import React, { Component } from 'react';
import airbnb from '../assets/image/airbnb.png';
import Messages_chat from './Messages_chat';
import Messages_users from './Messages_users';
import { BrowserRouter as Router, Route, Switch , Link, Navigate } from "react-router-dom";

import LeftSideBar from '../AccountCreation/sidebar/leftside';
import Header from '../AccountCreation/header/Header';

import UserIcon from '../assets/image/Rectangle_375.png';
import calender from '../assets/image/calender.png';
import zoom from '../assets/image/zoom.png';
import ionic_ios_add from '../assets/image/Icon ionic-ios-add.svg';
import notfound from '../assets/image/Group 8187.png';

import Pusher from 'pusher-js';

//Add Tabs
class Messages extends Component {
    chatContainer = React.createRef();
    constructor(props) {
        super(props);
        var temp_chat_id = 0;
        var temp_user_id = 0;
        temp_chat_id = window.location.search.replace('?', '');
        if(temp_chat_id == ''){
            temp_chat_id = 0;
        }else{
            temp_user_id = temp_chat_id.split('-');
            temp_user_id = temp_user_id[1];
            temp_chat_id = temp_chat_id.replace('-', '');
        }


        this.state = {
            data:"Chat",
            temp_login:true,
            Messages_Users : [],
            offset:0,
            current_chat_id : temp_chat_id,
            chat_history : [],
            chat_user : [],
            message_text : '',
            file : '',
            text: '',
            username: '',
            chats: []
        }

        this.get_messanger_user = this.get_messanger_user.bind(this);
        this.change_current_chat_id = this.change_current_chat_id.bind(this);

        var token = localStorage.getItem('token');
        if(token == null){
            this.state.temp_login = false;
        }
        this.get_messanger_user()
        if(temp_chat_id != 0){
            this.getProfileById(temp_user_id);
            this.chat_message_list(temp_chat_id);
        }
    }
    
    
    componentDidUpdate() {
        const pusher = new Pusher('785126dad3606ac09387', {
            cluster: 'us3',
            encrypted: true
        });
        const channel = pusher.subscribe('Chat');

        channel.bind('NewMessage'+this.state.current_chat_id, data => {
            this.chat_message_list(this.state.current_chat_id);
        });

        channel.bind('NewChat'+localStorage.getItem('User_id'), data => {
            this.get_messanger_user();
        });

        channel.bind('NewMessage'+localStorage.getItem('User_id'), data => {
            this.get_messanger_user();
        });


        channel.bind('ChatDeleted', data => {
            // console.log('ChatDeleted')
            this.get_messanger_user();
        });
    }
    
    fileHandler (e){
        this.setState({ file: e.target.files[0]});
    }

    close_file(e){
        this.setState({ file: ''});
    }

    get_messanger_user() {
        //API Call
        const apiOption = {
            method: "get",
            headers:{
                'Authorization': 'Bearer'+' '+localStorage.getItem('token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }
        fetch(this.props.APIPath+"chat_list?offset="+this.state.offset,apiOption)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            if(resJson !== undefined){
                var Messages_Users = [];
                if((resJson.data).length != 0){
                    if((resJson.data[0].users).length != 0){
                        Messages_Users = resJson.data;
                    }
                }
                this.setState({ Messages_Users: Messages_Users});
            }
        });
    }

    send_chat(thread_id){
        
        const requestOptions = {
            method: "post",
            headers:{
                'Authorization': 'Bearer'+' '+localStorage.getItem('token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({                
                "message" :  this.state.message_text,
                "chat_id" : thread_id
            }), 
        }
        
        fetch(this.props.APIPath+"send_message",requestOptions)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            this.setState({ file:''});
            this.setState({message_text:''});
            this.chat_message_list(thread_id);
        });
        
    }

    send_file(thread_id){
        console.log('chat_image')
        console.log(this.state.file)
        console.log(this.state.file.name)

        var formdata = new FormData();
        formdata.append("chat_id", thread_id);
        formdata.append("chat_image", this.state.file,this.state.file.name);

        const requestOptions = {
            method: "post",
            headers:{
                'Authorization': 'Bearer'+' '+localStorage.getItem('token')
            },
            body: formdata,
            redirect: 'follow'
        }
        
        fetch(this.props.APIPath+"send_message",requestOptions)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            this.setState({message_text:''});
            this.setState({file:''});
            this.chat_message_list(thread_id);
        });
    }
    

    chat_message_list(thread_id){
        const apiOption = {
            method: "get",
            headers:{
                'Authorization': 'Bearer'+' '+localStorage.getItem('token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }

        fetch(this.props.APIPath+"chat_message_list?chat_id="+thread_id,apiOption)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            if(resJson !== undefined){
                // this.setState({ chat_history: resJson.data});
                this.setState( { chat_history: resJson.data }, () => this.scrollToMyRef() );
            }
        });
    }

    getProfileById(user_id){
        const apiOption2 = {
            method: "post",
            headers:{
                'Authorization': 'Bearer'+' '+localStorage.getItem('token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({
                "user_id": user_id
            }),
        }
   
        fetch(this.props.APIPath+"getProfileById",apiOption2)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            if(resJson.status == true){
                if(resJson.status == true){
                    this.setState({ chat_user: resJson.data});
                }
            }
        });
    }
        
    change_current_chat_id(thread_id,user_id){
        this.setState({ file: ''});
        this.setState({ current_chat_id: thread_id});

        this.setState({ chat_user: []});

        this.getProfileById(user_id);
        
        this.setState({ chat_history: []});
        this.chat_message_list(thread_id);
    }

    handleKeyPress(e){
        this.setState({message_text:e.target.value});
    }

    scrollToMyRef = () => {
        console.log('dd')
        const scroll =
          this.chatContainer.current.scrollHeight -
          this.chatContainer.current.clientHeight;
        this.chatContainer.current.scrollTo(0, scroll);
      };

    render() {
        if (this.state.temp_login == false) {
            return <Navigate to="/" />
        }
        
        return (
            <>
                <div className="wapper" style={{backgroud:"orange"}}>
                    <div className="container-fluid">
                        <div className="row">
                            <LeftSideBar meta = {this.state.data} />
                            <div className="col-md-12 col-lg-12 col-xl-9 col-xxl-10" style={{padding:"0 0 0 30px",height:"100%"}}>
                                <Header/>
                                <div className='user_profile_main Messages-main'>
                                    <div className='row'>
                                        <div className='col-sm-4 col-xl-4 col-xxl-3' style={{paddingRight:"0px",borderRight:"1px solid #f6f6f6;"}}>
                                            <div className='chat_users_main'>
                    
                                                <div className='chat-users-header'>
                                                    <h3>Messages</h3>
                                                </div>
                                                <div className='chat-users-list'>
                                                {this.state.Messages_Users.map(Element =>
                                                    <Messages_users {...Element} change_current_chat_id={this.change_current_chat_id}/>
                                                )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-8 col-xl-8 col-xxl-9' style={{paddingLeft:"0px"}}>
                                            {this.state.current_chat_id != 0?
                                                <div className='Messages-main-chat'>
                                                    <div className='Messages-user-chat-header'>
                                                        <div className='chat-useres-box-username'>
                                                            {/* <span className='chat-useres-box-imges'>
                                                                <img src={this.state.chat_user.profile_image} alt="UIT" />
                                                            </span>     */}
                                                            <div className="chat-useres-box-imges" style={{borderRadius:'100%',width:'65px',height:'60px',backgroundSize:'cover',backgroundImage: `url(${this.state.chat_user.profile_image})`}}>
                                                                    {/* {$imagePreview} */}
                                                                </div>
                                                            <div className='chat-useres-box-text'>
                                                                <h3>{this.state.chat_user.name}</h3>
                                                                <span>{this.state.chat_user.job_title}</span>
                                                            </div>
                                                        </div> 
                                                        <div className='Messages-user-contact'>
                                                            <a href="javascript:void(0);"> <img style={{width:"51px"}} src={zoom} /></a>
                                                            <a href="javascript:void(0);"> <img src={calender} /></a>
                                                            <a href="javascript:void(0);"> <img src={notfound} /></a>
                                                        </div>
                                                    </div>     
                                                    <div className="Messages-user-chat-body" style={{position:'relative'}}>
                                                        <div className="Messages-user-chat-body-textshow" ref={this.chatContainer} id="Messages-user-chat-body-textshow">
                                                            {this.state.chat_history.map(Element =>
                                                                <Messages_chat {...Element} chat_user={this.state.chat_user} header_profile_image={this.state.chat_user.profile_image}/>
                                                            )}
                                                        </div>
                                                        <div style={{ float:"left", clear: "both" }}></div>
                                                        {this.state.file?
                                                            <div style={{bottom:'0',background:'#e9edef',display:'flex',height:'100%',zIndex:'100',width:'100%',right:'0',position:'absolute',overflow:'hidden'}}>
                                                                <span style={{marginLeft:'10px',marginTop:'10px'}} data-testid="x" data-icon="x" onClick={this.close_file.bind(this)}>
                                                                    <svg fill="currentColor" viewBox="0 0 24 24" width="24" height="24">
                                                                        <path d="m19.1 17.2-5.3-5.3 5.3-5.3-1.8-1.8-5.3 5.4-5.3-5.3-1.8 1.7 5.3 5.3-5.3 5.3L6.7 19l5.3-5.3 5.3 5.3 1.8-1.8z"></path>
                                                                    </svg>
                                                                </span>
                                                                <img style={{padding:'20px',width:'auto',height:'auto',maxWidth:'600px',maxHeight:'600px',position:'relative',margin:'0 auto'}} src={URL.createObjectURL(this.state.file)} alt={this.state.file.name}/>
                                                                <div aria-disabled="false" role="button" tabindex="0" aria-label="Send" style={{width:'60px',height:'60px',position:'absolute',right:'11px',bottom:'0'}} onClick={() => {this.send_file(this.state.current_chat_id)}}>
                                                                    <span data-testid="send" data-icon="send" style={{background:'#19999A',borderRadius:'50%',padding:'20px',color:'#fff'}}>
                                                                        <svg viewBox="0 0 24 24" width="24" height="24">
                                                                            <path fill="currentColor" d="M1.101 21.757 23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"></path>
                                                                        </svg>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        : 
                                                            ''
                                                        }
                                                        
                                                        <div className='messages_input_text'>
                                                            
                                                            <div className='messages_input_box'>
                                                                <textarea placeholder='Type Your Messages' onChange={this.handleKeyPress.bind(this)} value={this.state.message_text}></textarea>
                                                                <input type="file" style={{display:'none'}}  id="upload" onChange={this.fileHandler.bind(this)}/>
                                                                <button type="button"  className='messages_input_box-button'>
                                                                    <label for="upload" style={{cursor:'pointer'}}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.07" height="18.07" viewBox="0 0 18.07 18.07">
                                                                            <path id="Icon_ionic-ios-add" data-name="Icon ionic-ios-add" d="M25.91,16.875H19.125V10.09a1.125,1.125,0,0,0-2.25,0v6.785H10.09a1.125,1.125,0,0,0,0,2.25h6.785V25.91a1.125,1.125,0,0,0,2.25,0V19.125H25.91a1.125,1.125,0,0,0,0-2.25Z" transform="translate(-8.965 -8.965)" fill="#19999a"/>
                                                                        </svg>
                                                                    </label>
                                                                </button>
                                                            </div>
                                                            <button type='button' onClick={() => {this.send_chat(this.state.current_chat_id)}}>Send </button>
                                                        </div>                          
                                                    </div>                
                                                </div>
                                                : ""
                                            }
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>    
                        </div>    
                    </div> 
                </div>                   
            </>
        );
    }
}
export default Messages;