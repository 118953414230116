// import LeftSideBar from "../sidebar/leftside";
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch , Link, Navigate } from "react-router-dom"
import airbnb from '../../assets/image/airbnb.png';
import add_team_mamber from '../../assets/image/add_team_mamber.png';
import { Modal, Button } from "react-bootstrap";
import upload_image from '../../assets/image/upload_image.png';
import Alert from 'react-bootstrap/Alert';
//CSS

//Images
import Delete from '../../assets/image/Delete.png';

class Singup_stap_11 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            isOpen_edit: false,
            redirect:false,
            edit_mode : false,
            
            
            Team_Members: [],
            Full_name : "",
            Email:"",
            valition_Full_name:"",
            valition_Email:"",

            old_edit_name:"",
            old_edit_email:"",
            
            edit_name:"",
            edit_email:"",
            valition_Full_name_edit:"",
            valition_Email_edit:"",
            
            Required:"",
            redirect: false,

            showerror:false,
            showerrortype : 0,
            showerrormessage : "",
        }
        this.Full_name = React.createRef();
        this.Email = React.createRef();
        this.Full_name_edit = React.createRef();
        this.Email_edit = React.createRef();
    }

    openModal = () => this.setState({ isOpen:true });
    closeModal = () => this.setState({ isOpen:false});

    openModal_edit = () => this.setState({ isOpen_edit:true,edit_mode:true });
    closeModal_edit = () => this.setState({ isOpen_edit:false,edit_mode:false});

    handleKeyPress(e){
        if(e.target.name == "Email_Address"){
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            this.setState({ Email:e.target.value });
            if (re.test(e.target.value)) {
                this.setState({
                    valition_Email:"",
                    Email:e.target.value
                });
            }else {
                this.setState({ valition_Email: "Please enter a valid email address" })
            }           
        }

        if(e.target.name =="Full_name"){
            this.setState({
                valition_Full_name:"",
                Full_name : e.target.value
            });
        }

        if(e.target.name == "Email_Address_edit"){
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            this.setState({ edit_email:e.target.value });
            if (re.test(e.target.value)) {
                this.setState({
                    valition_Email_edit:"",
                    edit_email:e.target.value
                });
            }else {
                this.setState({ valition_Email: "Please enter a valid email address" })
            }           
        }

        if(e.target.name =="Full_name_edit"){
            this.setState({
                valition_Full_name_edit:"",
                edit_name : e.target.value
            });
        }
    }

    addNewFild(event){
        event.preventDefault();
        let log = false;
        if(!this.Full_name.current.value){
            this.setState({ valition_Full_name: "Please enter a full name" });
            log = true;
        }
        if(!this.Email.current.value){
            this.setState({ valition_Email: "Please enter a email" });
            log = true;
        }
        
        let new_email = this.Email.current.value;
        let oedt = 0; 
        this.props.list_experience.map(function(data){
           
            if(data.email === new_email ){
                oedt = 1;
            }
        });

        if(oedt === 1){
            this.setState({ valition_Email: "This email id already exists" });
            log = true;
        }

        if(log == true){ return }

        let Full_name = this.Full_name.current.value;
        let Email =  this.Email.current.value
        this.props.createjob(Full_name,Email);
        this.setState({Full_name:"",Email:"",})
        this.closeModal()
    }

    gotonextStap(){
        var data_token = "Bearer "+localStorage.getItem("token");
        const apiOption = {
            method: "post",
            headers:{
                'Authorization': data_token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({
                "dei_statement" :  this.props.DEI_info,
                "team_lead" : this.props.team_lead,
                "erg" :  this.props.erg,
                "programming" :  this.props.programming,
                "training" :  this.props.training,
                "men" :  this.props.Man,
                "women" : this.props.Women,
                "non_binary" : this.props.Binary,
                "lgbt" :  this.props.LGBTQ,
            }), 
        }
   
        fetch(this.props.apiPath+"add_dei_statement",apiOption)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            if(resJson.status == true){
                this.setState({showerror:true , showerrortype : 1 , showerrormessage: "Your company is registered" });
                this.error_handaling();
                this.send_invaites_users();
            }else{
                this.setState({ valid_email_address: "Email address is already registered" });
                this.setState({showerror:true , showerrortype : 2 , showerrormessage: "Something wrong with api fields" });
                this.error_handaling();
            }
        });
    }

    gotobackStap(){
        this.props.clickEvent("stap9");
    }

    send_invaites_users(){
        const apiOption = {
            method: "post",
            headers:{
                'Authorization': 'Bearer '+localStorage.getItem("token"),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({
                "invitations" :  this.props.list_experience,
            }), 
        }
   
        fetch(this.props.apiPath+"send_invites",apiOption)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            if(resJson.status == true){
                this.setState({showerror:true , showerrortype : 1 , showerrormessage: "Your company is registered" });
                this.error_handaling();
            }else{
                this.setState({ valid_email_address: "Email address is already registered" });
                this.setState({showerror:true , showerrortype : 2 , showerrormessage: "Something wrong with api fields" });
                this.error_handaling();
            }
        });
    }

    error_handaling(){
        if(this.state.showerror == true){
            setTimeout(function() {
               this.setState({showerror: false});
            }.bind(this),3000); 

            if(this.state.showerrortype == 1){
                setTimeout(function() {
                    this.setState({redirect:true});
                }.bind(this),3100);     
            }

            return(
                <>
                    <Alert style={{position:"absolute",top:"0",left:"0",right:"0", width:"100%", float:"left", textAlign:"center"}} variant={this.state.showerrortype == 1 ? "success" : "danger"}>
                        {this.state.showerrormessage}
                    </Alert>
                </>
            )
        }
    }    
    
    change_page(){
        //API Call
        var data_token = "Bearer "+localStorage.getItem("token");
        console.log("hello");
        console.log(data_token);

        const apiOption = {
            method: "post",
            headers:{
                'Authorization': data_token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({
                "dei_statement" :  this.props.DEI_info,
                "team_lead" : this.props.team_lead,
                "erg" :  this.props.erg,
                "programming" :  this.props.programming,
                "training" :  this.props.training,
                "men" :  this.props.Man,
                "women" : this.props.Women,
                "non_binary" : this.props.Binary,
                "lgbt" :  this.props.LGBTQ,
            }), 
        }
   
        fetch(this.props.apiPath+"add_dei_statement",apiOption)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            if(resJson.status == true){
                this.setState({showerror:true , showerrortype : 1 , showerrormessage: "Your company is registered" });
                this.error_handaling();
                this.send_invaites_users();
            }else{
                this.setState({ valid_email_address: "Email address is already registered" });
                this.setState({showerror:true , showerrortype : 2 , showerrormessage: "Something wrong with api fields" });
                this.error_handaling();
            }
        });
        //this.setState({redirect:true});
    }

    edit_my_data_now(name,email){
        this.state.name = name;
        this.state.email = email;
        this.setState({old_edit_name:name,old_edit_email:email,edit_name:this.state.name,edit_email:this.state.email,edit_mode:true});
        this.openModal_edit();
    }

    addNewFild_edit(event){
        event.preventDefault();
        
        let log = false;

        if(!this.Full_name_edit.current.value){
            this.setState({ valition_Full_name_edit: "Please enter a full name" });
            log = true;
        }
        if(!this.Email_edit.current.value){
            this.setState({ valition_Email_edit: "Please enter a email" });
            log = true;
        }

        let new_email = this.Email_edit.current.value;

        let oedt = 0; 
        this.props.list_experience.map(function(data){
            if(data.email === new_email ){
                oedt = 1;
            }
        });

        if(oedt === 1){
            this.setState({ valition_Email_edit: "This email id already exists" });
            log = true;
        }

        if(log == true){ return }

        let Full_name_edit = this.Full_name_edit.current.value;
        let Email_edit =  this.Email_edit.current.value

        let Name = this.state.old_edit_name;
        let Email = this.state.old_edit_email;

        this.props.edit_mode_on(Name,Email,Full_name_edit,Email_edit);

        this.closeModal_edit()
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to="/" />
        }
        let $imagePreview = null;
        if (this.props.logoUrl != "") {
          $imagePreview = (<img style={{width:"100%"}} src={this.props.logoUrl} />);
        } else {
          $imagePreview = (<img style={{width:"100%"}} src={upload_image} />);
        }

        return (
            <>
                {this.error_handaling()}
                <div className="row signin_signup_background singni_singup_meta_data11">
                    <div class="col-lg-6"></div> 
                    <div class="col-lg-6" style={{background:'#f9f9f9',padding:"1% 5% 5% 5%",textAlign:"left"}}>
                        
                        <div class="singup_box stap-11-signup" style={{marginTop:"5%", background:'overlay',background: "#FFF",float: "left", width: "100%", minheight: "85vh",borderRadius: "6px"}}>
                            <div class="Singup_stap_6_progress" style={{width:"100%",float:"left",height:"5px", borderTop:"10px solid teal", borderRadius:"25px 25px 0 0"}} ></div>
                            <div class="singup_box_inner">
                                <div class="singup_box_title singup_box_title_logo d-flex justify-content-start f-left w-100 align-items-center" style={{padding:"5% 6% 1%"}}>
                                    <div className="singup_box_title_logo_image" style={{width:"18%",marginRight:"3%" }}>
                                        {$imagePreview}
                                        {/* <img style={{width:"100%"}} src={airbnb} /> */}
                                    </div>
                                    <div className="singup_box_title_logo_text" style={{width:"85%"}}>
                                        <h3 style={{color:'var(--green)',fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600", fontSize: "32px"}}>Company Information</h3>
                                        <p style={{color:'#6d6d6d',fontFamily: "Poppins", fontStyle: "normal", fontWeight: "normal", fontSize: "24px"}}>DEI Information</p>
                                    </div>       
                                </div>
                                <div class="singup_box-singup-stap-2" style={{padding:"0% 6% 2%",float:"left",width:"100%"}}>
                                    <div className="invite_team">
                                        <span>Invite Team Members</span>
                                        <button type="button" onClick={this.openModal.bind(this)}> <img src={add_team_mamber} />  </button>
                                    </div>    
                                    <div className="list_of_invite_team">
                                        {this.props.list_experience.map(({ name,email}, index) =>
                                        <div className="list_of_invite_team_box_main" key={ index }>
                                            <div className="list_of_invite_team_box">
                                                <div className="list_of_invite_team_box_img">
                                                    <span className="list_invite_img">CC</span>
                                                </div>
                                                <div className="list_of_invite_team_box_text">
                                                    <h3>{name}</h3>
                                                    <p>{email}</p>
                                                </div>
                                            </div>
                                            <div className="Edit_list_of_invite">
                                                <button type="button" onClick={this.props.deleteItem.bind(this,  email)}><img src={Delete}/></button>
                                                <button type="button" onClick={this.edit_my_data_now.bind(this,name,email)} style={{fontSize:"20px"}}>Edit</button>
                                            </div>        
                                        </div>
                                        )}
                                    </div>

                                    <div className="singup_input d-flex align-items-center justify-content-between singup_setp-3-button-wapper" style={{textAlign:"center",padding: "6% 7% 0"}}>
                                        <div className="back_wapper">
                                            <button onClick={this.gotobackStap.bind(this)} type="button">  Back </button>
                                        </div>
                                        <button onClick={this.change_page.bind(this)} type="button" className="singup_input_button singup_setp-3-button"> Continue </button>
                                        {/* <Link className="singup_input_button singup_setp-3-button" to={"/ALL_Post"} variant="outline-success">Continue</Link> */}
                                    </div>
                                    <div className="back_wapper"  style={{textAlign:"center",marginTop:"2%", float:"left",width:"100%"}}>
                                        <button onClick={this.gotonextStap.bind(this)} type="button" style={{background: "transparent",border: "none",color: "teal",fontSize:"20px",paddingBottom:'5%'}}> Skip This </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Modal className="new_team_member_box_main" show={this.state.isOpen} onHide={this.closeModal}>
                            <Modal.Body>
                                <h3>New Team Member</h3>
                                <div className="new_team_member_box_inner">
                                    <form>
                                        <div className="singup_input" style={{float:"left",width:"100%"}}>
                                            <label style={{float:"left",width:"100%",fontWeight:"500"}}>Full Name</label>
                                            <input onChange={this.handleKeyPress.bind(this)} type="text" placeholder="Full Name" ref={this.Full_name} name="Full_name" value = {this.state.Full_name}  />
                                            <span className='form_validtion_class'>{this.state.valition_Full_name}</span>
                                        </div>
                                        <div className="singup_input" style={{float:"left",width:"100%"}}>
                                            <label style={{float:"left",width:"100%",fontWeight:"500"}}>Email Address</label>
                                            <input onChange={this.handleKeyPress.bind(this)} type="text" placeholder="Email Address" ref={this.Email} name="Email_Address" value={this.state.Email} />
                                            <span className='form_validtion_class'>{this.state.valition_Email}</span>
                                        </div>
                                        <div className="singup_input d-flex align-items-center justify-content-between singup_setp-3-button-wapper" style={{textAlign:"center",padding: "5% 0 0"}}>
                                            <div className="back_wapper">
                                                <button type="button"  onClick={this.closeModal.bind(this)}> Cancel </button>
                                            </div>
                                            <button onClick={ this.addNewFild.bind(this) } type="button" className="singup_input_button singup_setp-3-button"> {this.state.edit_mode == false ? "Add" : "Edit" } </button>
                                        </div>
                                    </form>
                                </div>
                            </Modal.Body>
                        </Modal>

                        <Modal className="new_team_member_box_main" show={this.state.isOpen_edit} onHide={this.closeModal_edit}>
                            <Modal.Body>
                                <h3>Edit Team Member</h3>
                                <div className="new_team_member_box_inner">
                                    <form>
                                        <div className="singup_input" style={{float:"left",width:"100%"}}>
                                            <label style={{float:"left",width:"100%",fontWeight:"500"}}>Full Name</label>
                                            <input onChange={this.handleKeyPress.bind(this)} type="text" placeholder="Full Name" ref={this.Full_name_edit} name="Full_name_edit" value={this.state.edit_name}  />
                                            <span className='form_validtion_class'>{this.state.valition_Full_name_edit}</span>
                                        </div>
                                        <div className="singup_input" style={{float:"left",width:"100%"}}>
                                            <label style={{float:"left",width:"100%",fontWeight:"500"}}>Email Address</label>
                                            <input onChange={this.handleKeyPress.bind(this)} type="text" placeholder="Email Address" ref={this.Email_edit} name="Email_Address_edit"   value={this.state.edit_email} />
                                            <span className='form_validtion_class'>{this.state.valition_Email_edit}</span>
                                        </div>
                                        <div className="singup_input d-flex align-items-center justify-content-between singup_setp-3-button-wapper" style={{textAlign:"center",padding: "5% 0 0"}}>
                                            <div className="back_wapper">
                                                <button type="button"  onClick={this.closeModal_edit.bind(this)}> Cancel </button>
                                            </div>
                                            <button onClick={this.addNewFild_edit.bind(this)} type="button" className="singup_input_button singup_setp-3-button"> Update </button>
                                        </div>
                                    </form>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div> 
                </div>
            </>
        );
    }
}
export default Singup_stap_11;
