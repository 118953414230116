// import LeftSideBar from "../sidebar/leftside";
import React, { Component } from 'react';


//Images
import logo from '../../assets/image/logo.png';
import company from '../../assets/image/company.png';
import team from '../../assets/image/team.png';

class Team_member_stap1 extends Component {
    constructor(props) {
        super(props);
        // let navigate = useNavigate();
        var initialItems_data = [];
        this.state = {
            initialItems: [],
            State : "",
            items: [],
            onclickState :0,
            get_search_value : "",
            selected_id: 0,
            selected_value : []
        };
        
       
        // get list of company size
        const apiOption = {
           method: "GET",
           headers:{
               'Accept': 'application/json',
               'Content-Type': 'application/json',
           },
       }
       fetch(this.props.apiPath+"companies?api_key=48e6c87a_6760_4b07_b987_7128fbea8545_1db5",apiOption)
       .then(function(res) {
           return res.json();
       }).then(resJson => { 
            // let list_industries = resJson.data;
            this.setState({
                initialItems: resJson.data
            });
            console.log('resJson.data')
            console.log(resJson.data);
            /*
            list_industries.map(function(es, i){
                if(list_industries.indexOf(es.id) == -1){
                    this.state.initialItems.unshift({
                        name:es.name ,id:es.id
                    })
                    this.setState({
                        initialItems: this.state.initialItems
                    });
                }
            });*/
        });
    }

    /*
    filterList(e){
        this.setState({State : e.target.value});
        var initialItems = [];
        // get list of company size
        const apiOption = {
           method: "GET",
           headers:{
               'Accept': 'application/json',
               'Content-Type': 'application/json',
           },
       }
       fetch(this.props.apiPath+"companies?api_key=48e6c87a_6760_4b07_b987_7128fbea8545_1db5",apiOption)
       .then(function(res) {
           return res.json();
       }).then(resJson => { 
           
           let list_industries = resJson.data;
           list_industries.map(function(es, i){
                if(initialItems.indexOf(es.name) == -1){
                   initialItems.push(es.name)
               }
           });
       });
       this.setState({initialItems : initialItems});
    }*/

    get_id(e){
        var find_id = 0;
        var find_array = []
        this.state.initialItems.map(function(es, i){
            console.log(es)
            if(e.target.value == es.name){
                find_id = es.user_id;
                find_array = es;
            }
        });

        
        this.setState({get_search_value: e.target.value,onclickState:1})
        console.log(find_id);
        console.log(find_array);

        if(this.state.selected_id == 0){
            this.setState({selected_id: find_id, selected_value :find_array})
        }else{
            this.setState({selected_id: find_id, selected_value :find_array})
        }
        
        // this.setState({State : e.target.value});
    }

    backtocompany(){
        this.props.clickEvent("stap1");
    }

    send_to_next(){
        this.props.savedata(this.state.selected_id);
        this.props.clickEvent("Team_member_stap2"); 
    }
    

    render() {
        return (
            
            <div className="row signin_signup_background Team_member_stap_main">
                <div className="col-lg-6"></div> 
                <div className="col-lg-6" style={{background:'#f9f9f9',padding:"5% 5% 5% 5%",textAlign:"center"}}>
                    <div className="singup_box Team_member_stap_1-inner_box" style={{ background:'overlay',background: "#FFF",float: "left", width: "100%", height: "100%",borderRadius: "15px"}}>
                        <div className="progress-singup_box"></div>
                        <div className="singup_box_inner">
                            <div className="singup_logo"style={{textAlign:"center",paddingTop:"30px"}}>
                                <img src={logo} />
                            </div>
                            <div className='singup_team_membar_text'>
                                <span>Welcome!</span>
                                <h3>Let's Find Your Company</h3>
                            </div>
                            <div className='main_of_the_all_list' style={{float: "left", width:"100%"}}>
                                <div className='header-search' style={{backgroundImage:"../../assets/image/Search.png",}}>
                                    <input onChange={this.get_id.bind(this)} type="" list="city_state_list" placeholder="Search for your company" />
                                    <datalist id="city_state_list">
                                        {this.state.initialItems.map((item) =>
                                            <option key={item.name} value={item.name} data_id={item.id} >{item.name}</option>
                                        )}
                                    </datalist>
                                </div>

                                {this.state.selected_value.length == 0 && this.state.selected_id == 0 ?
                                    <>
                                        {this.state.get_search_value.length > 0?
                                            <div className='company_is_empty' style={{maxWidth:"70%", margin:"20px auto 0",display:"inline-block"}}>
                                                <h3 style={{fontSize:'24px'}}> "{this.state.get_search_value}" Company Profile Not Found </h3>
                                                <div className='company_is_empty_image' style={{float:"left", width:"100%",margin:"15px 0 30px"}} >
                                                    
                                                </div>
                                                <span style={{float:"left", width:"100%",margin:"0 0 15px"}}>Please contact your company admin and request they make a company profile</span>
                                                <div className='company_is_empty_button'>
                                                    <button type='button' onClick={this.backtocompany.bind(this)} className="singup_input_button">Create Company Profile</button>
                                                </div>
                                            </div>
                                        : "" }
                                    </> : 
                                    <>
                                        <div className='main_wapper_lst_data'>
                                            <div className='compnay_logo'>
                                                <img style={{width:"105px",height:"105px",borderRadius:"50%"}} src={this.state.selected_value.profile_image} />
                                            </div>
                                            <div className='compnay_text'>
                                                <div className='compnay_inner_text'>
                                                    <h3>{this.state.selected_value.name}</h3>
                                                    <span>{this.state.selected_value.city} {this.state.selected_value.state}</span>
                                                    <span>200+ Employees</span>
                                                </div>
                                                <div className='Request_To_Join'>
                                                    <button onClick={this.send_to_next.bind(this)} type='button'>Request To Join</button>        
                                                </div>
                                            </div>
                                        </div> 
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        );
    }
}
export default Team_member_stap1;

