import React, { Component } from 'react';
//Add Tabs
class Single_list_data extends Component {
    
    constructor(props) {
        super(props);
        console.log(this.props)
        
    }
         
    get_active_data (data){
       this.props.get_id(data);
    }


    render() {
       return (
            <> 
                <br></br>
                <button  onClick={this.get_active_data.bind(this,this.props.id)} type="button" style={{border:"none", background:"transparent", textAlign:"left"}}>
                    <h3>this is list of data:{this.props.id} </h3>
                </button>
            </>
        );
    }
}
export default Single_list_data;