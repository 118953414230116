import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch , Link, Navigate } from "react-router-dom";
import {Tabs,Tab} from 'react-bootstrap';
import airbnb from '../assets/image/airbnb.png';
import close from '../assets/image/Icon ionic-ios-close-circle.png';
import Alert from 'react-bootstrap/Alert';

//Add Tabs
class Post_job_review extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showerror:false,
            showerrortype : 0,
            showerrormessage : "",
        }
    }

    postgotobackStap(){
        this.props.postEvent("post_job_4");
    }

    gotoedit(e){
        e.preventDefault();
        if(e.target.name == "title_job"){
            this.props.postEvent("post_job_1");
        }
        if(e.target.name == "role"){
            this.props.postEvent("post_job_2");
        }
        if(e.target.name == "Responsibilities"){
            this.props.postEvent("post_job_3");
        }
        if(e.target.name == "Experience"){
            this.props.postEvent("post_job_4");
            this.props.edit_job_expariens_mode_edit(1);

        }
        if(e.target.name == "Skills"){
            this.props.postEvent("post_job_4");
        }
        
    }

    jobSendtodata(event){
        event.preventDefault();

        const options = [];
        var skill_all = this.props.skills;
        this.props.SKills.map(function(element){

            var skill_id = -1;
            var skill_name = element;

            skill_all.map(function(element2){
                if(element2.name == skill_name){
                    skill_id = element2.id;
                }
            });

            options.push({
                skill_id: skill_id, skill_name: skill_name
            })
        });
        

        //city and state
        const text = this.props.State
        const myArray = text.split(",");
        const city = myArray[0];
        const state = myArray[1];

        //salary
        const Salary = this.props.Salary;
        const min_Salary = Salary[0]*1000;
        const max_Salary = Salary[1]*1000;

        const input = this.props.ApplicationDeadine;
        const myArrayd= input.split("-");
        const date = myArrayd[0]+"/"+myArrayd[1]+"/"+myArrayd[2];

        let Industry_name = 0;
        let Industry_id = "0";
        this.props.list_industries.map(elements => {
            if(this.props.Industry == elements.name){
                Industry_name = 1;
                Industry_id = '' + elements.id
            }
        });

        if(Industry_name == "0"){ Industry_id = this.props.Industry; }

        let depatment_name = 0;
        let depatment_id = "0";

        this.props.list_depatment.map(elements => {
            if(this.props.Depatment == elements.name){
                depatment_name = 1;
                depatment_id = '' + elements.id
            }
        });

        if(depatment_name == "0"){ depatment_id =  this.props.Depatment; } 
        
        if(localStorage.getItem('edit_job_id') !== null){
            const apiOption = {
                method: "post",
                headers:{
                    'Authorization': 'Bearer '+this.props.sendtokon,  
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify({
                    "job_id" : localStorage.getItem('edit_job_id'),
                    "job_title" : this.props.Job_Title,
                    "industry_id" : Industry_id,
                    "department_id" : depatment_id,
                    "state": state,
                    "city": city,
                    "location_status" : this.props.Location_Staus,
                    "employment_type" : this.props.Employment_Staus,
                    "min_salary": min_Salary,
                    "max_salary": max_Salary,
                    "degree_id" : this.props.degree_id,
                    "degree_required":this.props.degree_required,
                    "language_id" : this.props.Language_id,
                    "language_required":this.props.Language_Requirement,
                    "vaccination_status": this.props.vaccination_status,
                    "roles": this.props.roles,
                    "responsibilities": this.props.responsibilities,
                    "experience" : this.props.list_experience,
                    "skills" : options,
                    "deadline" : date, //this.props.ApplicationDeadine,
                    "lat": this.props.let,
                    "lang": this.props.lang,
                }), 
            }
    
            fetch(this.props.apilink+"edit_job",apiOption)
            .then(function(res) {
                return res.json();
            }).then(resJson => {
                if(resJson.status == true){
                    //this.setState({redirect:true});
                    localStorage.removeItem("edit_job_id")
                    this.props.postEvent("post_job_5");
                    this.setState({  showerror: true ,showerrortype : 2 ,showerrormessage: "Job is add" });
                    this.error_handaling();
                }else{
                    let data = resJson.message;
                    this.setState({  showerror: true ,showerrortype : 2 ,showerrormessage: data });
                    this.error_handaling();
                }
            })
            .catch(error => {
                this.setState({ showerror: true ,showerrortype : 2 ,showerrormessage: "Invalid Response" });
                this.error_handaling();
            });
        }else{
            const apiOption = {
                method: "post",
                headers:{
                    'Authorization': 'Bearer '+this.props.sendtokon,  
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify({
                    "job_title" : this.props.Job_Title,
                    "industry_id" : Industry_id,
                    "department_id" : depatment_id,
                    "state": state,
                    "city": city,
                    "location_status" : this.props.Location_Staus,
                    "employment_type" : this.props.Employment_Staus,
                    "min_salary": min_Salary,
                    "max_salary": max_Salary,
                    "degree_id" : this.props.degree_id,
                    "degree_required":this.props.degree_required,
                    "language_id" : this.props.Language_id,
                    "language_required":this.props.Language_Requirement,
                    "vaccination_status": this.props.vaccination_status,
                    "roles": this.props.roles,
                    "responsibilities": this.props.responsibilities,
                    "experience" : this.props.list_experience,
                    "skills" : options,
                    "deadline" : date, //this.props.ApplicationDeadine,
                    "lat": this.props.let,
                    "lang": this.props.lang,
                }), 
            }
    
            fetch(this.props.apilink+"add_job",apiOption)
            .then(function(res) {
                return res.json();
            }).then(resJson => {
                if(resJson.status == true){
                    //this.setState({redirect:true});
                    this.props.postEvent("post_job_5");
                }
            });
        }    
    }

    error_handaling(){
        if(this.state.showerror == true){
            setTimeout(function() {
               this.setState({showerror: false});
            }.bind(this),3000); 

            if(this.state.showerrortype == 1){
                setTimeout(function() {
                    this.props.clickEvent("stap4");
                }.bind(this),3100);     
            }

            return(
                <>
                    <Alert style={{position:"absolute",top:"0",left:"0",right:"0", width:"100%", float:"left", textAlign:"center"}} variant={this.state.showerrortype == 1 ? "success" : "danger"}>
                        {this.state.showerrormessage}
                    </Alert>
                </>
            )
        }
    }

    // experienceData(data){
    //     console.log(this.props.list_experience.length);
    //     if(this.props.list_experience.length > 1){
    //         console.log("hello");console.log(data);
            
    //         this.props.deleteItem(data);
    //     }
    // }

    render() {
        let degree_data ="";
        if(this.props.degree_required === true){
            degree_data = "Not Required";
        }else{
            let degree_data2 = this.props.degrees;
            let degreee_id = this.props.degree_id;

            degree_data2.map(elements =>{
                if(degreee_id == elements.id){
                    degree_data = elements.name;
                }
            });
    


            //degree_data = degree_data2[0]["name"];
        }

        let Language_data ="";
        if(this.props.Language_Requirement === true){
            Language_data = "Not Required";
            
        }else{
            let Language_data2 = this.props.language[this.props.Language_id];
            Language_data = Language_data2["name"];
            console.log(Language_data);
        }

        const text = this.props.State
        const myArray = text.split(",");
        const city = myArray[0];
        const state = myArray[1];

        let $imagePreview = null;
        if (localStorage.getItem('profile_img') != null) {
          $imagePreview = (<img style={{width:"147px"}} src={ localStorage.getItem('profile_img') } />);
        } else {
          $imagePreview = (<img style={{width:"147px"}} src={airbnb} />);
        }

        return (
            <>
                {this.error_handaling()}
                <div className='Dashbaord Dashbaord-post_job_main Dashbaord-post-review'>
                    <div className='Dashbaord-post_job_inner_box'>
                        <div className='Dashbaord_header'>
                            <h3 className='' style={{fontWeight:"bold"}}>Review Job</h3>
                            <div className='progressbar'>
                                <div className='progressbar-data'></div>
                            </div>
                        </div>
                        <div className='Dashbaord_Body Post_job_stap_1'>
                            <div className='Dashbaord_Body_inner Dashbaord-post_job_inner'>
                                <div className='post_box-user'>
                                    <span className='post_user_icon'>
                                        {/* <img  src={this.props.comp_url} /> */}
                                        {$imagePreview}
                                        </span>
                                    <div className='post_user_text'>
                                        <div className='post_user_text_inner'>
                                            <h3>BY {localStorage.getItem('user_name')}</h3>
                                            <span>{localStorage.getItem('user_sizes')}</span>
                                        </div>        
                                    </div>
                                </div>   

                                <div className='Job_Details'>
                                    <div className="Experience-title-main">
                                        <span className='Experience-title'>Job Details</span>
                                        <button onClick={this.gotoedit.bind(this)} name="title_job" type='button'>EDIT</button>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <div className="job_detailes_box">
                                                <lable>Job Title</lable>
                                                <span>{this.props.Job_Title}</span>
                                            </div>
                                            <div className="job_detailes_box">
                                                <lable>Industry</lable>
                                                <span>{this.props.Industry.name}</span>
                                            </div>
                                            <div className="job_detailes_box">
                                                <lable>Department</lable>
                                                <span>{this.props.Depatment}</span>
                                            </div>
                                            <div className="job_detailes_box">
                                                <lable>Location</lable>
                                                <span>{city}, {state}</span>
                                            </div>
                                            <div className="job_detailes_box">
                                                <lable>Employment status</lable>
                                                <span> 
                                                    {this.props.Employment_Staus == 1 ?  "Full Time": "" } 
                                                    {this.props.Employment_Staus == 2 ? "Part Time" : "" } 
                                                    {this.props.Employment_Staus == 3 ? "Contract" : "" } 
                                                    {this.props.Employment_Staus == 4 ? "Freelance" : "" } 
                                                    {this.props.Employment_Staus == 5 ? "Internship" : "" } 
                                                </span> 
                                             </div>
                                        </div>
                                        <div className='col-sm-6'>
                                        <div className="job_detailes_box">
                                                <lable>Payment option</lable>
                                                <span>Salary</span>
                                                <p>{this.props.Salary[0]}K - {this.props.Salary[1]}K</p>
                                            </div>
                                            <div className="job_detailes_box">
                                                <lable>Vaccination</lable>
                                                <span>Required</span>
                                            </div>
                                            <div className="job_detailes_box">
                                                <lable>Language</lable>
                                                <div className='job_detailes_tag_main'>
                                                    {/* {this.props.Language_Requirement == true ? "Not Required" : "Required"} */}
                                                    <span className='job_detailes_tag'>{Language_data}</span>
                                                </div>
                                            </div>
                                            <div className="job_detailes_box">
                                                <lable>Education</lable>
                                                <span className='job_detailes_tag'>{degree_data} </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                        </div>
                    </div>    

                    <div className='Dashbaord-post-review-tabs'>
                        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
                            <Tab eventKey="home" title="Job Description">
                                <div className="Experience-title-main">
                                    <button onClick={this.gotoedit.bind(this)} name="role" type='button'>EDIT</button>
                                </div>
                                <p>{this.props.roles}</p>
                            </Tab>
                            <Tab eventKey="profile" title="Responsibilities">
                            <div className="Experience-title-main">
                                <button onClick={this.gotoedit.bind(this)} name="Responsibilities" type='button'>EDIT</button>
                            </div>
                            <p>{this.props.responsibilities}</p>
                            </Tab>
                        </Tabs>
                    </div>

                    <div className='Dashbaord-post-review-tabs Dashbaord-post-experince'>
                        <div className='Dashbaord-post-experince-edit'>
                            <div className="Experience-title-main">
                                <span className='Experience-title'>Experience</span>
                                {/* <button onClick={this.gotoedit.bind(this)} name="Experience" type='button'>EDIT</button> */}
                            </div>
                            <div className='experience-box-main'>
                                {this.props.list_experience.map(({ requirement_status, industry_id, years,job_title}, index) => 
                                <div className='experience-box'>
                                    <div className='experience-box-text'>
                                        <span>{years} Years</span>
                                        <p>{job_title}</p>
                                    </div>
                                    <div className='experience-box-text-action'>
                                        <span>{ requirement_status == 0 ? "Required" : "Preferred" }</span>
                                        {/* <a href='#' onClick={this.experienceData.bind(this,requirement_status)}>REMOVE</a> */}
                                    </div>
                                </div>    
                                )}
                            </div>
                        </div>

                        <div className='Dashbaord-post-experince-edit skills-required'>
                            <div className="Experience-title-main">
                                <span className='Experience-title'>Skills Required</span>
                                <button name="Skills" onClick={this.gotoedit.bind(this)} type='button'>EDIT</button>
                            </div>
                            <div className='experience-box-main'>
                                <div className='job_detailes_tag_main'>
                                    { this.props.SKills.map((element, index) => 
                                        <span className='job_detailes_tag' key={ element } >{element}  </span>
                                    )}
                                </div>
                            </div>
                        </div> 
                    </div>

                    <div className='Dashbaord_Body_stap-box'>
                        <div className='Dashbaord_Body_stap-box-button'>
                            <button type="button" onClick={this.postgotobackStap.bind(this,"")} className='Dashbaord_stap-box-button-go_back'> Go Back </button>
                            <button type="button" onClick={this.jobSendtodata.bind(this)} className='Dashbaord_stap-box-button-next'> Publish </button>
                            {/* <Link className='Dashbaord_stap-box-button-next' to={"/Post_job"} variant="outline-success">Publish</Link> */}

                        </div>        
                    </div>    
                </div>
            </>
        );
    }
}
export default Post_job_review;