import React, { Component } from 'react';
import ReactDOM from "react-dom";
import airbnb from '../../assets/image/upload_image.png';
//import TagsInput from './TagsInput';
import SelectIndustryList from './Select2_industry_list';
//CSS

//Images
import upload_image from '../../assets/image/upload_image.png';
class Singup_stap_3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tag : [],
            list_industries: [],
            industries_name:[],
            //list of Size of com
            list_company_size : "",
            // Fome data erroe
            valid_company_tag : "", 
            valid_company_stage : "", 
            valid_company_size: "",
            //save upload data
            company_stage : this.props.stage, 
            company_size: this.props.size,
            get_select_value:this.props.industries
        }
        // add form value
        this.Company_Stage = React.createRef();
        this.Company_Size = React.createRef();

        // get list of company size
        const apiOption1 = {
            method: "GET",
            // headers:{
            //     'Accept': 'application/json',
            //     'Content-Type': 'application/json',
            // },
        }
        fetch(this.props.apiPath+"industries?api_key=48e6c87a_6760_4b07_b987_7128fbea8545_1db5",apiOption1)
        .then(function(res) {
            return res.json();
        }).then(resJson => { 
            let list_industries = resJson.data;
            this.state.list_industries = list_industries;
            this.setState({list_industries:list_industries});
        });
    }

    gotobackStap(){ this.props.clickEvent("stap1");}
    findItem(item){ return (this.setState({tag : item}));}

    getvaluedata(valid){
        this.state.get_select_value = valid;
        this.state.industries_name = valid;
        this.setState({get_select_value : this.state.get_select_value,valid_company_tag:""});
        this.setState({industries_name : this.state.industries_name});
        
    }
    handleKeyPress(e){
        if(e.target.name == "Company_Stage"){
            this.setState({ 
                valid_company_stage: "" ,
                company_stage : e.target.value
            });
            
        }

        if(e.target.name == "Company_Size"){
            this.setState({
                valid_company_size:"",
                company_size : e.target.value
            });
        }
    }

    //Fome submit function
    Save_company_data_stap_2(event){
        event.preventDefault();
        let log = false;
        if(this.state.get_select_value.length  < 1){
            this.setState({ valid_company_tag: "Please select a at least one  industry" });
            log = true;
        }
        
        var select_v = this.state.get_select_value;
        this.state.industries_name = select_v;
        
        this.setState({ industries_name: this.state.industries_name });

        /*
        var list_industries2 =  this.state.list_industries;
        
        var temp_array = [];
        select_v.map(function(element1,i){ 
            var temp_name = element1;
            list_industries2.map(function(element2,j){ 
                if(element2.name == element1){
                    temp_name = element2.id;
                }
            })
            console.log(temp_name)
            temp_array.push(temp_name)
        })

        this.state.get_select_value = temp_array;

        this.setState({ get_select_value : this.state.get_select_value });*/
        
       
        if(!this.Company_Stage.current.value || this.Company_Stage.current.value == ""){
            this.setState({ valid_company_stage: "Please select a company stage" });
            log = true;
        }
 
        if(!this.Company_Size.current.value || this.Company_Size.current.value == "") {
            this.setState({ valid_company_size: "Please select a company size" });
            log = true;
        }
       
        if(log === true){ 
            return 
        }

        this.state.company_stage = this.Company_Stage.current.value;
        this.state.company_size = this.Company_Size.current.value;

        this.setState({company_stage: this.state.company_stage});
        this.setState({company_size: this.Company_Size.current.value});

        //console.log(this.state.industries_name)

        this.props.get_data_stap_2(this.state.get_select_value,this.state.industries_name,this.state.company_stage,this.state.company_size,);

        this.props.clickEvent("stap3");
    }

    render() {
        let $imagePreview = null;
        if (this.props.logoUrl != "") {
        //   $imagePreview = (<img style={{width:"100%"}} src={this.props.logoUrl} />);
          $imagePreview = this.props.logoUrl;
        } else {
        //   $imagePreview = (<img style={{width:"100%"}} src={upload_image} />);
          $imagePreview = upload_image;
        }
        return (
            <div className="row signin_signup_background singni_singup_meta_data3">
                <div className="col-lg-6"></div> 
                <div className="col-lg-6" style={{background:'#f9f9f9',padding:"1% 5% 5% 5%",textAlign:"left"}}>
                    <div className="singup_box" style={{marginTop:"5%", background:'overlay',background: "#FFF",float: "left", width: "100%", height: "auto",borderRadius: "6px"}}>
                        <div className="Singup_stap_3_progress"></div>
                        <div className="singup_box_inner">
                            <div className="singup_box_title singup_box_title_logo d-flex justify-content-start f-left w-100 align-items-center" style={{padding:"5% 10% 5%"}}>
                                <div className="singup_box_title_logo_image" style={{marginRight:'12px',borderRadius:'100%',width:'82px',height:'80px',backgroundSize:'cover',backgroundImage: `url(${$imagePreview})`}}>
                                    {/* {$imagePreview} */}
                                </div>
                                <div className="singup_box_title_logo_text" style={{width:"85%"}}>
                                    <h3 style={{color:'var(--green)',fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600", fontSize: "32px"}}>Company Information</h3>
                                    <p style={{color:'#6d6d6d',fontFamily: "Poppins", fontStyle: "normal", fontWeight: "500", fontSize: "24px"}}>Primary Information</p>
                                </div>    
                            </div>
                            <div className="singup_box-singup-stap-2" style={{padding:"0% 10% 6%",float:"left",width:"100%"}}>
                                <form style={{float:"left",width:"100%"}} onSubmit={this.Save_company_data_stap_2.bind(this)} method="POST">
                                    <div className="singup_input" style={{marginBottom:"5%"}}>
                                        <label style={{}}>Industry</label>
                                        <div className="input_select float-left w-100">
                                            {/* <TagsInput list_industries={this.state.list_industries} taglist={this.state.tag} tagDelete={this.deleteTag.bind(this)} tagState ={this.getTagname.bind(this)} /> */}
                                            {/* <svg style={{ width: "25px",height: "25px",transform: "rotate(-92deg)", zIndex: "999999"}} className="Icon_feather-chevron-left" viewBox="13.5 9 9 18"><path id="Icon_feather-chevron-left" d="M 22.5 27 L 13.5 18 L 22.5 9"></path></svg> */}
                                            <SelectIndustryList industries_name={this.props.industries_name} industries={this.props.industries} apiPath={this.props.apiPath} list_industries={this.state.list_industries} getvaluedata={this.getvaluedata.bind(this)} /> 
                                            <span className="form_validtion_class"> {this.state.valid_company_tag} </span> 
                                        </div>
                                        
                                    </div>
                                    <div className="singup_input" style={{marginBottom:"5%"}}>
                                        <label style={{}}>Company Stage</label>
                                        <div className="input_select float-left w-100">
                                            <svg style={{ width: "25px",height: "25px",transform: "rotate(-92deg)"}} className="Icon_feather-chevron-left" viewBox="13.5 9 9 18"><path id="Icon_feather-chevron-left" d="M 22.5 27 L 13.5 18 L 22.5 9"></path></svg>
                                            <select onChange={this.handleKeyPress.bind(this)} value={this.state.company_stage} name='Company_Stage' ref={this.Company_Stage} className=" float-left w-100" >
                                                <option key="0" value="">Select Company Stage</option>
                                                {this.props.list_company_stages.map(({ id, name }) => 
                                                    <option key={id} value={id}>{name} </option>
                                                )}
                                            </select>
                                            <span className="form_validtion_class"> {this.state.valid_company_stage} </span>
                                        </div>
                                    </div>
                                    <div className="singup_input" style={{marginBottom:"5%"}}>
                                        <label style={{}}>Company Size</label>
                                        <div className="input_select float-left w-100">
                                        <svg style={{ width: "25px",height: "25px",transform: "rotate(-92deg)"}} className="Icon_feather-chevron-left" viewBox="13.5 9 9 18"><path id="Icon_feather-chevron-left" d="M 22.5 27 L 13.5 18 L 22.5 9"></path></svg>
                                            <select  onChange={this.handleKeyPress.bind(this)}  value={this.state.company_size} name='Company_Size' ref={this.Company_Size} className=" float-left w-100">
                                                <option key="0" value="">Select Company Size</option>
                                                {this.props.list_of_select.map(({ id, name }, index) => 
                                                    <option key={id} value={id} >{name}</option>
                                                )}  
                                            </select>
                                            <span className="form_validtion_class"> {this.state.valid_company_size} </span>
                                        </div>
                                    </div>
                                    <div className="singup_input d-flex align-items-center justify-content-between singup_setp-3-button-wapper" style={{textAlign:"center",padding: "15% 0 0"}}>
                                        <div className="back_wapper">
                                            <button type="button" onClick={this.gotobackStap.bind(this)} > Back </button>
                                        </div>
                                        <button type="button" onClick={this.Save_company_data_stap_2.bind(this)} className="singup_input_button singup_setp-3-button"> Continue </button>
                                    </div>
                                </form> 
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        );
    }
}

export default Singup_stap_3;