import React, { Component } from 'react';
import UserIcon from '../assets/image/Rectangle_375.png';
import calender from '../assets/image/calender.png';
import zoom from '../assets/image/zoom.png';
import ionic_ios_add from '../assets/image/Icon ionic-ios-add.svg';
import notfound from '../assets/image/Group 8187.png';
import { compareAsc, format } from 'date-fns';
// mport moment from 'moment';
import moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');

//Add Tabs
class Messages_chat extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id : this.props.id,
            message : this.props.message,
            image_url : this.props.image_url,
            image_width : this.props.image_width,
            image_height : this.props.image_height,
            user : this.props.user,
            current_user_id : localStorage.getItem('User_id'),
            created_at : moment(this.props.created_at, "YYYYMMDD, HH:mm:ss").fromNow(true),
            profile_image : this.props.header_profile_image
            
        }
    }

    render() {
        return (
            <>  
                {this.state.current_user_id == this.state.user.user_id?
                    <div class="messages-send-box">
                        <div class="messages-send-box-text">
                            {this.state.message != ''?
                                <>
                                    <div className="messages-send-text">
                                        <p style={{marginBottom:'0'}}>{this.state.message}</p>
                                    </div>    
                                    <span>{this.state.created_at}</span>
                                </>
                            :
                                <>
                                    <div className="messages-send-text2">
                                        <img src={this.state.image_url} alt="UIT" style={{width:"100px"}} />
                                    </div>
                                    <span style={{marginTop:'-2px'}}>{this.state.created_at}</span>
                                </>
                            }
                        </div>
                    </div>  
                    :
                    <div class="messages-receive-box">
                        <span className="messages-receive-img">
                            <img src={this.state.profile_image} alt="UIT" style={{width:"50px",borderRadius:"50px"}} />
                        </span>
                        <div class="messages-receive-box-text">

                            {this.state.message != ''?
                                <>
                                    <div className="messages-receive-text">
                                        <p style={{marginBottom:'0'}}>{this.state.message}</p>
                                    </div>    
                                    <span>{this.state.created_at}</span>
                                </>
                            :
                                <>
                                    <div className="messages-receive-text2">
                                        <img src={this.state.image_url} alt="UIT" style={{width:"100px"}} />
                                    </div>
                                    <span style={{marginTop:'-2px'}}>{this.state.created_at}</span>
                                </>
                            }
                        </div>
                    </div>
                }
            </>
        );
    }
}
export default Messages_chat;