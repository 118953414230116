import React, { Component } from 'react';
import {Form} from 'react-bootstrap'

//Add Tabs
class Post_job_stap_2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //stap 1
            About_Role : this.props.roles,
            valition_About_Role:"",
        }    
        this.About_Role = React.createRef(); 
    }

    handleKeyPress(e){
        if(e.target.name == "About_Role"){
            this.setState({valition_About_Role:""});
            let value = e.target.value;
            this.setState({
                About_Role:value
            });
        }
    }

    postgotobackStap(){
        this.props.postEvent("post_job_1");
    }

    save_Stap_2_data(event){
        event.preventDefault();
        let log = false;

        if(!this.About_Role.current.value){
            this.setState({ valition_About_Role: "Please enter about the role" });
            log = true;
        }

        if(log == true){
           
            return 
        }
        
        let about_job = this.About_Role.current.value;
        this.state.About_Role = about_job;
        this.setState({About_Role:this.state.About_Role});
      
        this.props.resivedData(about_job);
        this.props.postEvent("post_job_3");
    }

    render() {
        return (
            <>
                <div className='Dashbaord Dashbaord-post_job_main'>
                    <div className='Dashbaord-post_job_inner_box'>
                        <div className='Dashbaord_header post_job_2-header'>
                            <h3 className=''>Post A Job</h3>
                            <div className='progressbar'>
                                <div className='progressbar-data'></div>
                            </div>
                        </div>
                        <div className='Dashbaord_Body Dashbaord-stap-3'>
                            <div className='Dashbaord_Body_inner Dashbaord-post_job_inner  '>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className="singup_input Skills-Required">
                                            <label>About The Role</label>
                                            <span>Tell candidates about the job ?</span>
                                            <textarea type='text' onChange={this.handleKeyPress.bind(this)} value={this.state.About_Role} name="About_Role" ref={this.About_Role}></textarea>
                                            <span className='form_validtion_class'>{this.state.valition_About_Role}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                        </div>
                        <div className='Dashbaord_Body_stap-box'>
                            <div className='Dashbaord_Body_stap-box-button'>
                                <button type-="button" onClick={this.postgotobackStap.bind(this)} className='Dashbaord_stap-box-button-go_back'> Go Back </button>
                                <button type-="button" onClick={this.save_Stap_2_data.bind(this)}  className='Dashbaord_stap-box-button-next'> Next Step </button>
                            </div>        
                        </div>    
                    </div>    
                </div>
            </>
        );
    }
}
export default Post_job_stap_2;