import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch , Link, Navigate } from "react-router-dom"
import {Form} from 'react-bootstrap';
import SelectSkillsList from './SelectSkillsList';
import SelectIndustryListjob from '../Post_job/Select2_industry_list';
// import moment from 'moment'
import moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
class Post_job_stap_4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list_industries: [],
            industries: "",
            industries2:"",
            get_select_value_industries:[],
            experience_needed : this.props.list_experience,
            Skills :[],
            get_select_value:this.props.SKills,
            Application_Deadine : this.props.ApplicationDeadine,
            valition_job_title:"",
            valition_Years:"",
            valition_Required:"",
            valition_Skills:"",
            valition_Application_Deadine : "",
            Required:0,
            redirect: false,
            minimum_date : ''
        }
        this.job_title = React.createRef();
        this.Years = React.createRef();
        this.Application_Deadine = React.createRef();

         // get list of company size
         const apiOption = {
            method: "GET",
        }
        fetch(this.props.apilink+"industries?api_key=48e6c87a_6760_4b07_b987_7128fbea8545_1db5",apiOption)
        .then(function(res) {
            return res.json();
        }).then(resJson => { 
            let list_of_com_size = resJson.data;
            this.state.list_industries = list_of_com_size;
            this.setState({list_industries:this.state.list_industries});
        });

        if(this.props.edit_job_expariens_mode == 1){
            let industry_id = this.props.list_experience[0].industry_id;
            this.state.industries = industry_id;
            let years = this.props.list_experience[0].years;
            this.state.years = years;

            let Required = this.props.list_experience[0].requirement_status; 
            this.state.Required = Required;  
            this.setState({industries:industry_id,years:years,Required:Required});
           
        }else{
            this.setState({industries:"",years:"",Required:0});
        }
    }
    
    handleChange(e) {
        console.log('SS');
        console.log(e.target.value);
        let checkvalue = e.target.value;
        this.state.Required = checkvalue;
        this.setState({Required:this.state.Required});
    }

    addNewFild(event){
        event.preventDefault();
        let log = false;
        if(!this.job_title.current.value){
            this.setState({ valition_job_title: "Please enter a job title" });
            log = true;
        }
        if(!this.Years.current.value){
            this.setState({ valition_Years: "Please enter a years" });
            log = true;
        }

        if(log == true){ return }

        let job_title_id = this.job_title.current.value;
   
        let job_title = '';
        this.state.list_industries.map(elements =>{
            if(job_title_id == elements.id){
                job_title = elements.name;
            }
        });

        let Years =  this.Years.current.value
        if(this.props.edit_job_expariens_mode == 1){
            let experience_needed = this.state.experience_needed;
            let oldjob_job_title = experience_needed[0].job_title;
            let oldyears = experience_needed[0].years;
            let oldrequirement_status = experience_needed[0].requirement_status;
            let oldindustry_id = experience_needed[0].industry_id;

            this.props.edijobExparines(oldjob_job_title,oldyears,oldrequirement_status,oldindustry_id,job_title,Years,this.state.Required,job_title_id);
            this.props.edit_job_expariens_mode_edit(0);

        }else{
            this.props.createjob(job_title,Years,this.state.Required,job_title_id);
        }

        this.setState({industries:"",years:"",Required:0});
    }

    postgotobackStap(){
        this.props.postEvent("post_job_3");
        this.props.edit_job_expariens_mode_edit(0);
    }

    handleKeyPress(e){
        if(e.target.name == "Company_Stage"){
            this.setState({industries:e.target.value,industries2:e.target.getAttribute("title"), valition_job_title: "" });
        }

        if(e.target.name == "Years"){
                this.setState({years:e.target.value,valition_Years:""});
        }

        if(e.target.name=="Application_Deadine"){
            this.state.Application_Deadine = e.target.value;
            this.setState({Application_Deadine:this.state.Application_Deadine})
        }
    }

    getvaluedata(valid){
        this.state.get_select_value = valid;
        this.setState({get_select_value : this.state.get_select_value,valition_Skills:""});
        console.log(valid);
    }

    getvaluedataindustries(valid){
        this.state.get_select_value_industries = valid;
        this.setState({get_select_value_industries : this.state.get_select_value_industries,valition_get_select_value_industries:""});
    }

    stave_Data_4(event){
        event.preventDefault();
        let log = false;
        let data = this.state.Skills;

        if(this.state.get_select_value.length  < 1){
            this.setState({ valition_Skills : "Please enter a skills" });
            log = true;
        } 
       
        // var totaldata = this.state.experience_needed;
        // var totaldata_length =  Object.keys(totaldata).length
        
        // if(totaldata_length < 1) {
        //     this.setState({ valition_job_title: "Please enter a job title" });
        //     this.setState({ valition_Years: "Please enter a years" });
        //     log = true;
        // }

        // if(!this.Application_Deadine.current.value || this.Application_Deadine.current.value == ""){
        //     this.setState({ valition_Application_Deadine: "Please Enter a City, Sate" });
        //     log = true;
        // }

        if(log == true){
            console.log("data is not valid");
            return 
        }

        let Application_Deadine = this.Application_Deadine.current.value;
        this.props.resivedData(this.state.get_select_value,Application_Deadine);

        // const apiOption = {
        //     method: "post",
        //     headers:{
        //         'Authorization': 'Bearer'+this.props.sendtokon,  
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json',
        //     },
        //     body:JSON.stringify({
        //         "job_title" : this.props.Job_Title,
        //         "industry_id" : this.props.Industry,
        //         "department_id" : this.props.Depatment,
        //         "state": this.props.State,
        //         "city": this.props.State,
        //         "location_status" : 1,//this.props.Location_Staus,
        //         "employment_type" : 1,//this.props.Employment_Staus,
        //         "min_salary": this.props.min_salary,
        //         "max_salary": this.props.max_salary,
        //         "degree_id" : 1,//this.props.degree_id,
        //         "degree_required":this.props.degree_required,
        //         "language_id" : this.props.Language_id,
        //         "language_required":this.props.Language_Requirement,
        //         "vaccination_status": this.props.vaccination_status,
        //         "roles": this.props.roles,
        //         "responsibilities": this.props.responsibilities,
        //         "experience" : this.state.experience_needed,
        //         "skills" : this.state.Skills,
        //         "deadline" : Application_Deadine,
        //         "lat": 10,
        //         "lang": 10,
        //     }), 
        // }
   
        // fetch(this.props.apilink+"add_job",apiOption)
        // .then(function(res) {
        //     return res.json();
        // }).then(resJson => {
        //     if(resJson.status == true){
        //         this.setState({redirect:true});
        //     }
        // });
        this.props.postEvent("post_job_review");
        this.props.edit_job_expariens_mode_edit(0);
    }

    render() {
        return (
            <>
                <div className='Dashbaord Dashbaord-post_job_main'>
                    <div className='Dashbaord-post_job_inner_box'>
                        <div className='Dashbaord_header post_job_stap_4-header'>
                            <h3 className=''>Post A Job</h3>
                            <div className='progressbar'>
                                <div className='progressbar-data'></div>
                            </div>
                        </div>
                        <div className='Dashbaord_Body'>
                            <div className='Dashbaord_Body_inner Dashbaord-post_job_inner'>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className="singup_input">
                                            <label>Experience Needed</label>
                                            <div className="input_select float-left w-100" style={{position: "relative"}}>
                                                <svg style={{ width: "25px",height: "25px",transform: "rotate(-92deg)"}} class="Icon_feather-chevron-left" viewBox="13.5 9 9 18"><path id="Icon_feather-chevron-left" d="M 22.5 27 L 13.5 18 L 22.5 9"></path></svg>
                                                <select style={{border:"2px solid var(--green)", background:"transparent", height: "70px", borderRadius: "6px"}} class="select_job_meta" onChange={this.handleKeyPress.bind(this)} value={this.state.industries} name2={this.state.industries2} name='Company_Stage' ref={this.job_title} className=" float-left w-100" >
                                                    <option value="">Select Job title</option>
                                                    {this.state.list_industries.map(({ id, name }) => 
                                                        <option key={id} value={id} title={name}>{name} </option>
                                                    )}
                                                </select>
                                            </div>
                                            <span className="form_validtion_class"> {this.state.valition_job_title} </span>
                                        </div>
                                        <div className="singup_input">
                                            <label>Years</label>
                                            <input name='Years' placeholder='Year' onChange={this.handleKeyPress.bind(this)}  value={this.state.years} ref={this.Years} type="text" />
                                            <span className="form_validtion_class"> {this.state.valition_Years} </span>
                                        </div>
                                        <div className='readioaruye'>
                                            <Form>
                                                <div key={`inline-1`}>
                                                    <Form.Check checked={this.state.Required != 1} value="0" onChange={this.handleChange.bind(this)} inline name="group1" type="radio" label="Required" id={`inline-11`} />
                                                    <Form.Check checked={this.state.Required == 1} value="1" onChange={this.handleChange.bind(this)} inline label="Preferred"  name="group1" type="radio" id={`inline-12`} />
                                                </div>
                                            </Form>
                                            <span className="form_validtion_class"> {this.state.valition_Required} </span>
                                        </div>                    
                                        <div className='add_job_teset_button'>
                                            <button onClick={this.addNewFild.bind(this)} type="button"> Add New </button>
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='experience-box-main' > 
                                        {this.props.list_experience.map(({ requirement_status, industry_id, years,job_title}, index) => 
                                            <div className='experience-box' key={ index }>
                                                <div className='experience-box-text'>
                                                    <span>{years} Years</span>
                                                    <p>{job_title}</p>
                                                </div>
                                                <div className='experience-box-text-action'>
                                                    <span>{ requirement_status == 0 ? "Required" : "Preferred" }</span>
                                                    <button style={{padding:"0", border:"none",backgroundColor:"transparent", fontSize:"20px",color:"rgba(255,68,68,1)"}} type='remove-expares'  onClick={this.props.deleteItem.bind(this, requirement_status )} >REMOVE</button>
                                                </div>
                                            </div>    
                                        )}    
                                        </div>
                                    </div>
                                    <div className='col-sm-12'>
                                        <div className="singup_input" style={{marginBottom:"1%",marginTop:"1%"}}>
                                            <label>Skills Required</label>

                                            <div className="input_select float-left w-100" style={{position: "relative"}}>
                                                <svg style={{ width: "25px",height: "25px",transform: "rotate(-92deg)"}} class="Icon_feather-chevron-left" viewBox="13.5 9 9 18"><path id="Icon_feather-chevron-left" d="M 22.5 27 L 13.5 18 L 22.5 9"></path></svg>
                                                <SelectSkillsList selected_value = {this.state.get_select_value}  skillsList={this.props.skills}  getvaluedata={this.getvaluedata.bind(this)} />   
                                                <span className='form_validtion_class'>{this.state.valition_Skills}</span>
                                            </div> 
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className="singup_input Skills-Required">
                                            <label>Application Deadine(optional)</label>
                                            <input onKeyDown={(e) => {e.preventDefault();}} min={ moment(new Date()).format("YYYY/MM/DD")} min2="2022/06/09" ref={this.Application_Deadine} onChange={this.handleKeyPress.bind(this)} name="Application_Deadine"  value={this.state.Application_Deadine}  type="date" placeholder='MM/DD/YYYY' />
                                            <span>{this.state.valition_Application_Deadine}</span>
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                    </div>
                                </div>
                            </div>    
                        </div>
                        <div className='Dashbaord_Body_stap-box'>
                            <div className='Dashbaord_Body_stap-box-button'>
                                <button type-="button" onClick={this.postgotobackStap.bind(this)} className='Dashbaord_stap-box-button-go_back'> Go Back </button>
                                <button type-="button" onClick={this.stave_Data_4.bind(this)} className='Dashbaord_stap-box-button-next'> Next Step </button>
                            </div>        
                        </div> 
                    </div>    
                </div>
            </>
        );
    }
}
export default Post_job_stap_4;