import React, { Component } from 'react';
import SelectIndustryList from './Select2_industry_list';
import Alert from 'react-bootstrap/Alert';
import { Modal } from "react-bootstrap";
import { formControlUnstyledClasses } from '@mui/base';
import Edit_icone from '../../assets/image/pen-to-square-solid.png';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';

class Personal_information extends Component {
    constructor(props) {
        super(props);
        console.log(this.props.greenhousetoken);
        var size  = this.props.user_sizes.length == "" ? "" : this.props.user_sizes[0].name;
        var user_sizes = this.props.user_sizes.length == "" ? "" :this.props.user_sizes[0].id;

        var show_user_stages = this.props.user_stages.length == "" ? "" :  this.props.user_stages[0].name;
        var user_stages= this.props.user_stages.length == "" ? "" : this.props.user_stages[0].id;

        var name  = localStorage.getItem("user_name");
        var email = localStorage.getItem("Account_Email");
        
        this.state = {
           
            Name : name,
            validet_name:"",
            edit_name: false,

            validet_location:"",
            edit_location: false,

            Email: email,
            validet_Email:"",
            edit_Email: false,

            industry: this.props.industry.id,
            get_select_value:this.props.industry,
            show_user_industrye: this.props.industry.name,
            validet_user_industry:"",
            edit_industry: false,

            show_Company_Size: size,
            user_sizes: user_sizes,
            validet_user_sizes:"",
            edit_user_sizes: false,

            website: this.props.website,
            validet_website:"",
            edit_website: false,

            show_user_stages: show_user_stages,
            user_stages : user_stages,
            validet_user_stages:"",
            edit_user_stages: false,

            //bio
            show_text_data:false,
            bio : this.props.Bio,
            error_bio : "",
            bio_edit_mode : false,

            Greenhouse_Accoun: this.props.Greenhouse_Accoun,
            validet_Greenhouse_Accoun:"",
            edit_Greenhouse_Accoun: false,
            //list 
            list_company_stages:[],
            list_company_size:[],
            list_company_industry:[],

            city_state_list:[],

            greenhousetoken : this.props.greenhousetoken,
            greenhouse_status : this.props.greenhouse_status,
            isOpen : 0,
            account_token:'',

            City: this.props.City,
            State: this.props.State,
            location:this.props.City+","+this.props.State


        }

       
        if(this.props.City !== "undefined" ){
            this.setState({City: this.props.City})   
        }else{
            this.setState({City: ""})   
        }

        if(this.props.State !== "undefined" ){
            this.setState({State: this.props.State})   
        }else{
            this.setState({State: ""})   
        }

        this.setState({ location:this.props.City+","+this.props.State })   
        
        this.account_token = React.createRef();
        this.name =  React.createRef();
        // this.location =  React.createRef();
        this.email =  React.createRef();
        this.website =  React.createRef(); 
        this.Company_Stage = React.createRef(); 
        this.Company_Size = React.createRef(); 
        //edit Company Size
        const apiOption = {
            method: "GET",
            // headers:{
            //     'Accept': 'application/json',
            //     'Content-Type': 'application/json',
            // },
        }
        fetch(this.props.APIPath+"company-sizes?api_key=48e6c87a_6760_4b07_b987_7128fbea8545_1db5",apiOption)
        .then(function(res) {
            return res.json();
        }).then(resJson => { 
            let list_of_com_size = resJson.data;
            this.state.list_company_size = list_of_com_size;
            this.setState({list_company_size:this.state.list_company_size});
        });

        //edit Company Stage
        const apiOption1 = {
            method: "GET",
            // headers:{
            //     'Accept': 'application/json',
            //     'Content-Type': 'application/json',
            // },
        }
        fetch(this.props.APIPath+"company-stages?api_key=48e6c87a_6760_4b07_b987_7128fbea8545_1db5",apiOption1)
        .then(function(res) {
            return res.json();
        }).then(resJson => { 
            let list_company_stages = resJson.data;
            this.setState({list_company_stages:list_company_stages});
        });
        
        //edit Company Industry
        const apiOption3 = {
            method: "GET",
            // headers:{
            //     'Accept': 'application/json',
            //     'Content-Type': 'application/json',
            // },
        }
        fetch(this.props.APIPath+"industries?api_key=48e6c87a_6760_4b07_b987_7128fbea8545_1db5",apiOption3)
        .then(function(res) {
            return res.json();
        }).then(resJson => { 
            let list_company_industry = resJson.data;
            this.setState({list_company_industry:list_company_industry});
        });
    }

    getvaluedata(valid){
        this.state.get_select_value = valid;
        this.setState({get_select_value : this.state.get_select_value,validet_user_industry:""});
    }

    add_to_lessmore(){
        this.setState({show_text_data:true});
    }

    add_to_lessmore_less(){
        this.setState({show_text_data:false});
    }

    Edit_data(e){
        if(e.target.name == "Name"){
            if(this.state.edit_name == false){
                this.setState({edit_name:true});
            }else{
                this.setState({edit_name:false});
            }
        }

        if(e.target.name == "Location"){
            if(this.state.edit_location == false){
                this.setState({edit_location:true})
            }else{
                this.setState({edit_location:false})
            }
        }

        if(e.target.name == "Email"){
            if(this.state.edit_Email == false){
                this.setState({edit_Email:true})
            }else{
                this.setState({edit_Email:false})
            }
        }

        if(e.target.name == "Company_Size"){
            if(this.state.edit_user_sizes == false){
                this.setState({edit_user_sizes:true})
            }else{
                this.setState({edit_user_sizes:false})
            }
        }

        if(e.target.name == "Company_Website"){
            if(this.state.edit_website == false){
                this.setState({edit_website:true})
            }else{
                this.setState({edit_website:false})
            }
        }

        if(e.target.name == "Company_Bio"){
            
            if(this.state.bio_edit_mode == false){
                this.setState({bio_edit_mode:true})
            }else{
                this.setState({bio_edit_mode:false})
            }
        }

        if(e.target.name == "Company_Stage"){
            if(this.state.edit_user_stages == false){
                this.setState({edit_user_stages:true})
            }else{
                this.setState({edit_user_stages:false})
            }
        }

        if(e.target.name == "Industry"){
            if(this.state.edit_industry == false){
                this.setState({edit_industry:true})
            }else{
                this.setState({edit_industry:false})
            }
        }
    }
    
    getThelistedit2(e){
        this.state.location = e;
        this.setState({validet_location:"",location: this.state.location});
    }

    getThelistedit(e){
        if(e.target.name == "name"){
            this.setState({validet_name:"",Name:e.target.value});
        }
        /*if(e.target.name == "Location"){
                this.setState({validet_location:"",location: e.target.value});    
        }*/
        if(e.target.name == "email"){
            this.setState({Email:e.target.value});
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(e.target.value)) {
                this.setState({
                    validet_Email:""
                });
            }else {
                this.setState({ validet_Email: "Please enter a valid email address" })
            }        
        }
        if(e.target.name == "website"){
            this.setState({validet_website:"",website:e.target.value});    
        }
        if(e.target.name == "bio"){
            this.setState({error_bio:"",bio:e.target.value});    
        }
        if(e.target.name == "Company_Stage"){
            let Company_Stage = "";
            this.state.list_company_stages.map(elements =>{
                if(e.target.value == elements.id){
                    Company_Stage = elements.name;
                }
            });
            this.setState({validet_user_stages: "",user_stages:e.target.value,show_user_stages:Company_Stage});
        }

        if(e.target.name == "Company_Size"){
            let Company_Size = "";
            this.state.list_company_size.map(elements =>{
                if(e.target.value == elements.id){
                    Company_Size = elements.name;
                }
            });
            this.setState({validet_user_sizes:"",user_sizes:e.target.value,show_Company_Size:Company_Size});
        }
    }

    /*
    SaveData_update(data){
        console.log(data);
        
        

        if(log === true){ 
            return 
        }

        let user_Role = localStorage.getItem("user_Role");

        var formdata = new FormData();
        if(user_Role == 2){
            if(data =="update_name"){
                if(this.state.edit_name == true){
                    formdata.append("name", this.state.Name);
                }
            }   
            
            if(data =="update_location"){
                if(this.state.edit_industry == true){

                    var list_industries2 =  this.state.list_company_industry;
                    this.state.get_select_value.map(function(element1,i){ 
                        var temp_name = element1;
                        list_industries2.map(function(element2,j){ 
                            if(element2.name == element1){
                                temp_name = element2.id;
                            }
                        })
                        formdata.append("industries["+i+"]", temp_name);
                    })
                }
            }
            

            if(this.state.edit_Email == true){
                formdata.append("email", this.state.Email);
            }
            if(this.state.bio_edit_mode == true){
                formdata.append("bio", this.state.bio);
            }
            if(this.state.edit_website == true){
                formdata.append("website", this.state.website);
            }

            

            if(this.state.edit_user_stages == true){
                formdata.append("stages[0]", this.state.user_stages);
            }
            if(this.state.edit_user_sizes == true){
                formdata.append("sizes[0]", this.state.user_sizes);
            }
            if(this.state.edit_location == true){
                formdata.append("city", this.state.location.split(',')[0]);
                formdata.append("state", this.state.location.split(',')[1]);
            }
        } 
        
        if(user_Role == 4){
            if(this.state.edit_name == true){
                formdata.append("name", this.state.Name)
            }
            if(this.state.edit_Email == true){
                formdata.append("email", this.state.Email);
            }
        }

        const apiOption2 = {
            method: "post",
            headers:{
                'Authorization': 'Bearer '+localStorage.getItem("token"),  
                // 'Accept': 'application/json',
                // 'Content-Type': 'application/json',
            },
            body: formdata,
            redirect: 'follow'
        }
   
        fetch(this.props.APIPath+"update_profile",apiOption2)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            if(resJson.status == true){
                if(this.state.edit_name == true){
                    localStorage.setItem("user_name", this.state.Name);
                    this.props.localStorage_Name(this.state.Name);
                }
                if(this.state.edit_Email == true){
                    localStorage.setItem("Account_Email", this.state.Email);
                    this.props.localStorage_Email(this.state.Email);
                }
                if(this.state.edit_location == true){
                    this.props.localStorage_city_state(this.state.location.split(',')[0] , this.state.location.split(',')[1]);
                }

                if(this.state.edit_industry == true){
                    this.props.localStorage_company_industry(this.state.get_select_value);
                }

                this.setState({showerror:true , showerrortype : 1 , showerrormessage: "Your profile is update" });

                
                if(this.state.edit_name == true){
                    this.setState({edit_name: false });
                }
                if(this.state.edit_Email == true){
                    this.setState({edit_Email: false });
                }
                if(this.state.bio_edit_mode == true){
                    this.setState({bio_edit_mode: false });
                }
                if(this.state.edit_website == true){
                    this.setState({edit_website: false });
                }
                if(this.state.edit_industry == true){
                    this.setState({edit_industry: false });
                }
                if(this.state.edit_user_stages == true){
                    this.setState({edit_user_stages: false });
                }
                if(this.state.edit_user_sizes == true){
                    this.setState({edit_user_sizes: false });
                }
                if(this.state.edit_location == true){
                    this.setState({edit_location: false });
                }


                this.error_handaling();
            }else{
                this.setState({ valid_email_address: "Email address is already registered" });
                this.setState({showerror:true , showerrortype : 2 , showerrormessage: resJson.message});
                this.error_handaling();
            }
        })
        .catch(error => {
            this.setState({ showerror: true ,showerrortype : 2 ,showerrormessage: "Invalid Response" });
            this.error_handaling();
        });
    }*/
    
    show_name(){
        if(this.state.edit_name == false){
            return( <> 
                <span>{this.state.Name != "" ? this.state.Name :""}</span> 
                <button type='button' style={{backgroundImage :"url("+ Edit_icone +")",width:"20px", height:"20px", backgroundSize:"cover"}} onClick={this.Edit_data.bind(this)} name="Name"> </button>
            </> );
        }else{
            return( 
                <> 
                    <div className='Personal_data'>
                        <div className='singup_input'>
                            <input ref={this.name} type="text" name="name" value={this.state.Name} onChange={this.getThelistedit.bind(this)} placeholder=''></input>
                        </div>
                        <spna style={{float: "left",width: "100%"}} className="form_validtion_class">{this.state.validet_name}</spna>
                    </div>
                    {/* <button type='button' onClick={this.Edit_data.bind(this)} name="Name" style={{backgroundImage :"url("+ Edit_icone +")",width:"20px", height:"20px", backgroundSize:"cover"}} ></button> */}
                    <button type='button' onClick={this.SaveData_update.bind(this,"update_name")} >Update</button>

                </> 
            )
        }
    }

    show_Location(){
        if(this.state.edit_location == false){
            return( 
                <> 
                    <span>{this.state.location != "" ? this.state.location : ""} </span> 
                    {localStorage.getItem("user_Role") == 2 ? 
                    <button type='button' onClick={this.Edit_data.bind(this)} name="Location" style={{backgroundImage :"url("+ Edit_icone +")",width:"20px", height:"20px", backgroundSize:"cover"}}></button> 
                    :""}
                </> 
            )
        }else{
            return( <> 
                <div className='Personal_data' style={{width:'100%'}}>
                <div className='singup_input'>
                    {/* <input ref={this.location} type="text" value={this.state.location} name="Location" onChange={this.getThelistedit.bind(this)} placeholder=''></input> */}
                    <Autocomplete
                        id="country-select-demo"
                        options={this.state.city_state_list}
                        getOptionLabel={(option) => option.value}
                        onChange={(event, value) => {this.getThelistedit2(value.value)}}
                        defaultValue={{ value : this.state.location }}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {option.name}
                            </Box> 
                        )}
                        renderInput={(params) => (
                            <TextField name="City" onChange={this.city_state_list.bind(this)} placeholder='City, State'
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                </div>    
                <spna style={{float: "left",width: "100%"}} className="form_validtion_class">{this.state.validet_location}</spna>
                </div>
                {localStorage.getItem("user_Role") == 2 ? <>
                {/* <button type='button' onClick={this.Edit_data.bind(this)} name="Location" style={{backgroundImage :"url("+ Edit_icone +")",width:"20px", height:"20px", backgroundSize:"cover"}}></button> */}
	            <button type='button' onClick={this.SaveData_update.bind(this,"update_location")} >Update</button>
                </>:""}
             </> )
        }
    }
    show_Email(){
        if(this.state.edit_Email == false){
            return( <> <span>{this.state.Email !="" ? this.state.Email:""}</span> <button type='button' onClick={this.Edit_data.bind(this)} name="Email" style={{backgroundImage :"url("+ Edit_icone +")",width:"20px", height:"20px", backgroundSize:"cover"}}></button></> )
        }else{
            return( <> 
                <div className='Personal_data'>
                <div className='singup_input'>
                    <input ref={this.email} type="text" value={this.state.Email} name="email" onChange={this.getThelistedit.bind(this)} placeholder=''></input> 
                </div>        
                <spna style={{float: "left",width: "100%"}} className="form_validtion_class">{this.state.validet_Email}</spna>
                </div>
                {/* <button type='button' onClick={this.Edit_data.bind(this)} name="Email" style={{backgroundImage :"url("+ Edit_icone +")",width:"20px", height:"20px", backgroundSize:"cover"}}></button> */}
                <button type='button' onClick={this.SaveData_update.bind(this,"update_email")} >Update</button>
                </> )
        }
    }
    show_Company_Industry(){
        if(this.state.edit_industry == false){
            return(
                <>
                    <div className='Personal_information_value' style={{maxWidth:"290px", marginBottom:"20px"}}>
                        <span>
                            <>  
                                {this.state.get_select_value.map((data) =>
                                    <> {data}, </>        
                                )}
                            </>
                        </span>
                    </div>
                    {localStorage.getItem("user_Role") == 2 ? 
                        <button type='button' onClick={this.Edit_data.bind(this)} name="Industry" style={{backgroundImage :"url("+ Edit_icone +")",width:"20px", height:"20px", backgroundSize:"cover"}}></button>
                    :""}
                </>
            )
        }else{
            return(
            <>
                <div  className='singup_input Personal_data select_indeteste_my_data' style={{clear: "both" , marginBottom: "20px"}}>
                    <svg style={{ width: "25px",height: "25px",transform: "rotate(-92deg)", zIndex: "999999"}} className="Icon_feather-chevron-left" viewBox="13.5 9 9 18"><path id="Icon_feather-chevron-left" d="M 22.5 27 L 13.5 18 L 22.5 9"></path></svg>
                    <SelectIndustryList industries={this.state.get_select_value} apiPath={this.props.apiPath} list_industries={this.state.list_company_industry} getvaluedata={this.getvaluedata.bind(this)} /> 
                    <span className="form_validtion_class"> {this.state.valid_company_tag} </span> 
                </div> 
                {localStorage.getItem("user_Role") == 2 ? <>   
                {/* <button type='button' onClick={this.Edit_data.bind(this)} name="Industry" style={{backgroundImage :"url("+ Edit_icone +")",width:"20px", height:"20px", backgroundSize:"cover"}}></button> */}
	            <button type='button' onClick={this.SaveData_update.bind(this,"update_industry")} >Update</button>
                </>:""}
            </>);
        }
    }
    show_Company_Bio(){
        if(this.state.bio_edit_mode == false){
            if(this.state.show_text_data == false){
                return( 
                    <> 
                        <div className='Company-Bio-text' style={{float:"left", width:"100%"}}>
                            <span style={{float:"left", width:"100%", height:"30px", whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}> {this.state.bio } </span>            
                        </div>
                        {this.state.bio.length > 100 && this.state.bio !="" ? <button type="button" onClick={this.add_to_lessmore.bind(this)}>Read More</button> :"" }  
                    </> 
                ) 
            }else{
                return( 
                    <> 
                        <div className='Company-Bio-text' style={{float:"left", width:"100%"}}>
                            <span style={{float:"left", width:"100%"}}> {this.state.bio } </span>     
                        </div>
                        {this.state.bio.length > 100 && this.state.bio !="" ? <>
                            <button type="button"  onClick={this.add_to_lessmore_less.bind(this)}>Less More</button>
                        </> 
                        : ""}
                    </> 
                )
            }
         }else{
            return(
                <>
                    <div className='Company-Bio-text' style={{float:"left", width:"100%"}}>
                        <div className='readioaruye'>
                            <textarea onChange={this.getThelistedit.bind(this)} name='bio'  style={{width:"100%",float:"left",border:"1px solid #000", borderRadius:"6px"}}>{this.state.bio}</textarea>
                            <spna class="form_validtion_class">{this.state.error_datatrnert}</spna>
                        </div>                    
                    </div>
                    
                </>    
            )    
        }
    }
    show_Company_Bio_edit(){
        if(this.state.bio_edit_mode == false){
            return(
                <>
                    <button type='button' style={{ textDecoration:"none", background: "transparent", border: "none", color: "var(--green)", fontWeight: "400", backgroundImage :"url("+ Edit_icone +")",width:"20px", height:"20px", backgroundSize:"cover" }} onClick={this.Edit_data.bind(this)} name="Company_Bio"></button>    
                </>
            )    
        }else{
            return(
            <>
                <div className='buttons_bio_update'>
                    {/* <button type='button' style={{ textDecoration:"none", background: "transparent", border: "none", color: "var(--green)", fontWeight: "400", backgroundImage :"url("+ Edit_icone +")",width:"20px", height:"20px", backgroundSize:"cover"}} onClick={this.Edit_data.bind(this)} name="Company_Bio" ></button> */}
                    <button type='button' style={{ textDecoration:"none", background: "transparent", border: "none", color: "var(--green)", fontWeight: "400" }} onClick={this.SaveData_update.bind(this,"Company_Bio")} >Update</button>
                </div>    
            </>)
        }
    }
    show_Company_Size(){
        if(this.state.edit_user_sizes == false){
            return(
                <>
                    <div className='Personal_information_value' style={{maxWidth:"290px", marginBottom:"20px"}}>
                        <span>{this.state.show_Company_Size != "" ? this.state.show_Company_Size : "" } </span>
                        {localStorage.getItem("user_Role") == 2 ? 
                            <button type='button' className='Disconnected' onClick={this.Edit_data.bind(this)} name="Company_Size" style={{backgroundImage :"url("+ Edit_icone +")",width:"20px", height:"20px", backgroundSize:"cover"}}></button>
                        :"" }
                    </div>
                    
                </>
            )
        }else{
            return(
            <>
                <div className='Personal_data personal_select_color' style={{clear: "both", maxWidth: "290px" , marginBottom: "20px"}}>
                    <select  onChange={this.getThelistedit.bind(this)} value={this.state.user_sizes}  name='Company_Size' ref={this.Company_Size} className=" float-left w-100">
                        <option key="0" value="">Select Company Size</option>
                        {this.state.list_company_size.map(({ id, name }, index) => 
                            <option key={id} value={id} >{name}</option>
                        )}  
                    </select>
                    <span style={{float: "left",width: "100%"}} className="form_validtion_class"> {this.state.validet_user_sizes} </span>
                    {localStorage.getItem("user_Role") == 2 ? <>
                        {/* <button style={{ textDecoration:"none", background: "transparent", border: "none", color: "var(--green)", fontWeight: "400",backgroundImage :"url("+ Edit_icone +")",width:"20px", height:"20px", backgroundSize:"cover" }} type='button' className='Disconnected' onClick={this.Edit_data.bind(this)} name="Company_Size "></button> */}
                        <button style={{ textDecoration:"none", background: "transparent", border: "none", color: "var(--green)", fontWeight: "400" }} type='button' className='Disconnected' onClick={this.SaveData_update.bind(this,"Company_Size")} >Update</button>
                    </>:"" }
                </div>    
            </>);
        }
    }
    show_Company_Website(){
        if(this.state.edit_website == false){
            return( <> 
            <a style={{textDecoration:"underline"}} href={this.state.website !="" ? this.state.website : ""} target="_blank">{this.state.website !="" ? this.state.website : ""}</a> 
            {localStorage.getItem("user_Role") == 2 ? 
                <button type='button' className='Disconnected' onClick={this.Edit_data.bind(this)} name="Company_Website" style={{backgroundImage :"url("+ Edit_icone +")",width:"20px", height:"20px", backgroundSize:"cover"}}></button>
            :"" }
            </> )
        }else{
            return(
                <> 
                <div className='Personal_data'>
                    <div className='singup_input'>
                        <input ref={this.website} value={this.state.website} type="text" name="website" onChange={this.getThelistedit.bind(this)} placeholder=''></input>
                    </div>    
                    <spna style={{float: "left",width: "100%"}} className="form_validtion_class">{this.state.validet_website}</spna> 
                </div>
                {localStorage.getItem("user_Role") == 2 ? <>
                    {/* <button type='button' className='Disconnected' onClick={this.Edit_data.bind(this)} name="Company_Website" style={{backgroundImage :"url("+ Edit_icone +")",width:"20px", height:"20px", backgroundSize:"cover"}}></button> */}
                    <button type='button' className='Disconnected' onClick={this.SaveData_update.bind(this)} name="Company_Website">Update</button>
                </>
                :"" }
                </> 
                )
        }
    }
    show_Company_Stage(){
        if(this.state.edit_user_stages == false){
            return(<> 
                <span>{this.state.show_user_stages != "" ? this.state.show_user_stages : "" }</span>  
                {localStorage.getItem("user_Role") == 2 ? 
                    <button type='button' className='Disconnected' onClick={this.Edit_data.bind(this)} name="Company_Stage" style={{backgroundImage :"url("+ Edit_icone +")",width:"20px", height:"20px", backgroundSize:"cover"}}></button>
                    :"" }
            </> )
        }else{
            return(
            <>
                <div className='Personal_data personal_select_color'>
                    <select onChange={this.getThelistedit.bind(this)} value={this.state.user_stages}  name='Company_Stage' ref={this.Company_Stage} className=" float-left w-100" >
                        <option key="0" value="">Select Company Stage</option>
                        {this.state.list_company_stages.map(({ id, name }) => 
                            <option key={id} value={id}>{name} </option>
                        )}
                    </select>
                    <span style={{float: "left",width: "100%"}} className="form_validtion_class"> {this.state.validet_user_stages} </span>
                </div>  
                {localStorage.getItem("user_Role") == 2 ? <>
                    {/* <button type='button' className='Disconnected' onClick={this.Edit_data.bind(this)} name="Company_Stage" style={{backgroundImage :"url("+ Edit_icone +")",width:"20px", height:"20px", backgroundSize:"cover"}}></button> */}
                    <button type='button' className='Disconnected' onClick={this.SaveData_update.bind(this,"Company_Stage")} name="Company_Stage">Update</button></>
                    :"" }  
            </>)
        }
    }
    //onchange envet
 
    SaveData_update(data){
        console.log(data);

        let log = false;

        if(data =="update_name"){
            if(this.state.edit_name == true){
                if(this.state.Name == ""){
                    this.setState({ validet_name: "Please enter a name" });
                    log = true;
                }
            }
        }

        if(data =="update_location"){
            if(this.state.edit_location == true){
                if(this.state.location == "") {
                    this.setState({ validet_location: "Please enter a  city and state" });
                    log = true;
                }
                if(this.state.location.split(',').length != 2){
                    this.setState({ validet_location: "Please enter a  city and state" });
                    log = true;
                }
            }
        }

        if(data == "update_email"){
            if(this.state.edit_Email == true){
                if(this.state.Email == "" ) {
                    this.setState({ validet_Email: "Please enter a email address" });
                    log = true;
                }
    
                if(this.state.Email !="" ) {
                    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (re.test(this.state.Email)) {}else {
                        this.setState({ validet_Email: "Please enter a valid email address" })
                        log = true;
                    }           
                }   
            }
        }

        if(data == "Company_Website"){
            if(this.state.edit_website == true){
                if(this.state.website == "") {
                    this.setState({ validet_website: "Please enter a company website" });
                    log = true;
                }
            }
        }
        
        if(data == "Company_Stage"){
            if(this.state.edit_user_stages == true){
                if(this.state.user_stages == ""){
                    this.setState({ validet_user_stages: "Please select a company stage" });
                    log = true;
                }
            }
        }

        if(data == "Company_Size"){
            if(this.state.edit_user_sizes == true){
                if(this.state.user_sizes == "") {
                    this.setState({ validet_user_sizes: "Please select a company size" });
                    log = true;
                } 
            }
        }    

        if(data =="Company_Bio"){
            if(this.state.bio_edit_mode == true){
                if(this.state.bio == "") {
                    this.setState({ error_bio: "Please enter a bio" });
                    log = true;
                }
            }
        }

        if(log === true){ 
            return 
        }
        
        let user_Role = localStorage.getItem("user_Role");

        var formdata = new FormData();
        if(user_Role == 2){
            if(data =="update_name"){
                if(this.state.edit_name == true){
                    formdata.append("name", this.state.Name);
                }
            }

            if(data == "update_email"){
                if(this.state.edit_Email == true){
                    formdata.append("email", this.state.Email);
                }
            }

            if(data == "Company_Website"){
                if(this.state.edit_website == true){
                    formdata.append("website", this.state.website);
                }
            }

            if(data == "update_industry"){
                if(this.state.edit_industry == true){

                    var list_industries2 =  this.state.list_company_industry;
                    this.state.get_select_value.map(function(element1,i){ 
                        var temp_name = element1;
                        list_industries2.map(function(element2,j){ 
                            if(element2.name == element1){
                                temp_name = element2.id;
                            }
                        })
                        formdata.append("industries["+i+"]", temp_name);
                    })
                }
            }    

            if(data == "Company_Stage"){
                if(this.state.edit_user_stages == true){
                    formdata.append("stages[0]", this.state.user_stages);
                }
            }    

            if(data == "Company_Size"){
                if(this.state.edit_user_sizes == true){
                    formdata.append("sizes[0]", this.state.user_sizes);
                }   
            }

            if(data =="update_location"){
                if(this.state.edit_location == true){
                    formdata.append("city", this.state.location.split(',')[0]);
                    formdata.append("state", this.state.location.split(',')[1]);
                }
            }   

            if(data =="Company_Bio"){
                if(this.state.bio_edit_mode == true){
                    formdata.append("bio", this.state.bio);
                }
            }    
        } 
        
        if(user_Role == 4){
            if(data =="update_name"){
                if(this.state.edit_name == true){
                    formdata.append("name", this.state.Name)
                }
            }    
            if(data == "update_email"){
                if(this.state.edit_Email == true){
                    formdata.append("email", this.state.Email);
                }
            }    
        }

        const apiOption2 = {
            method: "post",
            headers:{
                'Authorization': 'Bearer '+localStorage.getItem("token"),  
                // 'Accept': 'application/json',
                // 'Content-Type': 'application/json',
            },
            body: formdata,
            redirect: 'follow'
        }
   
        fetch(this.props.APIPath+"update_profile",apiOption2)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            if(resJson.status == true){
                if(data =="update_name"){
                    if(this.state.edit_name == true){
                        localStorage.setItem("user_name", this.state.Name);
                        this.props.localStorage_Name(this.state.Name);
                    }
                }
                if(data == "update_email"){
                    if(this.state.edit_Email == true){
                        localStorage.setItem("Account_Email", this.state.Email);
                        this.props.localStorage_Email(this.state.Email);
                    }
                }    
                if(data =="update_location"){
                    if(this.state.edit_location == true){
                        this.props.localStorage_city_state(this.state.location.split(',')[0] , this.state.location.split(',')[1]);
                    }
                }

                if(data == "update_industry"){
                    if(this.state.edit_industry == true){
                        this.props.localStorage_company_industry(this.state.get_select_value);
                    }
                }    

                this.setState({showerror:true , showerrortype : 1 , showerrormessage: "Your profile is update" });

                if(data =="update_name"){
                    if(this.state.edit_name == true){
                        this.setState({edit_name: false });
                    }
                }    
                if(data == "update_email"){
                    if(this.state.edit_Email == true){
                        this.setState({edit_Email: false });
                    }
                }    
                if(data =="Company_Bio"){
                    if(this.state.bio_edit_mode == true){
                        this.setState({bio_edit_mode: false });
                    }
                }    
                if(data == "Company_Website"){
                    if(this.state.edit_website == true){
                        this.setState({edit_website: false });
                    }
                }    
                if(data == "update_industry"){
                    if(this.state.edit_industry == true){
                        this.setState({edit_industry: false });
                    }
                }
                if(data == "Company_Stage"){
                    if(this.state.edit_user_stages == true){
                        this.setState({edit_user_stages: false });
                    }
                }
                if(data == "Company_Size"){
                    if(this.state.edit_user_sizes == true){
                        this.setState({edit_user_sizes: false });
                    }
                }    
                if(data =="update_location"){
                    if(this.state.edit_location == true){
                        this.setState({edit_location: false });
                    }
                }    
                this.error_handaling();
            }else{
                this.setState({ valid_email_address: "Email address is already registered" });
                this.setState({showerror:true , showerrortype : 2 , showerrormessage: resJson.message});
                this.error_handaling();
            }
        })
        .catch(error => {
            this.setState({ showerror: true ,showerrortype : 2 ,showerrormessage: "Invalid Response" });
            this.error_handaling();
        });
    }

    error_handaling(){
        if(this.state.showerror == true){
            setTimeout(function() {
               this.setState({showerror: false});
            }.bind(this),3000); 

            if(this.state.showerrortype == 1){
                setTimeout(function() {
                    this.setState({redirect: true});
                }.bind(this),3100);     
            }

            return(
                <>
                    <Alert style={{position:"absolute",top:"0",left:"0",right:"0", width:"100%", float:"left", textAlign:"center"}} variant={this.state.showerrortype == 1 ? "success" : "danger"}>
                        {this.state.showerrormessage}
                    </Alert>
                </>
            )
        }
    }

    city_state_list(e){
        this.setState({State : e.target.value,valition_city:""});

        var city_state_list_val = [];
        const temp = {
            method: "GET", 
        }
        fetch('https://nominatim.openstreetmap.org/search?q='+e.target.value+'&limit=100&format=json&addressdetails=1',temp)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            resJson.map(function(es, i){  
                if(
                    ( es.address.hasOwnProperty('city') || es.address.hasOwnProperty('county') )
                    &&
                    ( es.address.hasOwnProperty('state') || es.address.hasOwnProperty('province') )
                ){
                    var city_temp = '';
                    var state_temp = '';

                    if(es.address.hasOwnProperty('county')){ city_temp = es.address.county; }
                    if(es.address.hasOwnProperty('city')){ city_temp = es.address.city; }

                    if(es.address.hasOwnProperty('province')){ state_temp = es.address.province; }
                    if(es.address.hasOwnProperty('state')){ state_temp = es.address.state; }

                    city_state_list_val.push({
                        value : city_temp+','+state_temp,
                        name : es.display_name
                    })
                }
            })
            this.setState({city_state_list : city_state_list_val});
        });
    }

    change_greenhouse_status(e){
        // console.log(e.target.value)

        var formdata = new FormData();
        formdata.append("status", e.target.value);
        const apiOption2 = {
            method: "post",
            headers:{
                'Authorization': 'Bearer '+localStorage.getItem("token"),  
                // 'Accept': 'application/json',
                // 'Content-Type': 'application/json',
            },
            body: formdata,
            redirect: 'follow'
        }
   
        fetch(this.props.APIPath+"greenhouse-status",apiOption2)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            console.log(resJson);
            if(resJson.status == true){
                if(e.target.value == 1){
                    this.setState({showerror:true , showerrortype : 1 , showerrormessage: "Greenhouse Connected Successfully!" });
                }else{
                    this.setState({showerror:true , showerrortype : 1 , showerrormessage: "Greenhouse Disconnected Successfully!" });
                }
                this.setState({greenhouse_status:e.target.value})
                this.props.greenhouse_status_change(e.target.value)
            }else{
                this.setState({showerror:true , showerrortype : 2 , showerrormessage: resJson.message});
                this.error_handaling();
            }
        })
        .catch(error => {
            this.setState({ showerror: true ,showerrortype : 2 ,showerrormessage: "Invalid Response" });
            this.error_handaling();
        });
    }

    openModal = () => this.setState({ isOpen:true });
    closeModal = () => this.setState({ isOpen:false });

    addNewFild(event){
        event.preventDefault();
        let log = false;
        
        if(!this.account_token.current.value){
            this.setState({ valition_account_token: "Please enter a account token" });
            log = true;
        }

        if(log == true){ return }

        let account_token =  this.account_token.current.value

        var formdata = new FormData();
        formdata.append("token", account_token);
        const apiOption = { method: "post", body: formdata, redirect: 'follow' }
   
        fetch(this.props.APIPath+"greenhouse_tokenVerify",apiOption)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            if(resJson.status == true){

                var formdata2 = new FormData();
                formdata2.append("greenhousetoken", account_token);
                // const apiOption2 = { method: "post", body: formdata2, redirect: 'follow' }

                const apiOption2 = {
                    method: "post",
                    headers:{
                        'Authorization': 'Bearer '+localStorage.getItem("token")
                    },
                    body: formdata2,
                    redirect: 'follow'
                }

                fetch(this.props.APIPath+"greenhouse-integration",apiOption2)
                .then(function(res) {
                    return res.json();
                }).then(resJson => {
                    if(resJson.status == true){
                        this.setState({isOpen:false});
                        this.setState({greenhousetoken:account_token});
                        this.props.greenhousetoken_change(account_token);
                        this.setState({greenhouse_status:1})
                        this.props.greenhouse_status_change(1)
        
                        this.setState({showerror:true ,greenhouse_show:1, showerrortype : 1 , showerrormessage: "Your account is connet with greenhouse" });
                        this.error_handaling();
                    }else{
                        this.setState({ valition_account_token: "Please enter a valid greenhouse token" });
                        this.error_handaling();
                    }
                })
                .catch(error => {
                    this.setState({ showerror: true ,showerrortype : 2 ,showerrormessage: "Invalid Response" });
                    this.error_handaling();
                });

            }else{
                this.setState({ valition_account_token: "Please enter a valid greenhouse token" });
                this.error_handaling();
            }
        })
        .catch(error => {
            this.setState({ showerror: true ,showerrortype : 2 ,showerrormessage: "Invalid Response" });
            this.error_handaling();
        });
    }

    render() {
        return (
            <>
                {this.error_handaling()}
                <div className='tab_inner_contanet Personal_information_main'>
                    <h3>Personal Information</h3>
                    <div className='Personal_information_form'>
                        <div className='Personal_information_form_data'>
                            <label >Name</label>
                            <div className='Personal_information_value'>
                                {this.show_name()}
                            </div>
                            <span></span>
                        </div>
                        <div className='Personal_information_form_data'>
                            <label >Location</label>
                            <div className='Personal_information_value'>
                                {this.show_Location()}
                                {/* {localStorage.getItem("user_Role") == 2 ? 
                                <button type='button' onClick={this.Edit_data.bind(this)} name="Location">Edit</button>
                                :"" } */}
                            </div>
                            <span></span>
                        </div>
                        <div className='Personal_information_form_data Personal_information_Email'>
                            <label>Email Address</label>
                            <div className='Personal_information_value'>
                                {this.show_Email()}
                            </div>
                            <span></span>
                        </div>
                        <div className='Personal_information_form_data'>
                            <div className='Personal_information_title' style={{maxWidth:"290px",display:"flex",justifyContent:"space-between"}} >
                                <label > Industry </label>
                                {/* {localStorage.getItem("user_Role") == 2 ? 
                                    <button type='button' onClick={this.Edit_data.bind(this)} style={{ textDecoration:"none", background: "transparent", border: "none", color: "var(--green)", fontWeight: "400" }} name="Industry">Edit</button> 
                                :"" } */}
                            </div>
                            <div className='Personal_information_value Location'>
                                {this.show_Company_Industry()}
                            </div>
                            
                        </div>
                        <div className='Personal_information_form_data'>
                            <div className='Personal_information_title' style={{maxWidth:"290px",display:"flex",justifyContent:"space-between"}} >
                                <label >Company Bio</label>
                                {localStorage.getItem("user_Role") == 2 ? 
                                <>
                                    {this.show_Company_Bio_edit()}
                                </>
                                    
                                :"" }
                            </div>
                            <div className='Personal_information_value Company-Bio' >
                                {this.show_Company_Bio()}
                            </div>
                            <span></span>
                        </div>
                        <div className='Personal_information_value Personal_information_form_data Company-Size' style={{marginBottom:"0px"}}>
                            <label>Company Size</label>
                        </div>
                        {this.show_Company_Size()}
                        <div className='Personal_information_form_data Company-Size Company-Website' style={{maxWidth:"290px", marginBottom:"20px"}}>
                            <div className='Personal_information_value'>
                                <label>Company Website</label>
                            </div>    
                            <div className='Personal_information_value'>
                                {this.show_Company_Website()}
                                {this.state.edit_website == false ? <a href={this.state.website} target="_blank"  className="Connected">Visit Site</a> :"" }
                            </div>
                        </div>
                        <div className='Personal_information_form_data Company-Size'>
                            <div className='Personal_information_value'>
                                <label>Company Stage</label>
                            </div>
                            <div className='Personal_information_value'>
                                {this.show_Company_Stage()}
                            </div>
                            <span></span>
                        </div>
                        <div className='Personal_information_form_data Company-Size Company-Website Greenhouse-Account' >
                            <div className='Personal_information_value'>
                                <label>Greenhouse Account</label>
                                {localStorage.getItem('user_Role') == 2 ? 
                                    <>
                                    {this.state.greenhousetoken == '' || this.state.greenhousetoken == null?
                                        <button className='Disconnected' onClick={this.openModal.bind(this)}>Connect</button>
                                        // Here popup
                                        :
                                        // <button className='Disconnected'>Connected</button>  
                                        (this.state.greenhouse_status == 0?
                                            <button className='Disconnected' onClick={this.change_greenhouse_status.bind(this)} value="1">Connect</button>
                                            // Here Active
                                            :
                                            <button className="Disconnected" onClick={this.change_greenhouse_status.bind(this)} value="0">Disconnect</button>
                                            // Here DeActive
                                        )
                                    }
                                    </>
                                :""}
                                
                            </div>    
                            <div className='Personal_information_value Greenhouse-Account-in'>
                                <span>Greenhouse</span>
                                {this.state.greenhousetoken == '' || this.state.greenhousetoken == null ?
                                    <button className='Disconnected'>Disconnected</button>
                                    // Here popup
                                    :
                                    (this.state.greenhouse_status == 0 ?
                                        <button className='Disconnected'>Disconnected</button>
                                        // Here Active
                                        :
                                        <button className="Connected">Connected</button>
                                        // Here DeActive
                                    )
                                }
                            </div>
                        </div>
                         <>
                        {/* { this.state.edit_Email == true || this.state.edit_Greenhouse_Accoun || this.state.bio_edit_mode == true || this.state.edit_industry == true || this.state.edit_location == true || this.state.edit_name == true || this.state.edit_user_sizes == true || this.state.edit_user_stages == true || this.state.edit_website == true || this.props.bg_imagePreviewUrl != ""  || this.props.imagePreviewUrl != "" ?
                            <div className='Update_save_button' style={{float:"left",width:"100%",marginBottom:"30px"}}>
                            <button class="Profile_update_button" type='button' onClick={this.SaveData_update.bind(this)}>Update</button>            
                        </div> :
                        ""} */}
                        </>
                        
                    </div>
                </div>

                <Modal className="new_team_member_box_main" show={this.state.isOpen} onHide={this.closeModal}>
                    <Modal.Body>
                        <h3>Connect Greenhouse Account</h3>
                        <div className="new_team_member_box_inner">
                            <form onSubmit={this.addNewFild.bind(this)}>
                                <div className="singup_input" style={{float:"left",width:"100%"}}>
                                    <label style={{float:"left",width:"100%",fontWeight:"500"}}>Account token</label>
                                    <input type="text" placeholder="Add account token" ref={this.account_token} name="account_token" />
                                    <span className='form_validtion_class'>{this.state.valition_account_token}</span>
                                </div>
                                <div className="singup_input d-flex align-items-center justify-content-between singup_setp-3-button-wapper" style={{textAlign:"center",padding: "5% 0 0"}}>
                                    <div className="back_wapper">
                                        <button type="button"  onClick={this.closeModal.bind(this)}> Cancel </button>
                                    </div>
                                    <button onClick={this.addNewFild.bind(this)} type="button" className="singup_input_button singup_setp-3-button">  Continue  </button>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}
export default Personal_information;