import React, { Component } from 'react';
import ReactDOM from "react-dom";
import airbnb from '../../assets/image/upload_image.png';
import TagsInput from './TagsInput';
import Alert from 'react-bootstrap/Alert';
//CSS

//Images
import upload_image from '../../assets/image/upload_image.png';
class Team_member_stap4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Fome data erroe
            valid_company_website: "",
            valid_email_address:"",
            //save upload data
            company_website: this.props.phone,//this.props.Company_Website,
            email_address:this.props.email,//this.props.email,

            showerror:false,
            showerrortype : 0,
            showerrormessage : "",
        }
        // add form value
        this.Company_Website = React.createRef();
        this.Email_Address = React.createRef();

         // get list of company size
         const apiOption1 = {
            method: "GET",
            // headers:{
            //     'Accept': 'application/json',
            //     'Content-Type': 'application/json',
            // },
        }
        fetch(this.props.apiPath+"industries?api_key=48e6c87a_6760_4b07_b987_7128fbea8545_1db5",apiOption1)
        .then(function(res) {
            return res.json();
        }).then(resJson => { 
            let list_industries = resJson.data;
            this.state.list_industries = list_industries;
            this.setState({list_industries:list_industries});
        })
    }

    handleKeyPress(e){
        if(e.target.name == "Email_Address"){
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            this.setState({ email_address:e.target.value });
            if (re.test(e.target.value)) {
                this.setState({
                    valid_email_address:"",
                });
            }else {
                this.setState({ valid_email_address: "Please enter a valid email address" })
            }           
        }

        if(e.target.name == "Company_Website"){
            this.setState({
                valid_company_website:"",
                company_website : e.target.value
            });
        }
    }

    gotobackStap(){ this.props.clickEvent("Team_member_stap3");}

    //Fome submit function
    Save_company_data_stap_2(event){
        event.preventDefault();
        let log = false;
        if(!this.Company_Website.current.value) {
            this.setState({ valid_company_website: "Please enter a phone number" });
            log = true;
        }

        if(!this.Email_Address.current.value ) {
            this.setState({ valid_email_address: "Please enter a email address" });
            log = true;
        }

        if(log === true){ 
            return 
        }
        
        this.state.company_website = this.Company_Website.current.value
        this.state.email_address = this.Email_Address.current.value

        this.setState({company_website: this.state.company_website});
        this.setState({email_address: this.state.email_address});

        this.props.savedata(this.state.company_website,this.state.email_address);

        const apiOption = {
            method: "post",
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({
                "email" :  this.Email_Address.current.value
            }), 
        }
   
        fetch(this.props.apiPath+"send_code",apiOption)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            if(resJson.status == true){
                this.setState({showerror:true , showerrortype : 1 , showerrormessage: "OTP is send in your email" });
                this.error_handaling();
            }else{
                this.setState({ valid_email_address: "Email address is already registered" });
                this.setState({showerror:true , showerrortype : 2 , showerrormessage: "Email address is already registered" });
                this.error_handaling();
            }
        })
        .catch(error => {
            this.setState({ showerror: true ,showerrortype : 2 ,showerrormessage: "Invalid Response" });
            this.error_handaling();
        });
    }

    error_handaling(){
        if(this.state.showerror == true){
            setTimeout(function() {
               this.setState({showerror: false});
            }.bind(this),3000); 

            if(this.state.showerrortype == 1){
                setTimeout(function() {
                    this.props.clickEvent("Team_member_stap5");
                }.bind(this),3100);     
            }

            return(
                <>
                    <Alert style={{position:"absolute",top:"0",left:"0",right:"0", width:"100%", float:"left", textAlign:"center"}} variant={this.state.showerrortype == 1 ? "success" : "danger"}>
                        {this.state.showerrormessage}
                    </Alert>
                </>
            )
        }
    }

    render() {
        let $imagePreview = null;
        if (this.props.logoUrl != "") {
            //   $imagePreview = (<img style={{width:"100%"}} src={this.props.logoUrl} />);
            $imagePreview = this.props.logoUrl;
        } else {
            //   $imagePreview = (<img style={{width:"100%"}} src={upload_image} />);
            $imagePreview = upload_image;
        }
        return (
            <>
                 {this.error_handaling()}              
                <div className="row signin_signup_background singni_singup_meta_data3">
                    <div className="col-lg-6"></div> 
                    <div className="col-lg-6" style={{background:'#f9f9f9',padding:"1% 5% 5% 5%",textAlign:"left"}}>
                        <div className="singup_box" style={{ background:'overlay',background: "#FFF",float: "left", width: "100%", height: "100%",borderRadius: "6px"}}>
                            <div className="Singup_stap_3_progress" style={{width:'51.45%'}}></div>
                            <div className="singup_box_inner">
                                <div className="singup_box_title singup_box_title_logo d-flex justify-content-start f-left w-100 align-items-center" style={{padding:"5% 10% 5%"}}>
                                    <div className="singup_box_title_logo_image" style={{marginRight:'12px',borderRadius:'100%',width:'82px',height:'80px',backgroundSize:'cover',backgroundImage: `url(${$imagePreview})`}}>
                                        {/* {$imagePreview} */}
                                    </div>
                                    <div className="singup_box_title_logo_text" style={{width:"85%"}}>
                                        <h3 style={{color:'var(--green)',fontFamily: "Poppins", fontStyle: "normal", fontWeight: "bold", fontSize: "32px"}}>Team Member Account</h3>
                                        <p style={{color:'#6d6d6d',fontFamily: "Poppins", fontStyle: "normal", fontWeight: "normal", fontSize: "24px"}}>Primary Information</p>
                                    </div>    
                                </div>
                                <div className="singup_box-singup-stap-2" style={{padding:"0% 10% 6%",float:"left",width:"100%"}}>
                                    <form style={{float:"left",width:"100%",}} onSubmit={this.Save_company_data_stap_2.bind(this)}>
                                        <div className="singup_input" style={{float:"left",width:"100%",marginBottom:"5%"}}>
                                            <label> Email Address</label>
                                            <input placeholder='Email Address' onChange={this.handleKeyPress.bind(this)} value={this.state.email_address}  name='Email_Address' ref={this.Email_Address} type="Email" />
                                            <span className="form_validtion_class">{ this.state.valid_email_address }</span>
                                        </div>
                                        <div  className="singup_input" style={{float:"left",width:"100%",marginTop:"5%",marginBottom:"5%"}}>
                                            <label>Phone Number</label>

                                            <input placeholder='Phone Number'  onChange={this.handleKeyPress.bind(this)} value={this.state.company_website}  name='Company_Website' type="text" ref={this.Company_Website} />
                                            <span className="form_validtion_class">{ this.state.valid_company_website }</span>
                                        </div>
                                        <div className="singup_input d-flex align-items-center justify-content-between singup_setp-3-button-wapper" style={{textAlign:"center",padding: "42% 0 0"}}>
                                            <div className="back_wapper">
                                                <button onClick={this.gotobackStap.bind(this)} type="button"> Back </button>
                                            </div>
                                            <button type="button" onClick={this.Save_company_data_stap_2.bind(this)} className="singup_input_button singup_setp-3-button"> Continue </button>
                                        </div>
                                    </form> 
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </>
        );
    }
}

export default Team_member_stap4;