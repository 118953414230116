import React, { Component } from 'react';
import {Form} from 'react-bootstrap'
import MyComponent from '../../AccountCreation/SingUp/RangSlider';
import Alert from 'react-bootstrap/Alert';
import Slider from '@mui/material/Slider';

class Demographic_information extends Component {
    constructor(props) {
        super(props);
        
        console.log('this.state.by.rinku')
        console.log(this.props)
        this.state = {
            Man:this.props.men,
            valid_men :"",
            Woman:this.props.women,
            valid_Women:"",
            other : this.props.non_binary,
            valid_other:"",
            edit_mode : false,
            lgbtq:this.props.lgbt,
            valid_lgbtq:"",
            edit_mode_lgbt : false
        }

        this.showeditdataon();
    }

    editdataon(){
        if(this.state.edit_mode == false){
            this.setState({edit_mode : true});
        }else{
            this.setState({edit_mode : false}); 
        }
        
    }

    lgbteditdataon(){
        if(this.state.edit_mode_lgbt == false){
            this.setState({edit_mode_lgbt : true});
        }else{
            this.setState({edit_mode_lgbt : false}); 
        }
    }

    getChangeEventMan(event){

        var Woman = this.state.Woman == 111 ? this.props.women : this.state.Woman;
        var other = this.state.other == 111 ? this.props.non_binary : this.state.other;
        
        if((event.target.value + Woman + other) < 101){
            console.log(event.target.value , Woman , other)
            this.setState({Man : event.target.value});
        }
    }

    getChangeEventWomen(event){

        var Man = this.state.Man == 111 ? this.props.men : this.state.Man;
        var other = this.state.other == 111 ? this.props.non_binary : this.state.other;

        
        if((event.target.value + Man + other) < 101){
            console.log(event.target.value , Man , other)
            this.setState({Woman : event.target.value});
        }
    }

    getChangeEventNon(event){

        var Man = this.state.Man == 111 ? this.props.men : this.state.Man;
        var Woman = this.state.Woman == 111 ? this.props.women : this.state.Woman;

        
        if((event.target.value + Man + Woman) < 101){
            console.log(event.target.value , Man , Woman)
            this.setState({other : event.target.value});
        }
    }

    getChangeEventLGBTQ(data){
        let lgbtq = data;
        this.state.lgbtq = lgbtq;
        this.setState({
            lgbtq:data.target.value,
        });
    }

    showeditdataonEdit(){ 
        if(this.state.edit_mode == true){
            return(
            <>
                <div className='dsf'>
                    <button onClick={this.editdataon.bind(this)} className='Disconnected'>Edit</button>
                    <button onClick={this.getupdate_data.bind(this,"edit_slider")} className='Disconnected'>Update</button>   
                </div>
            </>)
        }else{
            return(
                <>
                    <button onClick={this.editdataon.bind(this)} className='Disconnected'>Edit</button>
                </>
            )    
        }
    }

    showeditdataon(){
        
        if(this.state.edit_mode == true){
            return(
                <form style={{float:"left",width:"100%"}}>
                    <div className="singup_input slider-bars" style={{float:"left",width:"100%",marginBottom:"0%",marginTop:"0%"}}>
                        <label>Men</label>
                        <Slider valueLabelFormat={ this.state.Man == 111 ? this.props.men : this.state.Man+'%'} getAriaLabel={() => 'Minimum distance shift'} min={0} step={1} max={100} value={this.state.Man == 111 ? this.props.men : this.state.Man} name="man" onChange={this.getChangeEventMan.bind(this)}  valueLabelDisplay="on"  />  
                        <span className="form_validtion_class">{this.state.valid_Men}</span>
                    </div>
                    <div className="singup_input slider-bars" style={{float:"left",width:"100%",marginBottom:"0%",marginTop:"0%"}}>
                        <label>Women</label>
                        <Slider valueLabelFormat={this.state.Woman == 111 ? this.props.women : this.state.Woman+'%'} getAriaLabel={() => 'Minimum distance shift'} min={0} step={1} max={100} value={this.state.Woman == 111 ? this.props.women : this.state.Woman} name="man" onChange={this.getChangeEventWomen.bind(this)}  valueLabelDisplay="on"  />  
                        {/* <MyComponent Womenoldvalue={this.state.Women} value={this.state.Women} Women={true} changeEvent={this.getChangeEventWomen.bind(this)}  />  */}
                        <span className="form_validtion_class">{this.state.valid_Women}</span>
                    </div>
                    <div className="singup_input slider-bars" style={{float:"left",width:"100%",marginBottom:"0%",marginTop:"0%"}}>
                        <label>Non - Binary</label>
                        <Slider valueLabelFormat={this.state.other == 111 ? this.props.non_binary : this.state.other+'%'} getAriaLabel={() => 'Minimum distance shift'} min={0} step={1} max={100} value={this.state.other == 111 ? this.props.non_binary : this.state.other} name="man" onChange={this.getChangeEventNon.bind(this)}  valueLabelDisplay="on"  />  
                        {/* <MyComponent manoldvalue={this.state.other} value={this.state.other} Non={true} changeEvent={this.getChangeEventNon.bind(this)}   />  */}
                        <span className="form_validtion_class">{this.state.valid_Non_Binary}</span>
                    </div>
                </form>
            );
        }else{
                return(
                    <>
                        {Math.round(this.state.Man == 111 ? this.props.men : this.state.Man) + Math.round(this.state.Woman == 111 ? this.props.women : this.state.Woman) + Math.round( this.state.other == 111 ? this.props.non_binary : this.state.other) == 0?
                            <div className='genderbox_main'>
                                <div className='genderbox_non_binary' style={{width: '100%',background:'#6D6D6D'}}>Gender not available</div>
                            </div>
                        :    
                            <div className='genderbox_main'>
                                <div className='genderbox_men' style={{width:  this.state.Man == 111 ? `${this.props.men}%` : `${this.state.Man}%` }}> {Math.round(this.state.Man == 111 ? this.props.men : this.state.Man)}% </div> 
                                <div className='genderbox_Women' style={{width: this.state.Woman == 111 ? `${this.props.women}%`:`${this.state.Woman}%`  }}>{Math.round(this.state.Woman == 111 ? this.props.women : this.state.Woman)}%</div> 
                                <div className='genderbox_non_binary' style={{width: this.state.other == 111 ? `${this.props.non_binary}%`:`${this.state.other}%` }}>{Math.round( this.state.other == 111 ? this.props.non_binary : this.state.other)}%</div> 
                            </div>
                        }
                    </>
                );
        }
    }

    show_lgbtq_dataEdit(){
        if(this.state.edit_mode_lgbt == true){
            return(
            <>
            {localStorage.getItem("user_Role") == 2 ?
                <div className='dsf'>
                    <button onClick={this.lgbteditdataon.bind(this)} className='Disconnected'>Edit</button>
                    <button onClick={this.getupdate_data.bind(this,"edit_slider_lgbt")} className='Disconnected'>Update</button>   
                </div>                    
            :""}
                
            </>)
        }else{
            return(
                <>
                    {localStorage.getItem("user_Role") == 2 ?
                        <button onClick={this.lgbteditdataon.bind(this)} className='Disconnected'>Edit</button>
                    :""}    
                </>
            )    
        }
    }

    show_lgbtq_data(){
        if(this.state.edit_mode_lgbt == true){
            return(
                <>
                    <div className="singup_input slider-bars" style={{float:"left",width:"100%"}}>
                        <label style={{float:"left",width:"100%",fontWeight:"500"}}>LGBTQ+</label>
                        <Slider valueLabelFormat={ this.state.lgbtq == 111 ? this.props.lgbt+'%' : this.state.lgbtq+'%'} getAriaLabel={() => 'Minimum distance shift'} min={0} step={1} max={100} value={this.state.lgbtq == 111 ? this.props.lgbt : this.state.lgbtq} name="man" onChange={this.getChangeEventLGBTQ.bind(this)}  valueLabelDisplay="on"  />  
                        
                        {/* <MyComponent LGBTQ={true} changeEvent={this.getChangeEventLGBTQ.bind(this)}  />  */}
                        <span className="form_validtion_class">{this.state.valid_LGBTQ}</span>
                    </div>
                </>)
        }else{
            return(
                <>
                   <div className='genderbox_main'>
                        <div className='genderbox_men' style={{width: this.state.lgbtq == 111 ? `${this.props.lgbt}%`: `${this.state.lgbtq}%` }}> {Math.round( this.state.lgbtq == 111 ? this.props.lgbt : this.state.lgbtq)}% </div> 
                        <div className='genderbox_non_binary' style={{width: this.state.lgbtq == 111 ? `${100 - this.props.lgbt}%`:`${100 - this.state.lgbtq}%`}}></div> 
                        
                    </div>
                </>
            );
        }
    }   
    
    getupdate_data(data){

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        if(data == "edit_slider"){
            var Woman = this.state.Woman == 111 ? this.props.women : this.state.Woman;
            var other = this.state.other == 111 ? this.props.non_binary : this.state.other;
            var Man = this.state.Man == 111 ? this.props.men : this.state.Man;
        }
       
        
        if(data == "edit_slider_lgbt"){
            var lgbt = this.state.lgbtq == 111 ? this.props.lgbt : this.state.lgbtq;
        } 

        if(data == "edit_slider"){
            var raw = JSON.stringify({
                "men": Man,
                "women": Woman,
                "non_binary": other,
            });    
        }

        if(data == "edit_slider_lgbt"){
            var raw = JSON.stringify({
                "lgbt": lgbt
            });    
        }
        

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(this.props.apiPath+"update_dei", requestOptions)
        .then(res => {
            return res.json();
        })
        .then(resJson =>{
            if(resJson.status == true){
                this.setState({showerror:true , showerrortype : 1 , showerrormessage: "Your profile is update" });
                if(data == "edit_slider_lgbt"){
                    if(this.state.edit_mode_lgbt == true){
                        this.setState({edit_mode_lgbt: false });
                    }
                }   
                if(data == "edit_slider"){ 
                    if(this.state.edit_mode == true){
                        this.setState({edit_mode: false });
                    }
                }    
            }else{
                this.setState({showerror:true , showerrortype : 2 , showerrormessage: "Something wrong with api fields" });
                this.error_handaling();
            }
        })
        .catch(error => {
            this.setState({ showerror: true ,showerrortype : 2 ,showerrormessage: "Invalid Response" });
            this.error_handaling();
        });
    }
    
    error_handaling(){
        if(this.state.showerror == true){
            setTimeout(function() {
               this.setState({showerror: false});
            }.bind(this),3000); 

            if(this.state.showerrortype == 1){
                setTimeout(function() {
                    this.setState({redirect: true});
                }.bind(this),3100);     
            }

            return(
                <>
                    <Alert style={{position:"absolute",top:"0",left:"0",right:"0", width:"100%", float:"left", textAlign:"center"}} variant={this.state.showerrortype == 1 ? "success" : "danger"}>
                        {this.state.showerrormessage}
                    </Alert>
                </>
            )
        }
    }

    render() {
        return (
            <>
                {this.error_handaling()}
                <div className='tab_inner_contanet Personal_information_main'>
                    <h3>Demographic Information</h3>
                    <div className='Personal_information_form'>
                        <div className='Genderbox'>
                            <div className='Personal_information_value'>
                                <label>Gender</label>
                                {localStorage.getItem("user_Role") == 2 ?
                                <>
                                    {this.showeditdataonEdit()}
                                </>
                                :""}
                            </div>    
                            {this.showeditdataon()}
                            <div className='genderbox_select'>
                                <div className='gender-box_seected'>
                                {['checkbox'].map((type) => (
                                    <div key={`default-${type}`}>
                                        <Form.Check checked type={type} id={`Men`} label={`Men`} />
                                        <Form.Check checked type={type} id={`Women`} label={`Women`} />
                                        <Form.Check checked type={type} id={`Non-binary`} label={`Non-binary`} />
                                    </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='Genderbox lgbtq_box'>
                            <div className='Personal_information_value'>
                                <label>LGBTQ+ Population</label>
                                {this.show_lgbtq_dataEdit()}
                            </div>    
                            {this.show_lgbtq_data()}
                        </div>
                    </div>
                    {/* {localStorage.getItem("user_Role") == 2 ? <>
                    {this.state.edit_mode == true || this.state.edit_mode_lgbt == true ? 
                        <>
                            <div className='Update_save_button' style={{float:"left",width:"100%",marginTop:"30px"}}>
                                <button type="button"  onClick={this.getupdate_data.bind(this)} className="Dashbaord_stap-box-button-next Profile_update_button"> Update Profile </button>
                            </div> 
                            
                        </>
                    :""} 
                    </>
                    :"" }    */}
                </div>
            </>
        );
    }
}
export default Demographic_information;