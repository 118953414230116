
import React, { Component } from 'react';
import airbnb from '../../assets/image/airbnb.png';
import Alert from 'react-bootstrap/Alert';

//CSS

//Images
import upload_image from '../../assets/image/upload_image.png';


class Singup_stap_5 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validCode : "", 
            log : "1",

            showerror:false,
            showerrortype : 0,
            showerrormessage : "",
            Resend_otp : false,
        }
        // add form value
        this.code1 = React.createRef();
        this.code2 = React.createRef();
        this.code3 = React.createRef();
        this.code4 = React.createRef();
    }

    inputfocus = (elmnt) => {
        const re = /^[0-9\b]+$/;
        if (elmnt.target.value === '' || re.test(elmnt.target.value)) {
            const next = elmnt.target.tabIndex;
            if (next < 6) {
                console.log(elmnt.target.form.elements[next]);
                elmnt.target.form.elements[next].focus();
                this.setState({validCode:""});
                this.setState({log:"0"});
            }
        }else{
            elmnt.preventDefault();
        }
    }

    apiretun(){
        this.state.validCode = "OTP is not valid";
        this.setState({ validCode: this.state.validCode})
    }
            
    //resend email 
    resendEmailotp(){
        const apiOption = {
            method: "post",
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({
                "email" :  this.props.get_emdil_by_admin
            }), 
        }
   
        fetch(this.props.apiPath+"send_code",apiOption)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            if(resJson.status == true){
                this.setState({Resend_otp:true, showerror:true , showerrortype:3 , showerrormessage: "OTP resend please check your email!" });
                this.error_handaling();
            }else{

            }
        })
        .catch(error => {
            this.setState({ showerror: true ,showerrortype : 2 ,showerrormessage: "Invalid Response" });
            this.error_handaling();
        });
    }

    //
    Save_company_data_stap_4(event){
        event.preventDefault();
        let log = false;
        if(!this.code1.current.value || this.code1.current.value == ""){
            this.setState({ validCode: "Please enter a OTP" });
            log = true;
        }
        if(!this.code2.current.value || this.code2.current.value == ""){
            this.setState({ validCode: "Please enter a OTP" });
            log = true;
        }
        if(!this.code3.current.value || this.code3.current.value == ""){
            this.setState({ validCode: "Please enter a OTP" });
            log = true;
        }
        if(!this.code4.current.value || this.code4.current.value == ""){
            this.setState({ validCode: "Please enter a OTP" });
            log = true;
        }

        if(log === true){ 
            console.log("hello all data");
            return 
        }

        let value = this.code1.current.value+this.code2.current.value+this.code3.current.value+this.code4.current.value;

        const apiOptionOTP = {
            method: "post",
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({
                "email" :  this.props.get_emdil_by_admin,
                "code" : value
            }), 
        }
   
        fetch(this.props.apiPath+"verify_email",apiOptionOTP)
        
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            if(resJson.status == true){
                this.setState({Resend_otp:false , showerror:true , showerrortype:1 , showerrormessage: "Email verified!" });
                this.error_handaling();
            }else{
                this.setState({ validCode: "Please enter a valid OTP", showerror:true, showerrortype:2 , showerrormessage: "Please enter a valid OTP"  });
                this.error_handaling();
            }
        })
        .catch(error => {
            this.setState({ showerror: true ,showerrortype : 2 ,showerrormessage: "Invalid Response" });
            this.error_handaling();
        });
    }

    error_handaling(){
        if(this.state.showerror == true){
            setTimeout(function() {
               this.setState({showerror: false});
            }.bind(this),3000); 

            if(this.state.Resend_otp == false){
                if(this.state.showerrortype == 1){
                    setTimeout(function() {
                        this.props.clickEvent("stap5");  
                    }.bind(this),3100);     
                }
            }    

            return(
                <>
                    {this.state.Resend_otp == false ? <>
                    <Alert style={{position:"absolute",top:"0",left:"0",right:"0", width:"100%", float:"left", textAlign:"center"}} variant={this.state.showerrortype == 1 ? "success" : "danger"}>
                        {this.state.showerrormessage}
                    </Alert>
                    </> : "" }
                    {this.state.Resend_otp == true ? 
                        <>
                            <Alert style={{position:"absolute",top:"0",left:"0",right:"0", width:"100%", float:"left", textAlign:"center"}} variant={this.state.showerrortype == 3 ? "success" : "danger"}>
                                {this.state.showerrormessage}
                            </Alert>
                        </>
                    :""    
                    }  
                </>
            )
        }
    }

    //gotonextStap(){ this.props.clickEvent("Connect_greenhouse"); }

    render() {
        let $imagePreview = null;
        if (this.props.logoUrl != "") {
        //   $imagePreview = (<img style={{width:"100%"}} src={this.props.logoUrl} />);
            $imagePreview = this.props.logoUrl;
        } else {
        //   $imagePreview = (<img style={{width:"100%"}} src={upload_image} />);
            $imagePreview = upload_image;
        }
        return (
            <>
            {this.error_handaling()}
            <div className="row signin_signup_background singni_singup_meta_data5">
                <div className="col-lg-6"></div> 
                <div className="col-lg-6" style={{background:'#f9f9f9',padding:"1% 5% 5% 5%",textAlign:"left"}}>
                    <div className="singup_box" style={{marginTop:"5%", background:'overlay',background: "#FFF",float: "left", width: "100%", height: "auto",borderRadius: "6px"}}>
                        <div className="Singup_stap_6_progress" style={{width:"75%",float:"left",height:"5px", borderTop:"10px solid teal", borderRadius:"25px 0px 0 0"}} ></div>
                        <div className="singup_box_inner">
                            <div className="singup_box_title singup_box_title_logo d-flex justify-content-center f-left w-100 align-items-center" style={{padding:"10% 10% 5%"}}>
                                <div className="singup_box_title_logo_image" style={{borderRadius:'100%',width:'80px',height:'80px',backgroundSize:'cover',backgroundImage: `url(${$imagePreview})`}}>
                                    {/* {$imagePreview} */}
                                </div>
                            </div>
                            <div className="singup_box-singup-stap-2 singup_box-singup-stap-5" style={{padding:"0% 24% 6%",float:"left",width:"100%"}}>
                                <form style={{float:"left",width:"100%",textAlign:"center"}} onSubmit={this.Save_company_data_stap_4.bind(this)}>
                                    <div className="">
                                        <h3 style={{color:"charcolblack",fontSize:"20px",fontStyle: "normal", fontWeight: "normal",lineHeight: "30px",textAlign:"center",marginBottom:"8%",fontWeight:"500"}}>
                                            A Verification Code Was Sent To <br/>
                                            <span style={{color:"var(--green)",fontSize:"24px",fontStyle: "normal", fontWeight: "normal"}}> {this.props.get_emdil_by_admin} </span>
                                        </h3>

                                        <span style={{color:"charcolblack",fontSize:"18px",fontStyle: "normal", fontWeight: "normal",float:"left", width:"100%", marginBottom:"2%"}}>Enter verification code</span>
                                        <span style={{color:"charcolblack",fontSize:"18px",fontStyle: "normal", fontWeight: "normal"}}>Didn't Receive the code? <button type="button" onClick={this.resendEmailotp.bind(this)} style={{color:"teal",fontSize:"18px",fontStyle: "normal", fontWeight: "normal", padding:"0", backgroundColor:"transparent", border:"none" }}>Resend</button></span>
                                    </div>

                                    <div className="d-flex justify-content-between" style={{maxWidth:"400px",margin:"10% auto 0"}}>
                                        <div className="singup_input" style={{float:"left",marginBottom:"5%"}}>
                                            <input ref={this.code1}  type="text"  tabIndex="2" maxLength="1" onKeyUp={e => this.inputfocus(e)} style={{textAlign:"center",fontSize:"18px",fontStyle: "normal", fontWeight: "normal"}}  />
                                        </div>
                                        <div className="singup_input" style={{float:"left",marginBottom:"5%"}}>
                                            <input ref={this.code2} type="text" tabIndex="3" maxLength="1" onKeyUp={e => this.inputfocus(e)} style={{textAlign:"center",fontSize:"18px",fontStyle: "normal", fontWeight: "normal",borderColor:"grey"}} />
                                        </div>
                                        <div className="singup_input" style={{float:"left",marginBottom:"5%"}}>
                                            <input ref={this.code3} type="text" tabIndex="4" maxLength="1" onKeyUp={e => this.inputfocus(e)} style={{textAlign:"center",fontSize:"18px",fontStyle: "normal", fontWeight: "normal",borderColor:"grey"}} />
                                        </div>
                                        <div className="singup_input" style={{float:"left",marginBottom:"5%"}}>
                                            <input ref={this.code4} type="text" tabIndex="5" maxLength="1" onKeyUp={e => this.inputfocus(e)} style={{textAlign:"center",fontSize:"18px",fontStyle: "normal", fontWeight: "normal",borderColor:"grey"}} />
                                        </div>
                                    </div>
                                    <span className="form_validtion_class">{this.state.validCode}</span>
                                    <div className="singup_input" style={{textAlign:"center",padding: "30% 0 0"}}>
                                        <button onClick={this.Save_company_data_stap_4.bind(this)} type="button" className="singup_input_button  singup_input_button_stap_5  " style={{maxWidth:"100%"}}> Continue </button>
                                    </div>
                                </form> 
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
            </>
        );
    }
}
export default Singup_stap_5;