import React, { Component } from 'react';
import {Form} from 'react-bootstrap'

//Add Tabs
class Post_job_stap_3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //stap 1
            Responsibilities : this.props.responsibilities,
            valition_Responsibilities:"",
        }    
        this.Responsibilities = React.createRef(); 
    }

    handleKeyPress(e){
        if(e.target.name == "Responsibilities"){
            this.setState({
                valition_Responsibilities:"",
                Responsibilities:e.target.value
            });
            
        }
    }

    postgotobackStap(){
        this.props.postEvent("post_job_2");
    }

    save_Stap_3_data(event){
        event.preventDefault();
        let log = false;

        if(!this.Responsibilities.current.value || this.Responsibilities.current.value == ""){
            this.setState({ valition_Responsibilities: "Please enter responsibilities" });
            log = true;
        }

        if(log == true){
           
            return 
        }
        
        let about_job = this.Responsibilities.current.value;
        this.state.Responsibilities =  about_job;
        this.setState({Responsibilities:this.state.Responsibilities});
        this.props.resivedData(about_job);
        this.props.postEvent("post_job_4");
    }

    render() {
        return (
            <>
                <div className='Dashbaord Dashbaord-post_job_main'>
                    <div className='Dashbaord-post_job_inner_box'>
                        <div className='Dashbaord_header post_job_stap_3-header'>
                            <h3 className=''>Post A Job</h3>
                            <div className='progressbar'>
                                <div className='progressbar-data'></div>
                            </div>
                        </div>
                        <div className='Dashbaord_Body Dashbaord-stap-3'>
                            <div className='Dashbaord_Body_inner Dashbaord-post_job_inner  '>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className="singup_input Skills-Required">
                                            <label>Responsibilities</label>
                                            <span>What are the job responsibilities ?</span>
                                            <textarea type='text' onChange={this.handleKeyPress.bind(this)} name="Responsibilities" ref={this.Responsibilities} value={this.state.Responsibilities}></textarea>
                                            <span className='form_validtion_class'>{this.state.valition_Responsibilities}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                        </div>
                        <div className='Dashbaord_Body_stap-box'>
                            <div className='Dashbaord_Body_stap-box-button'>
                                <button type-="button" onClick={this.postgotobackStap.bind(this)} className='Dashbaord_stap-box-button-go_back'> Go Back </button>
                                <button type-="button" onClick={this.save_Stap_3_data.bind(this)} className='Dashbaord_stap-box-button-next'> Next Step </button>
                            </div>        
                        </div>    
                    </div>    
                </div>
            </>
        );
    }
}
export default Post_job_stap_3;