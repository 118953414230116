import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import Flagged_applicant from './Flagged_applicant';
import Close_Job from './Close_Job';
import Hiring_applicant from './Hiring_applicant';
import New_Hire from './New_Hire';




class Show_model extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            redirect:false
        };
    }

    gotobackStap(){
     
    }

    change_page(){
        
    }


    openModal = () => this.setState({ isOpen:true });
    closeModal = () => this.setState({ isOpen:false });

 

    render() {
        return (
            <div className="signin_signup_background">
                <button type="button" onClick={this.openModal.bind(this)}> Open model </button>
                <Flagged_applicant />
                <Close_Job />
                <Hiring_applicant />
                <New_Hire />
                
                <Modal className="new_team_member_box_main" show={this.state.isOpen} onHide={this.closeModal}>
                    <Modal.Body>
                        <h3>New Team Member</h3>
                        <div className="new_team_member_box_inner">
                            <form>
                                <div className="singup_input" style={{float:"left",width:"100%"}}>
                                    <label style={{float:"left",width:"100%",fontWeight:"500"}}>Full Name</label>
                                    <input type="text" placeholder="Full Name" />
                                </div>
                                <div className="singup_input" style={{float:"left",width:"100%"}}>
                                    <label style={{float:"left",width:"100%",fontWeight:"500"}}>Email Address</label>
                                    <input type="text" placeholder="Email Address" />
                                </div>
                                <div className="singup_input d-flex align-items-center justify-content-between singup_setp-3-button-wapper" style={{textAlign:"center",padding: "5% 0 0"}}>
                                    <div className="back_wapper">
                                        <button type="button" > Cancel </button>
                                    </div>
                                    <button type="button" className="singup_input_button singup_setp-3-button"> Add </button>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
                
            </div>
        );
    }
}
export default Show_model;
