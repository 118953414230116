import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import airbnb from '../assets/image/Ellipse_332_no@2x.png';




class Flagged_applicant extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen_1: false,
        };
    }

    gotobackStap(){
     
    }

    change_page(){
        
    }


    openModal = () => this.setState({ isOpen_1:true });
    closeModal = () => this.setState({ isOpen_1:false });

 

    render() {
        return (
            <>
                 <button type="button" onClick={this.openModal.bind(this)}> Flagged applicant </button>
                 <Modal className="new_team_member_box_main flagged-applicant" show={this.state.isOpen_1} onHide={this.closeModal}>
                    <Modal.Body >
                        <div className='users_job_box'>
                            <img src={airbnb} />
                            <h3>Noha Nega</h3>
                        </div>
                        
                        <div className="new_team_member_box_inner">
                            <form>
                                <div className="singup_input" style={{float:"left",width:"100%"}}>
                                    <label style={{float:"left",width:"100%"}}>Tell us  why you're flagging this user</label>
                                    <textarea>Inappropriate content sharing</textarea>
                                </div>
                                
                                <div className="singup_input " style={{textAlign:"center",padding: "5% 0 0"}}>
                                    <button type="button" className="singup_input_button singup_setp-3-button"> Submit and close </button>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
                
            </>
        );
    }
}
export default Flagged_applicant; 
