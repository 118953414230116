import React, { Component } from 'react';
import Rectangle from '../assets/image/Rectangle_479.png';
import Rectangle_1 from '../assets/image/Rectangle_480.png';
import Rectangle_2 from '../assets/image/Rectangle_482_o.png';
import Rectangle_3 from '../assets/image/Rectangle_485.png';
import Rectangle_4 from '../assets/image/Rectangle_486.png';
// import Rectangle_5 from '../assets/image/Rectangle_669.png';

import { Link } from 'react-router-dom';

//Add Tabs
class Show_user_job extends Component {
    constructor(props) {
        super(props);
    }

    my_list_data(data){
        this.props.applypost(data);
    }
    render() {
        return (
            <>
                <div className='show_job_users'>

                    

                    {this.props.tab_type == "Filled" ?
                        <>
                        {this.props.total_applications !== 0 ?
                            <ul className='fild_lists'>
                                {this.props.total_applications == 1 ? 
                                <>  
                                    { this.props.applications.map((title,index) =>
                                    (<li>
                                        <button type="button" onClick={this.my_list_data.bind(this,this.props.id)} style={{background:"transparent",border:"0"}}> 
                                            <img style={{width:"60px", height:"60px", borderRadius:"50%" }} src={ title.profile_image ? title.profile_image : "hello"  } /> 
                                        </button>
                                    </li>)
                                    )}
                                </> :
                                <>
                                    <li className='hired'><span>Lauren and 3 others were hired</span></li>
                                    <li><Link to={"/Applicants"} variant="outline-success"> <img style={{width:"60px"}} src={Rectangle} /></Link></li>
                                    <li><Link to={"/Applicants"} variant="outline-success" className='totla_useres'> +{ this.props.total_applications } </Link></li>
                                </>
                                }
                            </ul>
                            : "No applicants have applied yet"
                        }
                        </>
                        : ""
                    }

                    {this.props.tab_type == "Listed" || this.props.tab_type == "Closed" ?
                        <>
                        {this.props.total_applications !== 0 ?
                            <ul>
                                { this.props.applications.map((title,index) =>
                                    (<li>
                                        <button type="button" onClick={this.my_list_data.bind(this,this.props.id)} style={{background:"transparent",border:"0"}}> 
                                            <img style={{width:"60px", height:"60px", borderRadius:"50%" }} src={ title.profile_image ? title.profile_image : "hello"  } /> 
                                        </button>
                                    </li>)
                                )}
                                {/* <li><Link to={"/Applicants"} variant="outline-success"> <img style={{width:"60px"}} src={Rectangle_1} /> </Link></li>
                                <li><Link to={"/Applicants"} variant="outline-success"> <img style={{width:"60px"}} src={Rectangle_2} /> </Link></li>
                                <li><Link to={"/Applicants"} variant="outline-success"> <img style={{width:"60px"}} src={Rectangle_3} /> </Link></li>
                                <li><Link to={"/Applicants"} variant="outline-success"> <img style={{width:"60px"}} src={Rectangle_4} /> </Link></li> 
                                <li><Link to={"/Applicants"} variant="outline-success" className='totla_useres'> +20 </Link></li>*/}
                            </ul> : "No applicants have applied yet"
                        }
                        </> 
                    :""}    
                        
                        
                    
                </div>
            </>
        );
    }
}
export default Show_user_job;