import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Nav, Tab, Row } from "react-bootstrap";
import Header from "../AccountCreation/header/Header";
import LeftSideBar from "../AccountCreation/sidebar/leftside";
import Team_Members_single_post from "./Team_Members_single_post";
import Applicants_about from "./Applicants_about";

//Add Tabs
class Applicants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      List_Of_all_Post: [],
      active_id: 0,
      my_job_title: "",
      // Get Email
      Email: "",
      phone: "",
      profile_image: "",
      bg_image: "",
      job_title: "",
      name: "",
      //
      about: null,
      //
      dei_statement: null,
      demographic_info: null,
      professional_info: null,
      education: [],
      experience: [],
      professional_interests: [],
      industries: [],
      departments: [],
      jobs: [],
      resume: "",
      cover_letter: "",
    };
    this.get_all_data();
  }

  get_all_data() {
    let job_id = localStorage.getItem("Applicants");
    setTimeout(
      function () {
        const apiOption = {
          method: "GET",
          headers: {
            Authorization: "Bearer" + " " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };

        fetch(
          this.props.APIPath +
            "job_applicants?off_set=0&api_key=48e6c87a_6760_4b07_b987_7128fbea8545_1db5&job_id=" +
            job_id,
          apiOption
        )
          .then(function (res) {
            return res.json();
          })
          .then((resJson) => {
            let Manin_data_wrap = resJson.data;
            this.setState({ List_Of_all_Post: Manin_data_wrap });
            let getFilst_id = Manin_data_wrap[0].user.user_id;
            this.setState({ active_id: getFilst_id });
            this.get_all_data_by_post_id(getFilst_id);
          });
      }.bind(this),
      500
    );
  }

  get_active_data_id(data) {
    this.state.active_id = data;
    this.setState({ active_id: this.state.active_id });
    this.get_all_data_by_post_id(data);
  }

  get_all_data_by_post_id(data) {
    setTimeout(
      function () {
        const apiOption = {
          method: "POST",
          headers: {
            Authorization: "Bearer" + " " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: data,
          }),
        };

        fetch(this.props.APIPath + "getProfileById", apiOption)
          .then(function (res) {
            return res.json();
          })
          .then((resJson) => {
            let Manin_data_wrap = resJson.data;
            let my_job_title = Manin_data_wrap.job_title;
            let Email = Manin_data_wrap.email;
            this.state.Email = Email;
            this.state.my_job_title = my_job_title;
            this.setState({
              Email: this.state.Email,
              my_job_title: this.state.my_job_title,
            });

            let phone = Manin_data_wrap.phone;
            this.state.phone = phone;
            this.setState({ phone: this.state.phone });

            let profile_image = Manin_data_wrap.profile_image;
            this.state.profile_image = profile_image;
            this.setState({ profile_image: Manin_data_wrap.profile_image });

            let bg_image = Manin_data_wrap.bg_image;
            this.state.bg_image = bg_image;
            this.setState({ bg_image: this.state.bg_image });

            let job_title = Manin_data_wrap.job_title;

            this.state.job_title = job_title;
            this.setState({ job_title: this.state.job_title });

            let name = Manin_data_wrap.name;
            this.state.name = name;
            this.setState({ name: this.state.name });

            let about = Manin_data_wrap.about;
            this.state.about = about;
            this.setState({ about: this.state.about });

            let dei_statement = Manin_data_wrap.dei_statement;
            this.state.dei_statement = dei_statement;
            this.setState({ dei_statement: this.state.dei_statement });

            let demographic_info = Manin_data_wrap.demographic_info;
            this.state.demographic_info = demographic_info;
            this.setState({ demographic_info: this.state.demographic_info });

            let professional_info = Manin_data_wrap.professional_info;
            this.state.professional_info = professional_info;
            this.setState({ professional_info: this.state.professional_info });

            let education = Manin_data_wrap.education;
            this.state.education = education;
            this.setState({ education: this.state.education });

            let experience = Manin_data_wrap.experience;
            this.state.experience = experience;
            this.setState({ experience: this.state.experience });

            let professional_interests = Manin_data_wrap.professional_interests;
            this.state.professional_interests = professional_interests;
            this.setState({
              professional_interests: this.state.professional_interests,
            });

            let industries = Manin_data_wrap.industries;
            this.state.industries = industries;
            this.setState({ industries: this.state.industries });

            let departments = Manin_data_wrap.departments;
            this.state.departments = departments;
            this.setState({ departments: this.state.departments });

            let jobs = Manin_data_wrap.jobs;
            this.state.jobs = jobs;
            this.setState({ jobs: this.state.jobs });

            let user_id_by_id = Manin_data_wrap.jobs;
            this.state.jobs = jobs;
            this.setState({ jobs: this.state.jobs });

            let resume = Manin_data_wrap.professional_info.resume;
            this.state.resume = resume;
            this.setState({ resume: this.state.resume });

            let cover_letter = Manin_data_wrap.professional_info.cover_letter;
            this.state.cover_letter = cover_letter;
            this.setState({ cover_letter: this.state.cover_letter });
          });
      }.bind(this),
      500
    );
  }

  render() {
    return (
      <>
        <div className="wapper" style={{ backgroud: "orange" }}>
          <div className="container-fluid">
            <div className="row">
              <LeftSideBar />
              <div
                className="col-md-12 col-lg-12 col-xl-9 col-xxl-10"
                style={{ padding: "0 0 0 30px", height: "100%" }}
              >
                <Header apilink={this.props.APIPath} />
                <div className="all_post_main Tean_members_main Applicants_main">
                  <div className="row">
                    <div
                      className="col-sm-8 col-lg-7 col-xl-7 col-xxl-8"
                      style={{ padding: "0" }}
                    >
                      <div className="Applicants_title_main">
                        <Link to={"/ALL_Post"} variant="outline-success">
                          <div className="Applicants">
                            <svg
                              className="Icon_feather-chevron-left"
                              viewBox="13.5 9 9 18"
                            >
                              <path
                                id="Icon_feather-chevron-left"
                                d="M 22.5 27 L 13.5 18 L 22.5 9"
                              ></path>
                            </svg>
                            <h3>Applicants</h3>
                          </div>
                        </Link>
                        <button type="button" className="Save_Applicants">
                          {" "}
                          View Saved Applicants{" "}
                        </button>
                      </div>

                      <div className="Applicants_inner">
                        {this.state.List_Of_all_Post.map((title, index) => (
                          <Team_Members_single_post key={index}
                            {...title}
                            {...this.props}
                            apilink={this.props.APIPath}
                            sendtokon={localStorage.getItem("token")}
                            get_id={this.get_active_data_id.bind(this)}
                            active_id={this.state.active_id}
                          />
                        ))}
                      </div>
                    </div>
                    <div
                      className="col-sm-4 col-lg-5 col-xl-5 col-xxl-4"
                      style={{ paddingRight: "0" }}
                    >
                      {this.state.Email != "" ? (
                        <>
                          <Applicants_about
                            sendtokon={localStorage.getItem("token")}
                            active_id={this.state.active_id}
                            job_id={localStorage.getItem("Applicants")}
                            Email={this.state.Email}
                            phone={this.state.phone}
                            profile_image={this.state.profile_image}
                            job_title={this.state.job_title}
                            name={this.state.name}
                            about={this.state.about}
                            dei_statement={this.state.dei_statement}
                            demographic_info={this.state.demographic_info}
                            professional_info={this.state.professional_info}
                            education={this.state.education}
                            experience={this.state.experience}
                            professional_interests={
                              this.state.professional_interests
                            }
                            industries={this.state.industries}
                            departments={this.state.departments}
                            jobs={this.state.jobs}
                            bg_image={this.state.bg_image}
                            apilink={this.props.APIPath}
                            applicate_id={this.props.APIPath}
                            resume={this.state.resume}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Applicants;
