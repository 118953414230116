import React, { useState , useEffect } from 'react';
import { BrowserRouter, Routes, Route , useNavigate } from "react-router-dom";
import Signin from "./AccountCreation/SingIn/Signin";
import Singup from "./AccountCreation/SingUp/Singup";
import All_post from "./all_post/All_post";
import Post_job_stap_1 from "./Post_job/Post_job_stap_1";
import Team_members from "./Team_Members/Team_members";
import Messages from "./Messages/Messages";
import Profile from "./ProfileCreation/Profile/Profile";
import Applicants from "./Applicants/Applicants";
import Show_model from "./Show_models/Show_model";
import Mylist from "./Tasting_data/Mylist";
import List_of_flagged from './all_post/List_of_flagged';

import AddClientUser from './Profile_demo/AddClientUser';
import Underapproval from './AccountCreation/UnderApproval/underapproval';

function App() {
    //let APIPath = "http://zorroapp.tech/uit/api/";
    
    let APIPath = "https://uitdev.com/uitapis/api/";

    const [ latitude, setValue ] = useState(0);
    const [ longitude, setValuelongitude ] = useState(0); 
    /*
    navigator.geolocation.getCurrentPosition(function(position) {
        setValue(position.coords.latitude);
        setValuelongitude(position.coords.longitude);
    });
    
    console.log(latitude,longitude);*/
    
    return ( 
        <>      
            <BrowserRouter> 
                <Routes>
                    <Route exact={true} path="/" element={<Signin APIPath={APIPath} /> }></Route>
                    <Route exact={true} path="/Singup" element={<Singup APIPath={APIPath} latitude={latitude} longitude={longitude} /> }></Route>
                    <Route exact={true} path="/ALL_Post" element={<All_post APIPath={APIPath}  />}></Route>
                    <Route exact={true} path="/Post_Job" element={<Post_job_stap_1 APIPath={APIPath} latitude={latitude} longitude={longitude}  />}></Route>
                    <Route exact={true} path="/Team_Members" element={<Team_members APIPath={APIPath}  />}></Route>
                    <Route exact={true} path="/Messages" element={<Messages APIPath={APIPath}  />}></Route>
                    <Route exact={true} path="/Profile" element={<Profile APIPath={APIPath}  />}></Route>
                    <Route exact={true} path="/Applicants" element={<Applicants APIPath={APIPath} latitude={latitude} longitude={longitude} />}></Route>
                    <Route exact={true} path="/testing" element={<Show_model APIPath={APIPath}  />}></Route>
                    <Route exact={true} path="/testingData" element={<Mylist APIPath={APIPath}  />}></Route>
                    <Route exact={true} path="/List_Of_Flagged" element={<List_of_flagged APIPath={APIPath}  />}></Route>
                    <Route exact={true} path="/Profile_demo" element={<AddClientUser APIPath={APIPath}  />}></Route>
					<Route exact={true} path="/underapproval" element={<Underapproval APIPath={APIPath} />}></Route>
                </Routes>
            </BrowserRouter>


            {/* <Singup /> */}
            {/* <Connect_greenhouse_2 /> */}
            {/* <div className="wapper" style={{backgroud:"orange"}}>
                <div className="container-fluid">
                    <div className="row"> */}
                        {/* <LeftSideBar/>
                        <div className="col-xl-9" style={{padding:"0 0 0 30px",height:"100%"}}> */}
                            {/* <Header/> */}
                            {/* <Profile /> */}
                            {/* <All_post /> */}
                            {/* <Applicants/> */}
                            {/* <Messages /> */}
                            {/* <Team_members /> */}
                            {/* <Post_job_review /> */}
                        {/* </div> */}
                    {/* </div>
                </div>
            </div> */}
        </>
    );
}

export default App;
