import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import airbnb from '../assets/image/Rectangle_348.png';
import checkbox from '../assets/image/last-stap.png';




class New_Hire extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen_4: false,
        };
    }

    gotobackStap(){
     
    }

    change_page(){
        
    }


    openModal = () => this.setState({ isOpen_4:true });
    closeModal = () => this.setState({ isOpen_4:false });

 

    render() {
        return (
            <>
                 <button type="button" onClick={this.openModal.bind(this)}>New Hire</button>
                 <Modal className="new_team_member_box_main Hiring_applicant Hiring_applicant-last" show={this.state.isOpen_4} onHide={this.closeModal}>
                    <Modal.Body className="Hiring_applicant_body">
                        <h3>Hooray! New Hire</h3>
                        <div className='users_job_box' style={{ position: "relative"}}>
                            <img src={airbnb} />
                            <span className="icon_imgd">
                                <img src={checkbox} />
                            </span>
                            <h3>Noha Nega</h3>
                            <span>Product Developer</span>
                        </div>
                        <div className="new_team_member_box_inner">
                            <form>
                                <p>Congrats on your hire, we’ll notify the candidate shortly! 🎉</p>
                                <div className="singup_input " style={{textAlign:"center",padding: "8% 0 0"}}>
                                    <button type="button" className="singup_input_button singup_setp-3-button"> Continue </button>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
                
            </>
        );
    }
}
export default New_Hire;
