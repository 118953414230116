import React, { Component } from 'react';
import {  BrowserRouter as Router, Link , Navigate } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar'
import PropTypes from 'prop-types';
import {DropdownButton,Dropdown,} from 'react-bootstrap'
import Search from '../../assets/image/Search.png';
/*-Imges Icon-*/
import notification from '../../assets/image/notification.png';
import notify_user_imaged from '../../assets/image/Rectangle-486.png';
import airbnb from '../../assets/image/airbnb.png';
import toggleButton from '../../assets/image/Screenshot_3.png';
import last_stap from '../../assets/image/last-stap.png';
import Alert from 'react-bootstrap/Alert';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            search_value:'',
            selectTab:this.props.selectTab,
            notification:[],
			account_status: 2,

        }

        let token = localStorage.getItem('token');
		
		this.state.account_status = localStorage.getItem('account_status');
        this.setState({account_status:this.state.account_status});

        const apiOption = {
            method: "GET",
            headers:{
                'Authorization': 'Bearer '+token,  
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }

        fetch(this.props.apilink+"notifications",apiOption)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            
            let notification_data = resJson.data;
            this.state.notification = notification_data;
            this.setState({notification:notification_data});
            //this.showNotifiction();
        });
    }

    componentWillReceiveProps(props) {
        // console.log('props.search_value');
        // console.log(props);
        // this.setState({search_value : props.search_value});
        // return the new state as object, do not call .setState()
        /*return { 
            search_value: props.search_value
        };*/
    }


    // showNotifiction(){
    //     let Data_list_notifiction = this.state.notification;
    //     Data_list_notifiction.map(function(elelent){
    //         if(elelent.is_seen == false){
    //             return(
    //                 <>
    //                     <Dropdown.Item >
    //                         <div className="notification_box show">
    //                             <div className="notification_box_users">
    //                                 <spna>
    //                                     <img src={notify_user_imaged} />
    //                                 </spna>
    //                                 <div className="notification_box_users-text">
    //                                     <h3> {elelent.from_user.name} wants to join  </h3>
    //                                     <h3> your team </h3>
    //                                 </div>
    //                             </div>
    //                             <span className='timetonot'>
    //                                 20 mins ago
    //                             </span>
    //                         </div>
    //                     </Dropdown.Item>
    //                 </>
    //             )
    //         }
    //     })
    // }

    toggleButtondeta(){
        if(this.props.toggleStatus == "drop"){ this.props.toggleButton("up"); }
        if(this.props.toggleStatus == "up"){ this.props.toggleButton("drop"); }
    } 

    send_new_post(){
        if(localStorage.getItem('account_status') == 4){
            this.setState({ showerror: true ,showerrortype : 2 ,showerrormessage: "Your account in under approval" });
            this.error_handaling();
        }else{
            if(localStorage.getItem('edit_job_id') !== null){
                localStorage.removeItem("edit_job_id")
            }
            this.setState({redirect: true})
        }
    }

    error_handaling(){
        if(this.state.showerror == true){
            setTimeout(function() {
               this.setState({showerror: false});
            }.bind(this),3000); 

            if(this.state.showerrortype == 1){
                setTimeout(function() {
                    this.setState({redirect: true});
                }.bind(this),3100);     
            }

            return(
                <>
                    <Alert style={{position:"absolute",top:"0",left:"0",right:"0", width:"100%", float:"left", textAlign:"center"}} variant={this.state.showerrortype == 1 ? "success" : "danger"}>
                        {this.state.showerrormessage}
                    </Alert>
                </>
            )
        }
    }

    searchHandler (event) {
        let search_data = event.target.value;
        this.setState({search_value:event.target.value});
        this.props.search_data(search_data);
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to="/Post_Job" />
        }
		
		if (this.state.account_status != 1) {
            return <Navigate to="/underapproval" />
        }

        let $imagePreview = null;
        if (localStorage.getItem('profile_img') != null) {
          $imagePreview = (<img  src={ localStorage.getItem('profile_img') } />);
        } else {
          $imagePreview = (<img  src={airbnb} />);
        }

        return (
            <>
                {this.error_handaling()}
                <header className='header'>
                    <div className='header-search' style={{backgroundImage:"../../assets/image/Search.png",}}>
                        <input type="text" onChange={this.searchHandler.bind(this)} value={localStorage.getItem('serach_key')} placeholder="Search Jobs" />
                    </div>
                    <div className='header-left'>
                        
                        <button type='button' onClick={this.send_new_post.bind(this)} className="post_jobs_btn" variant="outline-success">Post Job</button>

                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <img src={notification} />
                            </Dropdown.Toggle> 
                            <Dropdown.Menu>
                                
                                <div className="inner_waaper_dropdown">
                                    <h3>Notifictions</h3>
                                    {this.state.notification.map(( elelent , index) => 
                                        <>
                                        {index < 4 ? <> 
                                            {elelent.is_seen == false ? <>
                                                {elelent.notification_type == 1 ?     
                                                    <Dropdown.Item  key={elelent.id}>
                                                        <div className="notification_box show">
                                                            <div className="notification_box_users">
                                                                <spna>
                                                                    <img src={notify_user_imaged} />
                                                                </spna>
                                                                <div className="notification_box_users-text">
                                                                    
                                                                    <h3> {elelent.from_user.name}  add new message   </h3>
                                                                </div>
                                                            </div>
                                                            <span className='timetonot'>
                                                                20 mins ago
                                                            </span>
                                                        </div>
                                                    </Dropdown.Item> 
                                                :""}
                                                {elelent.notification_type == 2 ?     
                                                    <Dropdown.Item  key={elelent.id}>
                                                        <div className="notification_box show">
                                                            <div className="notification_box_users">
                                                                <spna>
                                                                    <img src={notify_user_imaged} />
                                                                </spna>
                                                                <div className="notification_box_users-text">
                                                                
                                                                    <h3> {elelent.from_user.name} add new applicant  </h3>
                                                                    
                                                                </div>
                                                            </div>
                                                            <span className='timetonot'>
                                                                20 mins ago
                                                            </span>
                                                        </div>
                                                    </Dropdown.Item> 
                                                :""}
                                                {elelent.notification_type == 3 ?     
                                                    <Dropdown.Item  key={elelent.id}>
                                                        <div className="notification_box show">
                                                            <div className="notification_box_users">
                                                                <spna>
                                                                    <img src={notify_user_imaged} />
                                                                </spna>
                                                                <div className="notification_box_users-text">
                                                                
                                                                    <h3> {elelent.from_user.name} pending for approval   </h3>
                                                                    
                                                                </div>
                                                            </div>
                                                            <span className='timetonot'>
                                                                20 mins ago
                                                            </span>
                                                        </div>
                                                    </Dropdown.Item> 
                                                :""}
                                                {elelent.notification_type == 4 ?     
                                                    <Dropdown.Item  key={elelent.id} >
                                                        <div className="notification_box show">
                                                            <div className="notification_box_users">
                                                                <spna>
                                                                    <img src={notify_user_imaged} />
                                                                </spna>
                                                                <div className="notification_box_users-text">
                                                                    
                                                                    <h3> {elelent.from_user.name} has new comment   </h3>
                                                                    
                                                                </div>
                                                            </div>
                                                            <span className='timetonot'>
                                                                20 mins ago
                                                            </span>
                                                        </div>
                                                    </Dropdown.Item> 
                                                :""}
                                                {elelent.notification_type == 5 ?     
                                                    <Dropdown.Item  key={elelent.id} >
                                                        <div className="notification_box show">
                                                            <div className="notification_box_users">
                                                                <spna>
                                                                    <img src={notify_user_imaged} />
                                                                </spna>
                                                                <div className="notification_box_users-text">
                                                                
                                                                    <h3> {elelent.from_user.name} add flagged user  </h3>
                                                                    
                                                                </div>
                                                            </div>
                                                            <span className='timetonot'>
                                                                20 mins ago
                                                            </span>
                                                        </div>
                                                    </Dropdown.Item> 
                                                :""}
                                                {elelent.notification_type == 6 ?     
                                                    <Dropdown.Item   key={elelent.id}>
                                                        <div className="notification_box show">
                                                            <div className="notification_box_users">
                                                                <spna>
                                                                    <img src={notify_user_imaged} />
                                                                </spna>
                                                                <div className="notification_box_users-text">
                                                                    
                                                                    <h3> {elelent.from_user.name} add flagged job   </h3>
                                                                    
                                                                </div>
                                                            </div>
                                                            <span className='timetonot'>
                                                                20 mins ago
                                                            </span>
                                                        </div>
                                                    </Dropdown.Item> 
                                                :""}
                                                {elelent.notification_type == 7 ?     
                                                    <Dropdown.Item >
                                                        <div className="notification_box show">
                                                            <div className="notification_box_users">
                                                                <spna>
                                                                    <img src={notify_user_imaged} />
                                                                </spna>
                                                                <div className="notification_box_users-text">
                                                                
                                                                    <h3> {elelent.from_user.name} is  new hire  </h3>
                                                                    
                                                                </div>
                                                            </div>
                                                            <span className='timetonot'>
                                                                20 mins ago
                                                            </span>
                                                        </div>
                                                    </Dropdown.Item> 
                                                :""}
                                                {elelent.notification_type == 8 ?     
                                                    <Dropdown.Item   key={elelent.id}>
                                                        <div className="notification_box show">
                                                            <div className="notification_box_users">
                                                                <spna>
                                                                    <img src={notify_user_imaged} />
                                                                </spna>
                                                                <div className="notification_box_users-text">
                                                                
                                                                    <h3> {elelent.from_user.name} Add new job </h3>
                                                                </div>
                                                            </div>
                                                            <span className='timetonot'>
                                                                20 mins ago
                                                            </span>
                                                        </div>
                                                    </Dropdown.Item> 
                                                :""}
                                                {elelent.notification_type == 9 ?     
                                                    <Dropdown.Item  key={elelent.id} >
                                                        <div className="notification_box show">
                                                            <div className="notification_box_users">
                                                                <spna>
                                                                    <img src={notify_user_imaged} />
                                                                </spna>
                                                                <div className="notification_box_users-text">
                                                                    
                                                                    <h3> {elelent.from_user.name} create new company   </h3>
                                                                </div>
                                                            </div>
                                                            <span className='timetonot'>
                                                                20 mins ago
                                                            </span>
                                                        </div>
                                                    </Dropdown.Item> 
                                                :""}
                                                {elelent.notification_type == 10 ?     
                                                    <Dropdown.Item  key={elelent.id} >
                                                        <div className="notification_box show">
                                                            <div className="notification_box_users">
                                                                <spna>
                                                                    <img src={notify_user_imaged} />
                                                                </spna>
                                                                <div className="notification_box_users-text">
                                                                    <h3> {elelent.from_user.name} add  new recruiter   </h3>
                                                                </div>
                                                            </div>
                                                            <span className='timetonot'>
                                                                20 mins ago
                                                            </span>
                                                        </div>
                                                    </Dropdown.Item> 
                                                :""}
                                                {elelent.notification_type == 11 ?     
                                                    <Dropdown.Item   key={elelent.id}>
                                                        <div className="notification_box show">
                                                            <div className="notification_box_users">
                                                                <spna>
                                                                    <img src={notify_user_imaged} />
                                                                </spna>
                                                                <div className="notification_box_users-text">
                                                                
                                                                    <h3> {elelent.from_user.name} add new uit member   </h3>
                                                                    
                                                                </div>
                                                            </div>
                                                            <span className='timetonot'>
                                                                20 mins ago
                                                            </span>
                                                        </div>
                                                    </Dropdown.Item> 
                                                :""}
                                                {elelent.notification_type == 12 ?     
                                                    <Dropdown.Item  key={elelent.id} >
                                                        <div className="notification_box show">
                                                            <div className="notification_box_users">
                                                                <spna>
                                                                    <img src={notify_user_imaged} />
                                                                </spna>
                                                                <div className="notification_box_users-text">
                                                                    <h3> {elelent.from_user.name} add new job approved   </h3>
                                                                </div>
                                                            </div>
                                                            <span className='timetonot'>
                                                                20 mins ago
                                                            </span>
                                                        </div>
                                                    </Dropdown.Item> 
                                                :""}
                                                {elelent.notification_type == 13 ?     
                                                    <Dropdown.Item  key={elelent.id}>
                                                        <div className="notification_box show">
                                                            <div className="notification_box_users">
                                                                <spna>
                                                                    <img src={notify_user_imaged} />
                                                                </spna>
                                                                <div className="notification_box_users-text">
                                                                
                                                                    <h3> {elelent.from_user.name} wants to job application rejected   </h3>
                                                                    
                                                                </div>
                                                            </div>
                                                            <span className='timetonot'>
                                                                20 mins ago
                                                            </span>
                                                        </div>
                                                    </Dropdown.Item> 
                                                :""}
                                                {elelent.notification_type == 14 ?     
                                                    <Dropdown.Item  key={elelent.id}>
                                                        <div className="notification_box show">
                                                            <div className="notification_box_users">
                                                                <spna>
                                                                    <img src={notify_user_imaged} />
                                                                </spna>
                                                                <div className="notification_box_users-text">
                                                                    <h3> {elelent.from_user.name} is like post  </h3>
                                                                </div>
                                                            </div>
                                                            <span className='timetonot'>
                                                                20 mins ago
                                                            </span>
                                                        </div>
                                                    </Dropdown.Item> 
                                                :""}
                                                {elelent.notification_type == 15 ?     
                                                    <Dropdown.Item  key={elelent.id}>
                                                        <div className="notification_box show">
                                                            <div className="notification_box_users">
                                                                <spna>
                                                                    <img src={notify_user_imaged} />
                                                                </spna>
                                                                <div className="notification_box_users-text">
                                                                    
                                                                    <h3> {elelent.from_user.name} wants to unlike your post   </h3>
                                                                </div>
                                                            </div>
                                                            <span className='timetonot'>
                                                                20 mins ago
                                                            </span>
                                                        </div>
                                                    </Dropdown.Item> 
                                                :""}
                                                </>
                                            : ""}
                                            </> :""}
                                        </>
                                    )}

                                    {/* // <Dropdown.Item >
                                        <div className="notification_box show">
                                            <div className="notification_box_users">
                                                <spna>
                                                    <img src={notify_user_imaged} />
                                                    <img src={last_stap} className="hover_note_img" />
                                                </spna>
                                                <div className="notification_box_users-text">
                                                    <h3>New messages from rata</h3>
                                                    <span>Connor</span>
                                                </div>
                                            </div>
                                            <span className='timetonot'>
                                                20 mins ago
                                            </span>
                                        </div>
                                    </Dropdown.Item> */}
                                
                                    
                                    
                                    {/* <Dropdown.Item >
                                        <div className="notification_box show">
                                            <div className="notification_box_users">
                                                <spna>
                                                    <img src={notify_user_imaged} />
                                                </spna>
                                                <div className="notification_box_users-text">
                                                    <h3> Andres Anthonio wants to join  </h3>
                                                    <h3> your team </h3>
                                                </div>
                                            </div>
                                            <span className='timetonot'>
                                                20 mins ago
                                            </span>
                                        </div>
                                    </Dropdown.Item> */}

                                    
                                    <Dropdown.Item className='load_more_note-main' >
                                        Load More
                                    </Dropdown.Item>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Link to={"/Profile"} variant="outline-success">
                            <div className='userProfile'>
                                <span className='user_image'>
                                    {$imagePreview}
                                </span>
                                <span className='user_text'>
                                    {localStorage.getItem('user_name')}
                                </span>
                            </div>        
                        </Link>
                        <button  onClick={this.toggleButtondeta.bind(this)} className='toggole_button' type="button"> <img src={toggleButton} /> </button>
                    </div>
                </header>
            </>
        );
    }
}

export default Header;