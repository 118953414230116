import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch , Link, Navigate } from "react-router-dom";
import {Form} from 'react-bootstrap';
import TagsInput from './TagsInput';
//header saidbar
import Header from "../AccountCreation/header/Header";
import LeftSideBar from "../AccountCreation/sidebar/leftside";

import Post_job_stap_2 from "./Post_job_stap_2";
import Post_job_stap_3 from "./Post_job_stap_3";
import Post_job_stap_4 from "./Post_job_stap_4";
import Post_job_stap_5 from "./Post_job_stap_5";
import Post_job_review from "./Post_job_review";

import Slider from '@mui/material/Slider';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';

//Add Tabs
class Post_job_stap_1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            temp_login:true,
            //city_list
            city_state_list:[],
            //stap 1
            Job_Title : "",
            valition_Job_Title : "",
            //
            list_industries:[],
            Industry: "",
            valition_Industry: "",
            //
            list_depatment:[],
            Depatment:"",
            valition_Depatment:"",
            city:"",
            State:"",
            Location_Staus : 1,
            Employment_Staus: 1,
            
            min_salary:50000,
            max_salary:60000,
            slider_min_salary:50,
            slider_max_salary:400,
            Degree_Id: "",
            degree_required: false,
            Language_id:"",
            Language_Requirement : false,
            vaccination_status : false,
            degrees:[],
            language:[],
            skills:[],
            selected_skills:[],
            //stap 2
            roles: "",
            //stap 3
            responsibilities : "",
            //Stap 4
            ApplicationDeadine: "",
            //valition 
            valition_city:"",
            valition_Location_Staus : "",
            valition_Employment_Staus: "",
            valition_min_salary:"",
            valition_max_salary:"",
            valition_degree_required: "",
            valition_Language_Requirement:"",
            vaccination_status: true,
            post_job_active:"post_job_1",
            //stap 4
            experience_needed : [],
            data:"Post_job",
            redirect: false,
            Salary : [50, 140],
            job_title_list : [],
            //
            edit_job_expariens : 0,
        };

        this.Job_Title        = React.createRef(); 
        this.Industry         = React.createRef(); 
        this.Depatment        = React.createRef();
        // this.city             = React.createRef(); 
        this.Location_Staus   = React.createRef(); 
        this.Employment_Staus = React.createRef();
        this.min_salary       = React.createRef();  
        this.max_salary       = React.createRef(); 
        this.degree_id        = React.createRef();
        this.language_id      = React.createRef();

        // get list of industries
        const apiOption_industries = {
            method: "GET",
            // headers:{
            //     'Accept': 'application/json',
            //     'Content-Type': 'application/json',
            // },
        }
        fetch(this.props.APIPath+"industries?api_key=48e6c87a_6760_4b07_b987_7128fbea8545_1db5",apiOption_industries)
        .then(function(res) {
            return res.json();
        }).then(resJson => { 
            let list_industries = resJson.data;
            this.state.list_industries = list_industries;
            this.setState({list_industries:list_industries});
        });

        // get list of departments
        const apiOption_departments = {
            method: "GET",
            // headers:{
            //     'Accept': 'application/json',
            //     'Content-Type': 'application/json',
            // },
        }
        fetch(this.props.APIPath+"departments?api_key=48e6c87a_6760_4b07_b987_7128fbea8545_1db5",apiOption_departments)
        .then(function(res) {
            return res.json();
        }).then(resJson => { 
            let list_depatment = resJson.data;
            this.state.list_depatment = list_depatment;
            this.setState({list_depatment:list_depatment});
        });

        // get list of all degrees
        const apiOption = {
            method: "GET",
            headers:{
                'Authorization': 'Bearer'+' '+localStorage.getItem('token'),  
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
        fetch(this.props.APIPath+"degrees?api_key=48e6c87a_6760_4b07_b987_7128fbea8545_1db5",apiOption)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            let Manin_data_wrap = resJson.data;
            this.state.degrees = Manin_data_wrap;
            this.setState({degrees : this.state.degrees });
        });

        // get list of all Language 
        const apiOption1 = {
            method: "GET",
            headers:{
                'Authorization': 'Bearer'+' '+localStorage.getItem('token'),  
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
        fetch(this.props.APIPath+"languages?api_key=48e6c87a_6760_4b07_b987_7128fbea8545_1db5",apiOption1).then(function(res) {
            return res.json();
        }).then(resJson => {
            let Manin_data_wrap = resJson.data;
            this.state.language = Manin_data_wrap;
            this.setState({language : this.state.language});
        });

        //skills
        const apiOption2 = {
            method: "GET",
            headers:{
                'Authorization': 'Bearer'+' '+localStorage.getItem('token'),  
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
        fetch(this.props.APIPath+"skills?api_key=48e6c87a_6760_4b07_b987_7128fbea8545_1db5",apiOption2)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            let Manin_data_wrap = resJson.data;
            this.state.skills = Manin_data_wrap;
            this.setState({skills : this.state.skills});
        });
      
        var token = localStorage.getItem('token');
        if(token == null){
            this.state.temp_login = false;
        }

        // Edit job data API Conaction and set
        if(localStorage.getItem('edit_job_id') !== null){
            const apiOption_job_detail = {
                method: "GET",
                headers:{
                    'Authorization': 'Bearer'+' '+localStorage.getItem('token'),  
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            }
            fetch(this.props.APIPath+"get_job_detail?job_id="+localStorage.getItem('edit_job_id'),apiOption_job_detail)
            .then(function(res) {
                return res.json();
            }).then(resJson => {
                let Manin_data_wrap = resJson.data;
                console.log(Manin_data_wrap);
                let job_title = Manin_data_wrap.job_title;
                let city = Manin_data_wrap.city+","+Manin_data_wrap.state;
                let state = Manin_data_wrap.city+","+Manin_data_wrap.state;

                let industry = Manin_data_wrap.industry.name;
               
                let department = Manin_data_wrap.department.name; 

                let location_status = Manin_data_wrap.location_status;
                let employment_type = Manin_data_wrap.employment_type;

                let degree = Manin_data_wrap.degree.id;
                let language = Manin_data_wrap.language.id;

                let roles =  Manin_data_wrap.roles
                let responsibilities =  Manin_data_wrap.responsibilities

                let min_salary =  Manin_data_wrap.min_salary / 1000;
                let max_salary =  Manin_data_wrap.max_salary / 1000;

                let experience = Manin_data_wrap.experience

                let deadline = Manin_data_wrap.deadline == null ? "" : Manin_data_wrap.deadline;
                let deadline_data = deadline.split("/");
                let deadline_end = deadline_data[0]+"-"+deadline_data[1]+"-"+deadline_data[2];
                let vaccination_required =  Manin_data_wrap.vaccination_required == 1 ? true : false;

                const options = [];
                experience.map(function(element){
                    options.push({
                        "years": element.requirement_status, 
                        "requirement_status": element.requirement_status, 
                        "industry_id": element.industry.id,
                        "job_title":element.industry.name
                    })
                });

                let skills = Manin_data_wrap.skills;
                
                const skills_options = [];
                skills.map(function(element){
                    skills_options.push(
                        element.name, 
                    )
                });
                
                this.setState({
                    //stap 1
                    Job_Title : job_title,
                    Industry: industry,
                    Depatment:department,
                    city: city,
                    State: state,

                    Location_Staus : location_status,
                    Employment_Staus: employment_type,
                    
                    min_salary:min_salary,
                    max_salary:max_salary,
                    Degree_Id: degree,
                    degree_required: false,
                    Language_id: language,
                    Language_Requirement : false,
                    //stap 2
                    roles: roles,
                    //stap 3
                    responsibilities : responsibilities,
                    //Stap 4
                    ApplicationDeadine: deadline_end,
                    vaccination_status: vaccination_required,
                    //stap 4
                    experience_needed : options,
                    selected_skills:skills_options,
                    Salary : [min_salary, max_salary],
                })
            });
        }else{
            this.setState({
                //stap 1
                Job_Title : "",
                Industry: "",
                Depatment:"",
                city:"",
                State:"",
                Location_Staus :1,
                Employment_Staus:1,
                min_salary:1000000,
                max_salary:5000000,

                Degree_Id: "",
                degree_required: false,
                Language_id:"",
                Language_Requirement : false,
                //stap 2
                roles: "",
                //stap 3
                responsibilities : "",
                //Stap 4
                ApplicationDeadine: "",
                vaccination_status : false,
                //stap 4
                experience_needed : [],
                selected_skills:[],
                Salary : [10000, 100000],
            })
        }    
    }

    edit_job_expariens(value){
        console.log(value);
        this.setState({edit_job_expariens : value })
    }


    //list of city and starte
    city_state_list(e){
        this.setState({State : e.target.value,valition_city:""});

        var city_state_list_val = [];
        const temp = {
            method: "GET", 
        }
        fetch('https://nominatim.openstreetmap.org/search?q='+e.target.value+'&limit=100&format=json&addressdetails=1',temp)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            resJson.map(function(es, i){  
                if(
                    ( es.address.hasOwnProperty('city') || es.address.hasOwnProperty('county') )
                    &&
                    ( es.address.hasOwnProperty('state') || es.address.hasOwnProperty('province') )
                ){
                    var city_temp = '';
                    var state_temp = '';

                    if(es.address.hasOwnProperty('county')){ city_temp = es.address.county; }
                    if(es.address.hasOwnProperty('city')){ city_temp = es.address.city; }

                    if(es.address.hasOwnProperty('province')){ state_temp = es.address.province; }
                    if(es.address.hasOwnProperty('state')){ state_temp = es.address.state; }

                    city_state_list_val.push({
                        value : city_temp+','+state_temp,
                        name : es.display_name
                    })
                }
            })
            this.setState({city_state_list : city_state_list_val});
        });
    }

    createItem(job_title,YearsData,Required,job_title_id){
        this.state.experience_needed.unshift({
            "years": YearsData, 
            "industry_id": job_title_id, 
            "requirement_status": Required,
            "job_title":job_title
        });
        this.setState({
            experience_needed: this.state.experience_needed
        });
    }    

    onDelete(item){
        let index = this.state.experience_needed.map(es => es.job_title).indexOf(item);
        this.state.experience_needed.splice(index, 1);
        this.setState({ experience_needed: this.state.experience_needed });
    }

    edijobExparines(oldjobname,oldyear,oldrequirement_status,old_industry_id,newjob,newyear,newrequirement_statu,new_industry_id){
       
        let select_year = this.state.experience_needed.filter((elemnter) => elemnter.years === oldyear)[0];
        let select_jobname = this.state.experience_needed.filter((elemnter) => elemnter.job_title === oldjobname)[0];
        let select_requirement = this.state.experience_needed.filter((elemnter) => elemnter.requirement_status === oldrequirement_status)[0];
        let select_industry_id = this.state.experience_needed.filter((elemnter) => elemnter.industry_id === old_industry_id)[0];
        select_year.years = newyear;
        select_jobname.job_title = newjob;
        select_requirement.requirement_status = newrequirement_statu;
        select_industry_id.industry_id = select_industry_id;
        this.setState({experience_needed:this.state.experience_needed});
        console.log(this.state.experience_needed);
    }

    upDateState(edset){
        this.setState({post_job_active:edset});
    }

    handleChange(event) {
        this.setState({valition_Location_Staus:""});
        const { name, checked } = event.target;
        // this.setState((prevState) => {
		// 	const colors = prevState.Location_Staus;
        //     colors[name] = checked;
		// 	return colors;
        // });
        this.setState({Location_Staus : event.target.value});
    }

    handleClick(event) {
        this.setState({valition_Employment_Staus:""}); 
        let { name, checked } = event.target;
        this.setState({Employment_Staus : event.target.value});
    }

    // handleKeyPress
    handleKeyPress(e){
        if(e.target.name == "City"){
            this.setState({ 
                valition_city: "",
                city:e.target.value,
                State:e.target.value, 
            });

        }

        if(e.target.name == "Job_Title"){
            this.setState({ 
                valition_Job_Title:"",
                Job_Title:e.target.value
            });

        }

        if(e.target.name == "Industry_name"){
            this.setState({ 
                valition_Industry: ""
            });

            // if(e.target.value !== ""){
                let value = e.target.value;
                this.setState({ Industry:value });
            // }
            
        }

        if(e.target.name == "Department"){
            this.setState({ valition_Depatment:"" });
            // if(e.target.value !== ""){
                let value = e.target.value;
                this.setState({ Depatment:value });
            // }
        }
    }

    getEducation(e){
        if(e.target.name == "Education_select"){
            this.setState({valition_degree_required: ""});
            if(e.target.value !== ""){
                let value = e.target.value;
                this.setState({Degree_Id : value});
            }
        }
        if(e.target.name == "Language_select"){
            this.setState({valition_Language_Requirement: ""});
            if(e.target.value !== ""){
                let value = e.target.value;
                this.setState({Language_id : value});
            }
        }
    }
    
    getEducationRequired(e){
        const { name, checked } = e.target;
        this.setState({valition_degree_required: ""});
        this.setState({degree_required : checked});
    }

    changeState(e){
        this.setState({State : e})
        this.setState({city : e})
    }

    save_Post_data(event){
        event.preventDefault(); 
        
        let log = false;

        if(!this.Job_Title.current.value){
            this.setState({ valition_Job_Title: "Please enter a job title" });
            log = true;
        }
        if(!this.Industry.current.value) {
            this.setState({ valition_Industry : "Please enter industry" });
            log = true;
        }
        if(!this.state.city){
            this.setState({ valition_city: "Please enter City, State" });
            log = true;
        }
        if(!this.Depatment.current.value){
            this.setState({ valition_Depatment: "Please enter department"});
            log = true;
        }
        
        if(this.state.Location_Staus == 0){
            this.setState({ valition_Location_Staus: "Please enter a location staus"});
            log = true;
        }

        if(this.state.Employment_Staus == 0){
            this.setState({ valition_Employment_Staus: "Please select a employment staus"});
            log = true;
        }
            
        if(!this.degree_id.current.value  && this.state.degree_required == false ){
            this.setState({ valition_degree_required: "Please enter a education"});
            log = true;        
        }

        if(!this.language_id.current.value && this.state.Language_Requirement == false){
            this.setState({ valition_Language_Requirement: "Please enter a language "});
            log = true;
        }

        if(log == true){
           return 
        }

        this.state.Job_Title = this.Job_Title.current.value;
        this.setState({Job_Title : this.state.Job_Title})

        this.state.Industry = this.Industry.current.value;
        this.setState({Industry : this.state.Industry})

        this.state.Depatment = this.Depatment.current.value;
        this.setState({Depatment :this.state.Depatment})

        // this.state.State = this.city.current.value 
        // this.setState({State : this.state.State})

        // this.state.city = this.city.current.value 
        // this.setState({city : this.state.State})

        this.state.Degree_Id = this.degree_id.current.value 
        this.setState({Degree_Id : this.state.Degree_Id})

        this.state.Language_id = this.language_id.current.value
        this.setState({Language_id : this.state.Language_id})

        this.setState({post_job_active:"post_job_2"})
    }

    nextStapPostjob(stap){ this.setState({post_job_active:stap});  }

    // Get Create Company Wizard data Start
    get_post_data_2(data){
        let about_job = data
        this.state.roles = about_job;
        this.setState({roles: this.state.roles });
    }

    get_post_data_3(data){
        let Responsibilities = data;
        this.state.responsibilities = Responsibilities;
        this.setState({responsibilities: this.state.responsibilities });
       
    }

    get_post_data_4(skill,Application_Deadine){
        let Skill = skill;
        let application_deadine = Application_Deadine;

        this.state.selected_skills = Skill;
        this.setState({selected_skills: this.state.selected_skills });

        this.state.ApplicationDeadine = application_deadine;
        this.setState({ApplicationDeadine: this.state.ApplicationDeadine });
    }

    handleClickSlider(event){
       this.setState({Salary : event.target.value});
    }

    numFormatter(num) {
        return num + "K";
    }

    componentDidMount(){
        console.log('rinku is here')
        const apiOption = {
            method: "GET",
            headers:{
                'Authorization': 'Bearer'+' '+localStorage.getItem('token'),  
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }

        fetch(this.props.APIPath+"jobs?api_key=48e6c87a_6760_4b07_b987_7128fbea8545_1db5",apiOption)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            this.state.job_title_list = resJson.data;
            this.setState({job_title_list:this.state.job_title_list});
        });
    }

    // Get Create Company Wizard data End
    showPost_job(){
        if(this.state.post_job_active == "post_job_1"){
            return (
                <> 
                    <div className='Dashbaord Dashbaord-post_job_main '>
                        <div className='Dashbaord-post_job_inner_box'>
                            <div className='Dashbaord_header post_job_stap_1-header'>
                                <h3 className=''>Post A Job</h3>
                                <div className='progressbar'>
                                    <div className='progressbar-data'></div>
                                </div>
                            </div>
                            {localStorage.getItem('account_status') != 4?
                                <>
                                    <div className='Dashbaord_Body Post_job_stap_1'>
                                        <div className='Dashbaord_Body_inner Dashbaord-post_job_inner'>
                                            <div className='row'>
                                                <div className='col-sm-6'>
                                                    <div className="singup_input"> 
                                                        <label>Job Title</label>
                                                        <input  onChange={this.handleKeyPress.bind(this)} value={this.state.Job_Title} ref={this.Job_Title}  name="Job_Title" type="text" placeholder='Job title' list="job_title_list" />
                                                        <datalist id="job_title_list">
                                                            {this.state.job_title_list.map((item) =>
                                                                <option key={item} value={item.value} >{item.name}</option>
                                                            )}
                                                        </datalist>
                                                        <span className="form_validtion_class">{this.state.valition_Job_Title}</span>
                                                    </div>
                                                    <div className="singup_input">
                                                        <label>Industry</label>
                                                        <div className="input_select float-left w-100" style={{position: "relative"}}>
                                                            <input  onChange={this.handleKeyPress.bind(this)} value={this.state.Industry} ref={this.Industry}  name="Industry_name" type="text" placeholder='Industry' list="job_industry_list" />
                                                            <datalist id="job_industry_list">
                                                                {this.state.list_industries.map((item) =>
                                                                    <option key={item} value={item.name} >{item.name}</option>
                                                                )}
                                                            </datalist>
                                                            {/* <svg style={{ width: "25px",height: "25px",transform: "rotate(-92deg)"}} class="Icon_feather-chevron-left" viewBox="13.5 9 9 18"><path id="Icon_feather-chevron-left" d="M 22.5 27 L 13.5 18 L 22.5 9"></path></svg>
                                                            
                                                            <select value={this.state.Industry} ref={this.Industry} name="Industry_name" onChange={this.handleKeyPress.bind(this)} style={{border:"1px solid #6D6D6D", background:"transparent", height: "70px", borderRadius: "6px"}} class="select_job_meta" className=" float-left w-100" >
                                                                <option value="">Select Industry</option>
                                                                {this.state.list_industries.map(({ id, name }) => 
                                                                    <option key={id} value={name} title={name}>{name} </option>
                                                                )}
                                                            </select> */}
                                                        </div>
                                                        <span className="form_validtion_class">{this.state.valition_Industry}</span>
                                                    </div>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <div className='experience-box-main'>
                                                        <div className="singup_input">
                                                            <label>Headquarters </label>
                                                            <div className="input_select float-left w-100" style={{position: "relative"}}>
                                                                <svg style={{ width: "25px",height: "25px",transform: "rotate(-92deg)"}} class="Icon_feather-chevron-left" viewBox="13.5 9 9 18"><path id="Icon_feather-chevron-left" d="M 22.5 27 L 13.5 18 L 22.5 9"></path></svg>
                                                                {/* <input onChange={this.city_state_list.bind(this)} name="City" ref={this.city} type="text" placeholder='City, State' value={this.state.State} list="city_state_list" />
                                                                <datalist id="city_state_list">
                                                                    {this.state.city_state_list.map(item =>
                                                                        <option key={item.value} value={item.value} >{item.name}</option>
                                                                    )}
                                                                </datalist> */}

                                                                {/* <Autocomplete
                                                                    id="free-solo-demo"
                                                                    options={this.state.city_state_list.map((option) => option.value)}
                                                                    renderInput={(params) => <TextField placeholder='City, State' {...params} onChange={this.city_state_list.bind(this)}/>}
                                                                /> */}
                                                                
                                                                <Autocomplete
                                                                    id="country-select-demo"
                                                                    options={this.state.city_state_list}
                                                                    getOptionLabel={(option) => option.value}
                                                                    onChange={(event, value) => {this.changeState(value.value)}}
                                                                    value={{ value : this.state.State }}
                                                                    renderOption={(props, option) => (
                                                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                            {option.name}
                                                                        </Box> 
                                                                    )}
                                                                    renderInput={(params) => (
                                                                        <TextField name="City" onChange={this.city_state_list.bind(this)} placeholder='City, State'
                                                                            {...params}
                                                                            inputProps={{
                                                                                ...params.inputProps,
                                                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                                </div>
                                                            <span  className="form_validtion_class">{this.state.valition_city}</span>
                                                        </div>
                                                        <div className="singup_input">
                                                            <label>Department </label>
                                                            <div className="input_select float-left w-100" style={{position: "relative"}}>
                                                                <input  onChange={this.handleKeyPress.bind(this)} value={this.state.Depatment} ref={this.Depatment}  name="Department" type="text" placeholder='Depatment' list="job_depatment_list" />
                                                                <datalist id="job_depatment_list">
                                                                    {this.state.list_depatment.map((item) =>
                                                                        <option key={item} value={item.name} >{item.name}</option>
                                                                    )}
                                                                </datalist>
                                                                {/* <svg style={{ width: "25px",height: "25px",transform: "rotate(-92deg)"}} class="Icon_feather-chevron-left" viewBox="13.5 9 9 18"><path id="Icon_feather-chevron-left" d="M 22.5 27 L 13.5 18 L 22.5 9"></path></svg>
                                                                <select onChange={this.handleKeyPress.bind(this)} value={this.state.Depatment} name='Depatment' ref={this.Depatment}  style={{border:"1px solid #6D6D6D", background:"transparent", height: "70px", borderRadius: "6px"}} class="select_job_meta" className=" float-left w-100" >
                                                                    <option value="">Select Department</option>
                                                                    {this.state.list_depatment.map(({ id, name }) => 
                                                                        <option key={id} value={name} title={name}>{name} </option>
                                                                    )}
                                                                </select> */}
                                                            </div>
                                                            <span className="form_validtion_class">{this.state.valition_Depatment}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-12'>
                                                    <div className='readioaruye Location-Staus'>
                                                        <label>Location Status</label>
                                                        <Form>
                                                            <div key={`inline-1`} style={{marginLeft:'20px'}}>
                                                                <Form.Check checked={this.state.Location_Staus == 1} value="1" onChange={this.handleChange.bind(this)} inline label="Hybrid" name="Hybrid" type="checkbox" id={`inline-11`} />
                                                                <Form.Check checked={this.state.Location_Staus == 2} value="2" onChange={this.handleChange.bind(this)} inline label="Remote" name="Preferred" type="checkbox" id={`inline-12`} />
                                                                <Form.Check checked={this.state.Location_Staus == 3} value="3" onChange={this.handleChange.bind(this)} inline label="In-Person" name="In_Person" type="checkbox" id={`inline-13`} />
                                                            </div>
                                                        </Form>
                                                        <span className="form_validtion_class" style={{float:"left",width:"100%"}}>{this.state.valition_Location_Staus}</span>
                                                    </div>
                                                    <div className='readioaruye Location-Staus'>
                                                        <label>Employment Status</label>
                                                        <Form>
                                                            {['checkbox'].map((type) => (
                                                                <div key={`inline-1`} style={{marginLeft:'20px'}}>
                                                                    <Form.Check  checked={this.state.Employment_Staus == 1} value="1" onChange={this.handleClick.bind(this)} inline label="Full Time" name="Full_Time" type="checkbox"  id={`inline-14`} />
                                                                    <Form.Check  checked={this.state.Employment_Staus == 2} value="2" onChange={this.handleClick.bind(this)} inline label="Part Time" name="Part_Time" type="checkbox" id={`inline-15`} />
                                                                    <Form.Check  checked={this.state.Employment_Staus == 3} value="3" onChange={this.handleClick.bind(this)} inline label="Contract" name="Contract" type="checkbox" id={`inline-16`} />
                                                                    <Form.Check  checked={this.state.Employment_Staus == 4} value="4" onChange={this.handleClick.bind(this)} inline label="Freelance" name="Freelance" type="checkbox" id={`inline-17`} />
                                                                    <Form.Check  checked={this.state.Employment_Staus == 5} value="5" onChange={this.handleClick.bind(this)} inline label="Internship" name="Internship" type="checkbox" id={`inline-19`} />
                                                                </div>                                                                          
                                                            ))} 
                                                        </Form>
                                                        <span className="form_validtion_class" style={{float:"left",width:"100%"}}>{this.state.valition_Employment_Staus}</span>
                                                    </div>       
                                                    <div className='slider-bars post_slider_data'>
                                                        <label>Salary</label>
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <Slider getAriaLabel={() => 'Minimum distance shift'} min={this.state.slider_min_salary} step={5} max={this.state.slider_max_salary} value={this.state.Salary} valueLabelFormat={this.numFormatter} onChange={this.handleClickSlider.bind(this)}  valueLabelDisplay="on"  />                                 
                                                        {/* <spna className="slider-textdata min-slider-price">{this.state.slider_min_salary}</spna>
                                                        <spna className="slider-textdata max-slider-price">{this.state.slider_max_salary}</spna> */}

                                                        {/* <Slider getAriaLabel={() => 'Minimum distance shift'} min={50} step={5} max={400} value={this.state.Salary2} valueLabelFormat={this.numFormatter} onChange={this.handleClickSlider2.bind(this)} valueLabelDisplay="on"  />    */}
                                                        
                                                    </div>                
                                                </div>
                                                <div className='col-sm-6'>
                                                    <div className="singup_input" style={{marginBottom:"5%"}}>
                                                        <label> Minimum Education Requirement </label>
                                                        <div className="input_select float-left w-100" style={{position: "relative"}}>
                                                            <svg style={{ width: "25px",height: "25px",transform: "rotate(-92deg)"}} class="Icon_feather-chevron-left" viewBox="13.5 9 9 18"><path id="Icon_feather-chevron-left" d="M 22.5 27 L 13.5 18 L 22.5 9"></path></svg>
                                                            <select ref={this.degree_id} onChange={this.getEducation.bind(this)} name="Education_select" className=" float-left w-100" value={this.state.Degree_Id}>
                                                                <option value=""> Select Education </option>
                                                                {this.state.degrees.map(({ id, name }) => 
                                                                    <option key={id} value={id}>{name} </option>
                                                                )}
                                                            </select>
                                                            <span className="form_validtion_class">{this.state.valition_degree_required}</span>
                                                        </div>
                                                        <div className='readioaruye readioaruye_indid'>
                                                            <Form>
                                                                {['checkbox'].map((type) => (
                                                                    <div key={`inline-${type}`} style={{marginLeft:'20px'}}>
                                                                        <Form.Check onChange={this.getEducationRequired.bind(this)} inline name="group1" type={type} label="Not Required" id={`inline-${type}-11`} defaultChecked={this.state.degree_required == true ? "checked" : ""} />
                                                                        <Form.Check inline label="Vaccination Requirement" name="Remote" type={type} id={`inline-${type}-12`} defaultChecked={this.state.vaccination_status == true ? "checked" : ""} />
                                                                    </div>
                                                                ))}
                                                            </Form>
                                                            <span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <div className='experience-box-main'>
                                                        <div className="singup_input" style={{marginBottom:"5%"}}>
                                                            <label>Language Requirement</label>
                                                            <div className="input_select float-left w-100" style={{position: "relative"}}>
                                                                <svg style={{ width: "25px",height: "25px",transform: "rotate(-92deg)"}} class="Icon_feather-chevron-left" viewBox="13.5 9 9 18"><path id="Icon_feather-chevron-left" d="M 22.5 27 L 13.5 18 L 22.5 9"></path></svg>
                                                                <select ref={this.language_id} onChange={this.getEducation.bind(this)} name="Language_select" className="float-left w-100" value={this.state.Language_id}>
                                                                    <option value="">Select Language</option>
                                                                    {this.state.language.map(({ id, name }) => 
                                                                        <option key={id} value={id}>{name} </option>
                                                                    )}
                                                                </select>
                                                                <span className="form_validtion_class">{this.state.valition_Language_Requirement}</span>
                                                            </div>  
                                                            <div className='readioaruye readioaruye_indid'>
                                                                <Form>
                                                                    {['checkbox'].map((type) => (
                                                                        <div key={`inline-${type}`}>
                                                                            <Form.Check inline name="group1" type={type} label="Not Required" id={`inline-${type}-13`} defaultChecked={this.state.Language_Requirement == true ? "checked" : ""} />
                                                                        </div>
                                                                    ))}
                                                                </Form>
                                                                <span></span>
                                                            </div>      
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>    
                                    </div>
                                    <div className='Dashbaord_Body_stap-box'>
                                        <div className='Dashbaord_Body_stap-box-button'>
                                            <button type="button" onClick={this.save_Post_data.bind(this)} className='Dashbaord_stap-box-button-next'> Next Step </button>
                                        </div>        
                                    </div>    
                                </>
                            : <> <h1 style={{paddingTop:'20%',fontSize:'30px',color:'red',textAlign:'center'}}>Your account in under approval</h1> </>
                            }
                        </div>    
                    </div>
                </>
            )
        }
        if(this.state.post_job_active === "post_job_2"){
            return (<> <Post_job_stap_2 roles={this.state.roles} resivedData = {this.get_post_data_2.bind(this)} postEvent={this.nextStapPostjob.bind(this)}  /> </>); 
        }
        if(this.state.post_job_active == "post_job_3"){
            return ( <Post_job_stap_3 responsibilities={this.state.responsibilities} resivedData = {this.get_post_data_3.bind(this)} postEvent={this.nextStapPostjob.bind(this)}  /> ); 
        }
        if(this.state.post_job_active == "post_job_4"){
            return ( 
            <Post_job_stap_4 
                sendtokon={localStorage.getItem("token")} 
                comp_url = {localStorage.getItem("profile_img")}
                apilink={this.props.APIPath} 
                resivedData = {this.get_post_data_4.bind(this)} 
                postEvent={this.nextStapPostjob.bind(this)} 
                createjob = {this.createItem.bind(this)} 
                deleteItem={this.onDelete.bind(this)} 
                edijobExparines = {this.edijobExparines.bind(this)} 
                list_experience={this.state.experience_needed} 
                //props
                skills={this.state.skills}
                selected_skills = {this.state.selected_skills}
                //stap 1
                Job_Title={this.state.Job_Title} 
                Industry = {this.state.Industry}
                Depatment= {this.state.Depatment}
                city = {this.state.city}
                State = {this.state.State}
                Location_Staus = {this.state.Location_Staus}
                Employment_Staus = {this.state.Employment_Staus}
                min_salary = {this.state.min_salary}
                max_salary = {this.state.max_salary}
                degree_id =  {this.state.Degree_Id}
                degree_required = {this.state.degree_required}
                Language_id = {this.state.Language_id}
                Language_Requirement = {this.state.Language_Requirement}
                vaccination_status= {this.state.vaccination_status}
                //stap 2
                roles = {this.state.roles}
                //stap 3
                responsibilities = {this.state.responsibilities} 
                //Stap 4
                ApplicationDeadine = {this.state.ApplicationDeadine} 
                SKills = {this.state.selected_skills}
                edit_job_expariens_mode = {this.state.edit_job_expariens}
                edit_job_expariens_mode_edit = {this.edit_job_expariens.bind(this)}
             /> );
        }
        if(this.state.post_job_active == "post_job_5"){
            return ( 
                <Post_job_stap_5 postEvent={this.nextStapPostjob.bind(this)}   /> 
            );
        }
        if(this.state.post_job_active == "post_job_review"){
            return ( 
            <Post_job_review postEvent={this.nextStapPostjob.bind(this)}
                sendtokon={localStorage.getItem("token")} 
                apilink={this.props.APIPath}
                list_experience={this.state.experience_needed}
                //stap 1
                Job_Title={this.state.Job_Title} 
                
                list_industries = {this.state.list_industries}
                Industry = {this.state.Industry}

                list_depatment = {this.state.list_depatment}
                Depatment= {this.state.Depatment}
                
                city = {this.state.city}
                State = {this.state.State}
                Location_Staus = {this.state.Location_Staus}
                Employment_Staus = {this.state.Employment_Staus}
                min_salary = {this.state.min_salary}
                max_salary = {this.state.max_salary}
                degree_id =  {this.state.Degree_Id}
                degree_required = {this.state.degree_required}
                Language_id = {this.state.Language_id}
                Language_Requirement = {this.state.Language_Requirement}
                vaccination_status= {this.state.vaccination_status}
                Salary = {this.state.Salary}
                //stap 2
                roles = {this.state.roles}
                //stap 3
                responsibilities = {this.state.responsibilities} 
                //Stap 4
                ApplicationDeadine = {this.state.ApplicationDeadine} 
                SKills = {this.state.selected_skills}
                //list of degrre
                degrees = {this.state.degrees}
                //list of language
                language={this.state.language}
                
                deleteItem={this.onDelete.bind(this)}
                skills={this.state.skills}
                edit_job_expariens_mode = {this.state.edit_job_expariens}
                edit_job_expariens_mode_edit = {this.edit_job_expariens.bind(this)}
                let={this.props.latitude}
                lang={this.props.longitude}
            /> );
        }
    }
    
    render() {
        if (this.state.temp_login == false) {
            return <Navigate to="/" />
        }
        return (
            <>
                <div className="wapper" style={{backgroud:"orange"}}>
                    <div className="container-fluid">
                        <div className="row">
                            <LeftSideBar meta={this.state.data} />
                            <div className="col-md-12 col-lg-9 col-xl-9 col-xxl-10" style={{padding:"0 0 0 30px",height:"100%"}}>
                                <Header apilink={this.props.APIPath}  />
                                {this.showPost_job()}                
                            </div>    
                        </div>    
                    </div> 
                </div>                
            </>
        );
    }
}
export default Post_job_stap_1;