import React, { Component } from 'react';
import airbnb from '../assets/image/airbnb.png';
import Rectangle_1047 from  "../assets/image/Rectangle_1047_ni.png"
import Ellipse_332_no from  "../assets/image/Ellipse_332_no@2x.png"
import Show_user_job from './Show_user_job';
import { BrowserRouter as Router, Route, Switch , Link, Navigate } from "react-router-dom"
// import moment from 'moment';
import moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
//Add Tabs
class Single_post extends Component {
    
    constructor(props) {
        super(props);
        console.log(this.props);
        this.props = props;
        this.state = {
            applypost:false
        }
    }
         
    get_active_data (data){
       this.props.get_id(data);
    }

    nydsafd(data){
        let company_post_id = this.props.company_post_id;
        localStorage.setItem('Applicants', JSON.stringify(data));
        localStorage.setItem('company_post_id', JSON.stringify(company_post_id));
        this.setState({applypost:true})
    }

    render() {
        let $company_profile = null;
        if(this.props.company != undefined){
            if(this.props.company.profile_image){
                $company_profile = (<img style={{width:"72px",height:"72px",borderRadius:"60%"}} src={this.props.company.profile_image} className="user_icon" />); 
            }else{
                $company_profile = (<img style={{width:"72px",height:"72px",borderRadius:"60%"}} src={airbnb} className="user_icon" />);  
            }
        }
        let $userProfile = null;
        if(this.props.company != undefined){
            if(this.props.company.name){
                $userProfile = ( <img style={{position: "absolute",top: "34px",right: "44px",width:"36px",height:"36px",borderRadius:"60%"}} src={this.props.user.profile_image} />);
            }else{
                $userProfile = ( <img style={{position: "absolute",top: "34px",right: "44px",width:"36px",height:"36px",borderRadius:"60%"}} src={Rectangle_1047} />);
            }
        }
        let $bigUserProfile = null;
        if(this.props.company != undefined){
            if(this.props.company.name){
                $bigUserProfile = (<img style={{width:"72px",height:"72px",borderRadius:"60%"}} src={this.props.user.profile_image} className="user_hover_icon" />);
            }else{
                $bigUserProfile = (<img style={{width:"72px",height:"72px",borderRadius:"60%"}} src={Ellipse_332_no} className="user_hover_icon" />);    
            }
        }

        if (this.state.applypost == true) {
            return <Navigate to="/Applicants" />
        }
        return (
            <> 
                <button  onClick={this.get_active_data.bind(this,this.props.id)} type="button" style={{border:"none", background:"transparent", textAlign:"left", float:"left", width:"100%"}}>
                    <div className='show_post_list'>
                        <div className={`post_box-user ${this.props.id == this.props.active_id ? "active" : ""} `}>
                            <span className='post_user_icon'>
                                {/* <img style={{width:"72px"}} src={airbnb} className="user_icon" />  */}
                                {$company_profile}
                                {/* <img style={{width:"72px",height:"72px",borderRadius:"60%"}} src={Ellipse_332_no} className="user_hover_icon" />  */}
                                {$bigUserProfile}
                                {/* <img style={{position: "absolute",top: "28px",right: "28px"}} src={Rectangle_1047} />  */}
                                {$userProfile}
                            </span>
                            
                            <div className='post_user_text'>
                                <div className='post_user_text_inner'>
                                    <h3> {this.props.company != undefined ? this.props.company.name : "" } <span className='post_time'> {moment(this.props.created_at, "YYYYMMDD, h:mm:ss a").fromNow()} </span></h3>
                                    {/* <span>{this.props.job_title}</span> */}
                                </div>        
                                <span className='post_name'>{this.props.job_title}</span>
                                <span className='AirBnB'>{this.props.company.name} </span>
                                <span className='address'>{this.props.city}, {this.props.state}</span>
                                <div className="tag_data">
                                    <span className="tag_data_inner"> {(this.props.min_salary/1000)+'K'}-{(this.props.max_salary/1000)+'K'}</span>
                                    <span className="tag_data_inner">{typeof this.props.industry !== 'undefined'? this.props.industry.name : ""}</span>
                                    <span className="tag_data_inner">
                                        {this.props.employment_type == 1?  "Full Time": ""}
                                        {this.props.employment_type == 2?  "Part Time": ""}
                                        {this.props.employment_type == 3?  "Contract": ""}
                                        {this.props.employment_type == 4?  "Freelance": ""}
                                        {this.props.employment_type == 5?  "Internship": ""}
                                    </span>
                                </div>
                                
                                    {this.props.tab_type == "Filled" ? 
                                    <>
                                        <Show_user_job tab_type={this.props.tab_type} id={this.props.id} applypost={this.nydsafd.bind(this)} applications ={this.props.applications}  total_applications = {this.props.total_applications} />
                                    </> : "" }

                                    {this.props.tab_type == "Listed" || this.props.tab_type == "Closed" ? <>
                                        <Show_user_job tab_type={this.props.tab_type} id={this.props.id} applypost={this.nydsafd.bind(this)} applications ={this.props.applications} total_applications = {this.props.total_applications} /> 
                                        </>
                                    : "" }    
                            </div>
                            <span className='type_of_post Filld'>Filled</span>
                            <span className='type_of_post Closed'>Closed</span>
                        </div>        
                    </div>
                </button>
            </>
        );
    }
}
export default Single_post;