import React, { Component } from 'react';
import airbnb from '../assets/image/Ellipse_332.png';
import Delete from '../assets/image/Delete.png';
import { Modal, Button } from "react-bootstrap";
import { BrowserRouter as Router, Route, Switch , Link, Navigate } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
//Add Tabs
class Team_Members_single_post extends Component {
    constructor(props) {
        super(props);
        
         this.state = {
            isOpen: false,
            isOpen_1: false,
            profile:"",
            name:"",
            title:"",
            crrent_id:0,

            profile_1:"",
            name_1:"",
            title_1:"",
            crrent_id_1:0,

            chat_redirect:false,
            showerror:false,
            showerrortype : 0,
            showerrormessage : "",


        };
    }

    openModal = () => this.setState({ isOpen:true,profile:this.props.profile_image,name:this.props.name,title:this.props.job_title,crrent_id:this.props.user_id});
    closeModal = () => this.setState({ isOpen:false });

    openModal_1 = () => this.setState({ 
        isOpen_1:true,
        name_1:this.props.name,
        title_1:this.props.email,
        crrent_id_1:this.props.id
    });
    closeModal_1 = () => this.setState({ isOpen_1:false });


    getUserdata(data){
        this.props.get_user_id(data);
    }

    accept_users(data){
        const apiOption = {
            method: "Post",
            headers:{
                'Authorization': 'Bearer'+' '+localStorage.getItem('token'),  
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({ "team_id" : data }), 
        }
        fetch(this.props.APIPath+"approve_company_member",apiOption)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            if(resJson.status == true){
                let Manin_data_wrap = resJson.data;
                this.props.get_reload();
                this.setState({showerror:true , showerrortype : 1 , showerrormessage: "Team member is approve" });
                this.error_handaling();
            }else{
                this.setState({showerror: true ,showerrortype : 2 ,showerrormessage: resJson.message });
                this.error_handaling();
            }    
        })
        .catch(error => {
            this.setState({ showerror: true ,showerrortype : 2 ,showerrormessage: "Invalid Response" });
            this.error_handaling();
        });
    }

    Decline_post(data){
        const apiOption1 = {
            method: "Post",
            headers:{
                'Authorization': 'Bearer'+' '+localStorage.getItem('token'),  
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({ "team_id" : data }), 
        }
        fetch(this.props.APIPath+"reject_company_member",apiOption1)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            if(resJson.status == true){
                let Manin_data_wrap = resJson.data;
                this.props.get_reload();
                this.setState({showerror:true , showerrortype : 1 , showerrormessage: "Team member is reject" });
                this.error_handaling();
                this.closeModal();
            }else{
                this.setState({showerror: true ,showerrortype : 2 ,showerrormessage: resJson.message });
                this.error_handaling();
            }    
        })
        .catch(error => {
            this.setState({ showerror: true ,showerrortype : 2 ,showerrormessage: "Invalid Response" });
            this.error_handaling();
        });
    }

    Delete_post(){
        const apiOption1 = {
            method: "Post",
            headers:{
                'Authorization': 'Bearer'+' '+localStorage.getItem('token'),  
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({ "user_id" : this.state.crrent_id }), 
        } 
        fetch(this.props.APIPath+"delete_user",apiOption1)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            if(resJson.status == true){
                let Manin_data_wrap = resJson.data;
                this.props.get_reload();
                this.setState({showerror:true , showerrortype : 1 , showerrormessage: "Team member is delete message send successfully " });
                this.error_handaling();
                this.closeModal();
            }else{
                this.setState({showerror: true ,showerrortype : 2 ,showerrormessage: resJson.message });
                this.error_handaling();
                this.closeModal();
            }    
        })
        .catch(error => {
            this.setState({ showerror: true ,showerrortype : 2 ,showerrormessage: "Invalid Response" });
            this.error_handaling();
        });
    }

    Delete_post_invait(){
        const apiOption1 = {
            method: "Post",
            headers:{
                'Authorization': 'Bearer'+' '+localStorage.getItem('token'),  
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({ "invitation_id" : this.state.crrent_id_1 }), 
        } 
        fetch(this.props.APIPath+"delete_invitation",apiOption1)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            if(resJson.status == true){
                let Manin_data_wrap = resJson.data;
                this.props.get_reload();
                this.setState({showerror:true , showerrortype : 1 , showerrormessage: " Invited team member is delete successfully " });
                this.error_handaling();
                this.closeModal_1();
            }else{
                this.setState({showerror: true ,showerrortype : 2 ,showerrormessage: resJson.message });
                this.error_handaling();
                this.closeModal_1();
            }    
        })
        .catch(error => {
            this.setState({ showerror: true ,showerrortype : 2 ,showerrormessage: "Invalid Response" });
            this.error_handaling();
        });
    }

    error_handaling(){
        if(this.state.showerror == true){
            setTimeout(function() {
               this.setState({showerror: false});
            }.bind(this),3000); 

            if(this.state.showerrortype == 1){
                setTimeout(function() {
                    this.setState({redirect: true});
                }.bind(this),3100);     
            }

            return(
                <>
                    <Alert style={{position:"absolute",top:"0",left:"0",right:"0", width:"100%", float:"left", textAlign:"center"}} variant={this.state.showerrortype == 1 ? "success" : "danger"}>
                        {this.state.showerrormessage}
                    </Alert>
                </>
            )
        }
    }

    get_meta_data(data){
        this.setState({message_id:data})
        const apiOption = {
            method: "post",
            headers:{
                'Authorization': 'Bearer '+localStorage.getItem('token'),  
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({
                "user" : this.props.user_id,
            }), 
        }

        fetch(this.props.APIPath+"create_chat",apiOption)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            if(resJson.status == true){
                this.setState({  showerror: true ,showerrortype : 2 ,showerrormessage: "Job is add",temp_login:false,chat_redirect:true});
                // this.error_handaling();
            }else{
                let data = resJson.message;
                this.setState({  showerror: true ,showerrortype : 2 ,showerrormessage: data,chat_redirect:true });
                // this.error_handaling();
            }
        })
        .catch(error => {
            this.setState({ showerror: true ,showerrortype : 2 ,showerrormessage: "Invalid Response" });
            // this.error_handaling();
        });
    }

    get_active_data (data){
       this.props.get_id(data);
    }

    render() {
        if (this.state.chat_redirect == true) {
            return <Navigate to={`/Messages/?${localStorage.getItem('User_id')}-${this.props.user_id}`} />
        }
        return (
            <>
                {this.error_handaling()}
                <div className='show_post_list'>
                   
                    <button type='button' onClick={this.getUserdata.bind(this,this.props.user_id)} style={{background:"transparent",border:"0",float:"left", width:"100%", zIndex:"999999999"}} >
                        <div className={`post_box-user ${this.props.user_id == this.props.active_id ? "active" : ""} `}>
                            <span className='post_user_icon'><img alt="UIT" style={{width:"91px",height:"91px", borderRadius:"50%"}} src={ this.props.profile_image !="" ? this.props.profile_image : airbnb} /></span>
                            <div className='post_user_text'>
                                <div className='post_user_text_inner'>
                                    <h3>{this.props.name}</h3>
                                    <span>{this.props.job_title}</span>
                                    <span className='user_email'>{this.props.email}</span>
                                </div>    
                                <div className='action_team_members'>
                                    <div className='team_members_Decline'>
                                        <a href="javascript:void(0);" onClick={this.Decline_post.bind(this,this.props.user_id)}> Decline </a>
                                    </div>
                                    <div className='team_members_Delete'>
                                        <a href="#" onClick={this.openModal.bind(this)}><img src={Delete} /></a>
                                    </div>

                                    <div className='team_members_Delete delete-invait' style={{display:"none"}}>
                                        <a href="#" onClick={this.openModal_1.bind(this)}><img src={Delete} /></a>
                                    </div>
                                    
                                    <div className='team_members_accept'>
                                        <a href="#" onClick={this.accept_users.bind(this,this.props.user_id)}> Accept </a>
                                    </div>
                                    <div className='team_members_accept-Message'>
                                        <button style={{color: "rgba(25,153,154,1)", border:"none",background:"transparent", padding: 0}} onClick={this.get_meta_data.bind(this,this.props.user_id)} type='button'>Message</button>
                                        {/* <Link to={"/Messages"} onClick={this.get_meta_data.bind(this,this.props.user.user_id)} variant="outline-success">Message</Link> */}
                                    </div>
                                </div>    
                            </div>
                        </div>
                    </button>        
                </div>
                
                {/* opne modle */}
                <Modal className="Delete_model_main" show={this.state.isOpen} onHide={this.closeModal} aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body>
                        <div className='Delete_model_body'>
                            <div className="Delete_model_user">
                                <span className='Delete_model_user_img'>
                                    <img style={{width:"129px"}} src={ this.state.profile !="" ? this.state.profile : airbnb} />
                                </span>
                                <div className='Delete_model_user_text'>
                                    <h3>{this.state.name}</h3>
                                    <span>{this.state.title}</span>
                                </div>
                                <p>Confirm you want to delete this team member</p>
                            </div>
                            <div className='delete_mobel_buttons'>
                                <div className='delete_mobel_buttons_cancel'>
                                    <button type="button" onClick={this.closeModal.bind(this)}> Cancel</button>
                                </div>
                                <div className='delete_mobel_buttons_delete'>
                                    <button type="button" onClick={this.Delete_post.bind(this)}>Yes! Delete</button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* opne model */}

                 {/* opne modle */}
                 <Modal className="Delete_model_main invaite_delete_model" show={this.state.isOpen_1} onHide={this.closeModal_1} aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body>
                        <div className='Delete_model_body'>
                            <div className="Delete_model_user">
                                {/* <span className='Delete_model_user_img'>
                                    <img style={{width:"129px"}} src={ this.state.profile !="" ? this.state.profile : airbnb} />
                                </span> */}
                                <div className='Delete_model_user_text'>
                                    <h3>{this.state.name_1}</h3>
                                    <span>{this.state.title_1}</span>
                                </div>
                                <p>Confirm you want to delete this team member</p>
                            </div>
                            <div className='delete_mobel_buttons'>
                                <div className='delete_mobel_buttons_cancel'>
                                    <button type="button" onClick={this.closeModal_1.bind(this)}> Cancel</button>
                                </div>
                                <div className='delete_mobel_buttons_delete'>
                                    <button type="button" onClick={this.Delete_post_invait.bind(this)}>Yes! Delete</button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* opne model */}
            </>
        );
    }
}
export default Team_Members_single_post;