
import React, { Component } from 'react';
import airbnb from '../../assets/image/airbnb.png';
import Greenhouse from '../../assets/image/Ellipse 354 grey.png';
import { Modal, Button } from "react-bootstrap";
import { Navigate } from "react-router-dom"
import Alert from 'react-bootstrap/Alert';
//CSS

//Images
import upload_image from '../../assets/image/upload_image.png';

const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];
class Connect_greenhouse extends Component {
    constructor(props) {
        super(props);
	    this.state = {
            account_name : "",
            account_token:"",
            valition_account_name:"",
            valition_account_token:"",
            redirect: false,
            list_industries:[],

            showerror:false,
            showerrortype : 0,
            showerrormessage : "",
            greenhouse_show : 0,
        }

        this.account_name = React.createRef();
        this.account_token = React.createRef();

        // get list of company size
        const apiOption1 = {
            method: "GET",
            // headers:{
            //     'Accept': 'application/json',
            //     'Content-Type': 'application/json',
            // },
        }
        fetch(this.props.apiPath+"industries?api_key=48e6c87a_6760_4b07_b987_7128fbea8545_1db5",apiOption1)
        .then(function(res) {
            return res.json();
        }).then(resJson => { 
            let list_industries = resJson.data;
            this.state.list_industries = list_industries;
            this.setState({list_industries:list_industries});
        });
    }

    SkipStap(){
        //API Call
        const text = this.props.Headquater
        const myArray = text.split(",");
        const city = myArray[0];
        const state = myArray[1];

        var list_industries2 =  this.state.list_industries;
        var temp_array = [];
        this.props.industries.map(function(element1,i){ 
            var temp_name = element1;
            list_industries2.map(function(element2,j){ 
                if(element2.name == element1){
                    temp_name = element2.id;
                }
            })
            temp_array.push(temp_name)
        })
       
        var formdata = new FormData();
        formdata.append("name", this.props.Name);
        formdata.append("email", this.props.email);
        formdata.append("password", this.props.password);
        formdata.append("stage", this.props.stage);
        formdata.append("size", this.props.size);
        formdata.append("bio", this.props.bio);
        // formdata.append("industries", temp_array);
        temp_array.map(function(element1,i){ 
            formdata.append("industries["+i+"]", element1);
        })
        formdata.append("lat", this.props.let);
        formdata.append("lang", this.props.lang);
        formdata.append("city", city);
        formdata.append("state", state);
        //formdata.append("greenhouse_user_id", "");
        //formdata.append("greenhousetoken", "");
        formdata.append("profile_image", this.props.logofile);

        const apiOption = {
            method: "post",
            body: formdata,
            redirect: 'follow'
        }
   
        fetch(this.props.apiPath+"register-company",apiOption)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            if(resJson.status == true){

                let Manin_data_wrap = resJson.data;
                let Profle = Manin_data_wrap.profile; 
                let profile_img = Profle.profile_image;
                localStorage.setItem('profile_img', profile_img);

                let id = Profle.user_id;
                localStorage.setItem('User_id', id);
                localStorage.setItem('Account_Email', Profle.email);

                let access_token =  Manin_data_wrap.access_token;
                let token = access_token.token;
                localStorage.setItem('token',token);

                let account_status = Profle.account_status;
                localStorage.setItem('account_status',account_status);

                let user_name = Profle.name;
                localStorage.setItem('user_name',user_name);

                let green_house_token = Profle.greenhouse_access_token;
                localStorage.setItem('greenhouse_access_token',green_house_token);

                let user_Role = Profle.role;
                localStorage.setItem('user_Role',user_Role); 

                // let user_sizes = Profle.user_sizes;
                // let user_sizes_merd = '';
                // if(user_sizes.length > 0){
                //     user_sizes_merd = user_sizes[0].name;
                // }
                // localStorage.setItem('user_sizes',user_sizes_merd);
                
                this.setState({showerror:true , showerrortype : 1 , showerrormessage: "Your company is registered" });
                this.error_handaling();
            }else{
                this.setState({ valid_email_address: "Email address is already registered" });
                this.setState({showerror:true , showerrortype : 2 , showerrormessage: "Something wrong with api fields" });
                this.error_handaling();
            }
        })
        .catch(error => {
            this.setState({ showerror: true ,showerrortype : 2 ,showerrormessage: "Invalid Response" });
            this.error_handaling();
        });
    }
    
    openModal = () => this.setState({ isOpen:true });
    closeModal = () => this.setState({ isOpen:false });

    handleKeyPress(e){
        if(e.target.name == "account_token"){
            this.setState({
                account_token:e.target.value,
                valition_account_token:"",
            });
        }

        if(e.target.name =="account_name"){
            this.setState({
                valition_account_name:"",
                account_name : e.target.value
            });
        }
    }

    addNewFild(event){
        event.preventDefault();
        let log = false;
        
        if(!this.account_token.current.value){
            this.setState({ valition_account_token: "Please enter a account token" });
            log = true;
        }

        if(log == true){ return }

        let account_token =  this.account_token.current.value

        var formdata = new FormData();
        formdata.append("token", account_token);
        const apiOption2 = { method: "post", body: formdata, redirect: 'follow' }
   
        fetch(this.props.apiPath+"greenhouse_tokenVerify",apiOption2)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            if(resJson.status == true){
                this.props.resive_data(account_token);
                this.setState({showerror:true ,greenhouse_show:1, showerrortype : 1 , showerrormessage: "Your account is connet with greenhouse" });
                this.error_handaling();
            }else{
               
                this.setState({showerror:true , showerrortype : 2 , showerrormessage: "Please enter a valid greenhouse token" });
                this.error_handaling();
            }
        })
        .catch(error => {
            this.setState({ showerror: true ,showerrortype : 2 ,showerrormessage: "Invalid Response" });
            this.error_handaling();
        });
    }

    error_handaling(){
        if(this.state.showerror == true){
            setTimeout(function() {
               this.setState({showerror: false});
            }.bind(this),3000); 

            if(this.state.showerrortype == 1){
                setTimeout(function() {
                    if(this.state.greenhouse_show == 1){
                        this.props.clickEvent("Connect_greenhouse_2");
                    }else{
                        this.props.clickEvent("stap6");
                    }
                }.bind(this),3100);     
            }

            return(
                <>
                    <Alert style={{position:"fixed",top:"0",left:"0",right:"0", width:"100%", float:"left", textAlign:"center" ,zIndex:"9999"}} variant={this.state.showerrortype == 1 ? "success" : "danger"}>
                        {this.state.showerrormessage}
                    </Alert>
                </>
            )
        }
    }

    render() {
        let $imagePreview = null;
        if (this.props.logoUrl != "") {
            //   $imagePreview = (<img style={{width:"100%"}} src={this.props.logoUrl} />);
            $imagePreview = this.props.logoUrl;
        } else {
            //   $imagePreview = (<img style={{width:"100%"}} src={upload_image} />);
            $imagePreview = upload_image;
        }
        return (
            <>
            {this.error_handaling()}
            <div className="row signin_signup_background connect_greenhouse_stap_1">
                <div className="col-lg-6"></div> 
                <div className="col-lg-6" style={{background:'#f9f9f9',padding:"1% 5% 5% 5%",textAlign:"left"}}>
                    <div className="singup_box connect_greenhouse-main-top" style={{marginTop:"5%", background:'overlay',background: "#FFF",float: "left", width: "100%", height: "auto",borderRadius: "6px"}}>
                        <div className="Singup_stap_3_progress" style={{width:"100%"}}></div>
                        <div className="singup_box_inner">
                            <div className="singup_box_title singup_box_title_logo d-flex justify-content-start f-left w-100 align-items-center" style={{padding:"5% 10% 8%"}}>
                                <div className="singup_box_title_logo_image" style={{marginRight:'12px',borderRadius:'100%',width:'82px',height:'80px',backgroundSize:'cover',backgroundImage: `url(${$imagePreview})`}}>
                                    {/* {$imagePreview} */}
                                </div>
                                <div className="singup_box_title_logo_text" style={{width:"85%"}}>
                                    <h3 style={{color:'teal',fontFamily: 'Poppins',fontWeight:"600", fontSize:"32px"}}>Company Information</h3>
                                    <p style={{color:'grey',fontFamily: 'Poppins',fontWeight:"500", fontSize:"24px",marginBottom:"0"}}>Primary Information</p>
                                </div>    
                            </div>
                            <div className="connect_greenhouse-main">
                                <div className="connect_greenhouse-main-text">
                                    <h3>Connect Greenhouse Account</h3>
                                    <span>Connect Your Greenhouse account to UIT</span>
                                </div>
                                <div className="connect_greenhouse-main-logo">
                                    <span className="connect_greenhouse-main-logo-ma">
                                        <img src={Greenhouse} style={{width:"154px"}} />
                                    </span>
                                    <span>Awaiting Connection...</span>
                                </div>
                            </div>
                            <div className="singup_box-singup-stap-2" style={{padding:"0% 10% 6%",float:"left",width:"100%"}}>
                                <form style={{float:"left",width:"100%"}}>
                                    <div className="singup_input " style={{textAlign:"center",padding: "15% 0 0"}}>
                                        <button onClick={this.openModal.bind(this)} type="button" className="singup_input_button singup_setp-3-button"> 
                                            Continue 
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" style={{marginTop:"-4px" , marginLeft:"15px"}} viewBox="0 0 26.899 26.899">
                                                <g id="Icon_feather-external-link" data-name="Icon feather-external-link" transform="translate(1 1.414)">
                                                    <path id="Path_24033" data-name="Path 24033" d="M24.9,18.522v8.162A2.721,2.721,0,0,1,22.184,29.4H7.221A2.721,2.721,0,0,1,4.5,26.684V11.721A2.721,2.721,0,0,1,7.221,9h8.162" transform="translate(-4.5 -4.919)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                    <path id="Path_24034" data-name="Path 24034" d="M22.5,4.5h8.162v8.162" transform="translate(-6.177 -4.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                    <path id="Path_24035" data-name="Path 24035" d="M15,19.463,29.963,4.5" transform="translate(-5.478 -4.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                </g>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="back_wapper" style={{textAlign:"center",marginTop:"5%", float:"left",width:"100%"}}>
                                        <button onClick={this.SkipStap.bind(this)} type="button" style={{background: "transparent",border: "none",color: "teal" ,fontSize:"20px"}}> Skip This </button>
                                    </div>
                                </form> 
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
            <Modal className="new_team_member_box_main" show={this.state.isOpen} onHide={this.closeModal}>
                <Modal.Body>
                    <h3>Connect Greenhouse Account</h3>
                    <div className="new_team_member_box_inner">
                        <form onSubmit={this.addNewFild.bind(this)}>
                            {/* <div className="singup_input" style={{float:"left",width:"100%"}}>
                                <label style={{float:"left",width:"100%",fontWeight:"500"}}> User id</label>
                                <input onChange={this.handleKeyPress.bind(this)} type="text" placeholder="Add user id" ref={this.account_name} name="account_name" />
                                <span className='form_validtion_class'>{this.state.valition_account_name}</span>
                            </div> */}
                            <div className="singup_input" style={{float:"left",width:"100%"}}>
                                <label style={{float:"left",width:"100%",fontWeight:"500"}}>Account token</label>
                                <input onChange={this.handleKeyPress.bind(this)} type="text" placeholder="Add account token" ref={this.account_token} name="account_token" />
                                <span className='form_validtion_class'>{this.state.valition_account_token}</span>
                            </div>
                            <div className="singup_input d-flex align-items-center justify-content-between singup_setp-3-button-wapper" style={{textAlign:"center",padding: "5% 0 0"}}>
                                <div className="back_wapper">
                                    <button type="button"  onClick={this.closeModal.bind(this)}> Cancel </button>
                                </div>
                                <button onClick={this.addNewFild.bind(this)} type="button" className="singup_input_button singup_setp-3-button">  Continue  </button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
            </>
        );
    }
}
export default Connect_greenhouse;