import React, { Component } from 'react';
import {Form} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Edit_icone from '../../assets/image/pen-to-square-solid.png';
class DEI_Information extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show_text_data:false,
            button_toggle:false,
            //DEI Infomation
            datatrnert : this.props.dei_statement,
            error_datatrnert : "",
            datatrnert_edit_mode : false,
            deistatement: this.props.team_lead,
            deistatement_edit_mode : false,
            training: this.props.team_lead,
            training_edit_mode : false,
            programming: this.props.programming,
            programming_edit_mode : false,
            erg:'',
            erg_edit_mode : false,
            // Demo Graphic Infomation
            // man:this.props.men,
            // women:this.props.women,
            // non_binary:this.props.non_binary,
            // lgbt:this.props.lgbt,
            edit_mode : false,
            
            showerror:false,
            showerrortype : 0,
            showerrormessage : "",
        } 

        this.datatrnert  = React.createRef(); 
    }

    add_to_lessmore(){
        this.setState({show_text_data:true});
    }

    add_to_lessmore_less(){
        this.setState({show_text_data:false});
    }

    edit_DEI_lead_or_team_button(){
        if(this.state.datatrnert_edit_mode == true){
            return(<>
            {localStorage.getItem("user_Role") == 2 ? <>
                    {/* <button onClick={this.edit_DEI_lead_or_team.bind(this)}  className='Disconnected' style={{backgroundImage :"url("+ Edit_icone +")",width:"20px", height:"20px", backgroundSize:"cover"}}>Edit</button> */}
                    <button onClick={this.getupdate_data.bind(this,"DEI_lead_or_team")}  className='Disconnected'>Update</button>
                </>
            :"" }
           </>)
        }else{
            return(<>
            <button onClick={this.edit_DEI_lead_or_team.bind(this)}  className='Disconnected' style={{backgroundImage :"url("+ Edit_icone +")",width:"20px", height:"20px", backgroundSize:"cover"}}></button>
           </>) 
        }
    }
    edit_DEI_lead_or_team(){
        if(this.state.datatrnert_edit_mode == true){
            this.setState({datatrnert_edit_mode : false});
        }else{
            this.setState({datatrnert_edit_mode : true})
        }
    }

    edit_mode_training_button(){
        if(this.state.training_edit_mode == true){
            return(<>
            {localStorage.getItem("user_Role") == 2 ? <>
                    {/* <button onClick={this.edit_mode_training.bind(this)}  className='Disconnected' style={{backgroundImage :"url("+ Edit_icone +")",width:"20px", height:"20px", backgroundSize:"cover"}}>Edit</button> */}
                    <button onClick={this.getupdate_data.bind(this,"mode_training")}  className='Disconnected'>Update</button>
                </>
            :"" }
           </>)
        }else{
            return(<>
            {localStorage.getItem("user_Role") == 2 ? <>
            <button onClick={this.edit_mode_training.bind(this)}  className='Disconnected' style={{backgroundImage :"url("+ Edit_icone +")",width:"20px", height:"20px", backgroundSize:"cover"}}></button>
            </>:"" }
            </>)
            
        }
    }
    edit_mode_training(){
        if(this.state.training_edit_mode == true){
            this.setState({training_edit_mode : false});
        }else{
            this.setState({training_edit_mode : true})
        }
    }

    edit_mode_programming_button(){
        if(this.state.programming_edit_mode == true){
            return(<>
                {localStorage.getItem("user_Role") == 2 ? 
                    <>
                        {/* <button onClick={this.edit_mode_programming.bind(this)}  className='Disconnected' style={{backgroundImage :"url("+ Edit_icone +")",width:"20px", height:"20px", backgroundSize:"cover"}}>Edit</button> */}
                        <button onClick={this.getupdate_data.bind(this,"mode_programming")}  className='Disconnected'>Update</button>
                    </>:"" }
            </>)
        }else{
            return(<>
                {localStorage.getItem("user_Role") == 2 ? 
                    <>
                        <button onClick={this.edit_mode_programming.bind(this)}  className='Disconnected' style={{backgroundImage :"url("+ Edit_icone +")",width:"20px", height:"20px", backgroundSize:"cover"}}></button>
                </>:"" }
            </>)
        }
    }
    edit_mode_programming(){
        if(this.state.programming_edit_mode == true){
            this.setState({programming_edit_mode : false});
        }else{
            this.setState({programming_edit_mode : true})
        }
    }

    edit_mode_erg_button(){
        if(this.state.erg_edit_mode == true){
            return(<>
            {localStorage.getItem("user_Role") == 2 ? <>
                {/* <button onClick={this.edit_mode_erg.bind(this)} className='Disconnected' style={{backgroundImage :"url("+ Edit_icone +")",width:"20px", height:"20px", backgroundSize:"cover"}}>Edit</button> */}
                <button onClick={this.getupdate_data.bind(this,"mode_erg")}  className='Disconnected'>Update</button>
            </>:"" }
            </>)
        }else{
            return(<>
            {localStorage.getItem("user_Role") == 2 ? 
                    <button onClick={this.edit_mode_erg.bind(this)} className='Disconnected' style={{backgroundImage :"url("+ Edit_icone +")",width:"20px", height:"20px", backgroundSize:"cover"}}></button>
                :"" }
            </>)    
        }
    }
    edit_mode_erg(){
        if(this.state.erg_edit_mode == true){
            this.setState({erg_edit_mode : false});
        }else{
            this.setState({erg_edit_mode : true})
        }
    }


    show_data_DEI_lead_or_team(){   
        if(this.state.datatrnert_edit_mode === false){
            if(this.state.show_text_data == false){
                return( <>
                    <span style={{float:"left", width:"100%", height:"30px", whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}> {this.state.datatrnert == "" ? this.props.dei_statement  : this.state.datatrnert } </span>  {this.state.datatrnert.length > 100 && this.props.dei_statement !="" ? <button type="button" onClick={this.add_to_lessmore.bind(this)}>Read More</button> :"" }  </> ) 
            }else{
                return( <> <span style={{float:"left", width:"100%"}}> {this.state.datatrnert == "" ? this.props.dei_statement  : this.state.datatrnert } </span>  {this.state.datatrnert.length > 100 && this.props.dei_statement !="" ?   <button type="button" onClick={this.add_to_lessmore_less.bind(this)} >Less More</button> :"" } </> )
            }
            
        }else{
            return(
                <>
                    <div className='readioaruye'>
                        <textarea ref={this.datatrnert} onChange={this.event_handel.bind(this)} name='datatrnert'  style={{width:"100%",float:"left",border:"1px solid #000", borderRadius:"6px"}}>{this.state.datatrnert == "" ? this.props.dei_statement  : this.state.datatrnert }</textarea>
                        <spna class="form_validtion_class">{this.state.error_datatrnert}</spna>
                    </div>                    
                </>    
            )    
        }
    }
    
    show_data_training(){
        if(this.state.training_edit_mode === false){
            return(
               <> <span>{this.state.training == "" ? this.props.training == 1 ? "Yes" : "No" : this.state.training == 1 ?"Yes" : "No" }</span> </> 
            ) 
        }else{
            return(
                <>
                    <div className='readioaruye'>
                        <Form>
                            {['radio'].map((type) => (
                                <div key={`inline-${type}`}>
                                    <Form.Check checked={this.state.training ==  "" ? this.props.training != 0 : this.state.training != 0} value={1} onChange={this.event_handel.bind(this)} inline name="training" type={type} label="Yes" id={`inline-training-1`} />
                                    <Form.Check checked={this.state.training ==  "" ? this.props.training != 1 : this.state.training != 1} value={0} onChange={this.event_handel.bind(this)} inline label="No"  name="training" type={type} id={`inline-training-2`} />
                                </div>
                            ))}
                        </Form>
                    </div>                    
                </>    
            )    
        }
    } 
    show_data_Programming(){
        if(this.state.programming_edit_mode === false){
            return(
               <> <span>{ this.state.programming == "" ? this.props.programming == 1 ? "Yes" : "No"  :  this.state.programming == 1 ? "Yes" : "No"  }</span> </> 
            ) 
        }else{
            return(
                <>
                    <div className='readioaruye'>
                    <Form>
                        {['radio'].map((type) => (
                            <div key={`inline-${type}`}>
                                <Form.Check checked={this.state.programming ==  "" ? this.props.programming != 0 : this.state.programming != 0 } value={1} onChange={this.event_handel.bind(this)} inline name="programming" type={type} label="Yes" id={`inline-programming-1`} />
                                <Form.Check checked={this.state.programming ==  "" ? this.props.programming != 1 : this.state.programming != 1} value={0} onChange={this.event_handel.bind(this)} inline label="No"  name="programming" type={type} id={`inline-programming-2`} />
                            </div>
                        ))}
                    </Form>
                    </div>                    
                </>    
            )    
        }
    } 
    show_data_erg(){
        if(this.state.erg_edit_mode === false){
            return( <> <span>{this.state.erg ==  "" ? this.props.erg == 1 ? "Yes" : "No" : this.state.erg == 1 ? "Yes" : "No" }</span> </> ) 
        }else{
            return(
                <>
                    <div className='readioaruye'>
                    <Form>
                        <div key={`inline-erg`}>
                            
                            <Form.Check checked={this.state.erg ==  "" ? this.props.erg != 0 : this.state.erg != 0} value={1} onChange={this.event_handel.bind(this)} inline name="erg" type="radio" label="Yes" id={`inline-erg-1`}  />
                            <Form.Check checked={this.state.erg ==  "" ? this.props.erg != 1 : this.state.erg != 1} value={0} onChange={this.event_handel.bind(this)} inline label="No"  name="erg" type="radio" id={`inline-erg-2`} />
                        </div>
                    </Form>
                    </div>                    
                </>    
            )    
        }
    } 

    event_handel(event){
        event.preventDefault(); 
        if(event.target.name == "datatrnert"){ 
            var value = event.target.value;
            this.setState({datatrnert:value,error_datatrnert:""}) 
        }
        if(event.target.name == "programming"){ 
            var value = event.target.value;
            this.setState({programming:value}) 
        }
        if(event.target.name == "training"){ 
            var value = event.target.value;
            this.setState({training:value}) 
        }
        if(event.target.name == "erg"){ 
            var value = event.target.value;
            this.setState({erg:value});
        }
    }

    getupdate_data(data){
        let datatrnert = ""; 
        if(data == "DEI_lead_or_team"){
            if(this.state.datatrnert == ""){
                datatrnert = this.props.dei_statement;
            }else{
                datatrnert = this.state.datatrnert;
            }
        }
        
        if(data == "mode_erg"){
            let erg = ""; 
            if(this.state.erg == ""){
                erg = this.props.erg;
            }else{
                erg = this.state.erg;
            }
        }    

        if(data == "mode_training"){
            let training = ""; 
            if(this.state.training == ""){
                training = this.props.training;
            }else{
                training = this.state.training;
            }
        }    

        if(data == "mode_programming"){
            let programming = ""; 
            if(this.state.programming == ""){
                programming = this.props.programming;
            }else{
                programming = this.state.programming;
            }
        }    

        var data_token = "Bearer "+localStorage.getItem("token");
        /*
        const apiOption = {
            method: "post",
            headers:{
                'Authorization': data_token,
                'Content-Type': 'application/json',
            },
            
            body:JSON.stringify({


            }), 
        }*/

        var body_json = {};

        if(data == "DEI_lead_or_team"){
            body_json = {
                    "dei_statement":this.state.datatrnert,
            };
        }

        if(data == "mode_erg"){
            body_json = {
                "erg": this.state.erg, 
            } 
        }

        if(data == "mode_training"){
            body_json = {
                "training": this.state.training,
            } 
        }

        if(data == "mode_programming"){
            body_json = {
                "programming": this.state.programming,
            } 
        }   

        const apiOption = {
            method: "post",
            headers:{
                'Authorization': data_token,
                'Content-Type': 'application/json',
            },
            
            body:JSON.stringify(body_json), 
        }

        fetch(this.props.apiPath+"update_dei",apiOption)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            if(resJson.status == true){
                this.setState({showerror:true , showerrortype : 1 , showerrormessage: "Your Profile is Update" });
                if(data == "mode_erg"){
                    if(this.state.erg_edit_mode === true){
                        this.setState({erg_edit_mode: false });
                    }
                }    
                if(data == "mode_training"){
                    if(this.state.training_edit_mode === true){
                        this.setState({training_edit_mode: false });
                    }
                }    
                if(data == "mode_programming"){
                    if(this.state.programming_edit_mode === true){
                        this.setState({programming_edit_mode: false });
                    }
                }    
                if(data == "DEI_lead_or_team"){
                    if(this.state.datatrnert_edit_mode === true){
                        this.setState({datatrnert_edit_mode: false });
                    }
                }
                
            }else{
                this.setState({showerror:true , showerrortype : 2 , showerrormessage: resJson.message });
                this.error_handaling();
            }
        })
        .catch(error => {
            this.setState({ showerror: true ,showerrortype : 2 ,showerrormessage: "Invalid Response" });
            this.error_handaling();
        }); 
    }

    error_handaling(){
        if(this.state.showerror == true){
            setTimeout(function() {
               this.setState({showerror: false});
            }.bind(this),3000); 

            if(this.state.showerrortype == 1){
                setTimeout(function() {
                    this.setState({redirect: true});
                }.bind(this),3100);     
            }

            return(
                <>
                    <Alert style={{position:"absolute",top:"0",left:"0",right:"0", width:"100%", float:"left", textAlign:"center"}} variant={this.state.showerrortype == 1 ? "success" : "danger"}>
                        {this.state.showerrormessage}
                    </Alert>
                </>
            )
        }
    }

    render() {
        return (
            <>
                {this.error_handaling()}
                <div className='tab_inner_contanet Personal_information_main'>
                    <h3>DEI Information</h3>
                    <div className='Personal_information_form'>
                        <div className='Personal_information_value'>
                            <label >Do you have DEI lead or team?</label>
                            {this.edit_DEI_lead_or_team_button()}
                        </div>    
                        <div className='Personal_information_form_data'>    
                            <div className='Personal_information_value Company-Bio' >
                                {this.show_data_DEI_lead_or_team()}
                            </div>
                            <span></span>
                        </div>
                        <div className='Personal_information_form_data Company-Size'>
                            <div className='Personal_information_value'>
                                <label>Do you have DEI training?</label>
                                {this.edit_mode_training_button()}
                            </div>    
                            <div className='Personal_information_value'>
                                {this.show_data_training()}
                            </div>
                        </div>

                        <div className='Personal_information_form_data Company-Size'>
                            <div className='Personal_information_value'>
                                <label>Employment Resource Group</label>
                                {this.edit_mode_erg_button()}
                            </div>
                            <div className='Personal_information_value'>
                                {this.show_data_erg()}
                            </div>
                            <span></span>
                        </div>

                        <div className='Personal_information_form_data Company-Size'>
                            <div className='Personal_information_value'>
                                <label>DEI Programming</label>
                                {this.edit_mode_programming_button()}
                                
                            </div>
                            <div className='Personal_information_value'>
                                {this.show_data_Programming()}
                            </div>
                            <span></span>
                        </div>
                        
                    </div>
                    {/* {localStorage.getItem("user_Role") == 2 ? <>
                    {this.state.datatrnert_edit_mode == true || this.state.training_edit_mode == true || this.state.programming_edit_mode == true||this.state.erg_edit_mode == true ? 
                        <>
                            <div className='Update_save_button' style={{float:"left",width:"100%",marginBottom:"30px"}}>
                                <button onClick={this.getupdate_data.bind(this)} className=" Profile_update_button" type="button">Update Profile</button>
                            </div>
                        </>
                    :""}   
                     </>
                        :"" }  */}
                </div>
            </>
        );
    }
}
export default DEI_Information;