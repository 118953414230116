import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import airbnb from '../assets/image/Group 8187.png';
import closeicon from '../assets/image/Group 8187.png';




class Close_Job extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen_2: false,
        };
    }

    openModal = () => this.setState({ isOpen_2:true });
    closeModal = () => this.setState({ isOpen_2:false });

 

    render() {
        return (
            <>

                <button type="button" onClick={this.openModal.bind(this)}> Close Job </button>

                <Modal className="new_team_member_box_main flagged-applicant Close-Job" show={this.state.isOpen_2} onHide={this.closeModal}>
                    <Modal.Body>
                        <div className='users_job_box'>
                            <img src={airbnb} />
                            <h3>You are  about to closing the job</h3> 
                            <span> "software engineering needed" </span>
                        </div>
                        
                        <div className="new_team_member_box_inner">
                            <form>
                                <div className="singup_input" style={{float:"left",width:"100%"}}>
                                    <label style={{float:"left",width:"100%",fontWeight:"500"}}>Reason for Closing</label>
                                    <textarea>I Found my  candidate alreddy</textarea>
                                </div>
                                
                                <div className="singup_input " style={{textAlign:"center",padding: "5% 0 0"}}>
                                    <button type="button" className="singup_input_button singup_setp-3-button"> Submit and close </button>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
                
            </>
        );
    }
}
export default Close_Job;
