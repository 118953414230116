import React, { Component } from 'react';
import UserIcon from '../assets/image/Rectangle_375.png';
import calender from '../assets/image/calender.png';
import zoom from '../assets/image/zoom.png';
import off_users from '../assets/image/Ellipse 333.png';
import online_users from '../assets/image/Ellipse 330.png';
// import moment from 'moment';
// import someTime from '../assets/image/Group 8187.png';
import moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');

//Add Tabs
class Messages_users extends Component {
    constructor(props) {
        super(props);

        this.state = {
            current_user_id : localStorage.getItem('User_id')
        }
    }
   

    render() {

        return (
            <>
                {this.props.users.map(Element =>
                    // <div style={this.state.current_user_id == Element.user_id ? {display:'none'} : {}}  className='chat-users-box user-online ' onClick={() => this.props.change_current_chat_id(this.props.id,Element.user_id)} value={this.props.id}>
                    <div style={this.state.current_user_id == Element.user_id ? {display:'none'} : {}}  className={"chat-users-box " + (Element.unread_messages == 0 ? '' : 'user-online')} onClick={() => this.props.change_current_chat_id(this.props.id,Element.user_id)} value={this.props.id}>

                        <div className='chat-useres-box-username'>
                            {/* <span className='chat-useres-box-imges'>
                                <img src={Element.profile_image} alt="UIT" />
                            </span> */}
                            <div className="chat-useres-box-imges" style={{marginRight:'12px',borderRadius:'100%',width:'80px',height:'50px',backgroundSize:'cover',backgroundImage: `url(${Element.profile_image})`}}>
                                {/* {$imagePreview} */}
                            </div>
                            <div className='chat-useres-box-text'>
                                <h3>{Element.name}</h3>
                                <span> {this.props.last_message} </span>
                            </div>
                            <div className='chat-useres-box-time-box'>
                                <span>{moment(this.props.last_message_date, "YYYYMMDD, HH:mm:ss").fromNow(true)}</span>
                                <span  style={Element.unread_messages == 0 ? {display:'0'} : {}}>
                                    <img src={online_users} />
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}
export default Messages_users;
