import LeftSideBar from ".././sidebar/leftside";
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch , Link, Navigate } from "react-router-dom"
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import upload_image from '../../assets/image/logo.png';


class Signin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validEmail : "", 
            validPassword: "",
            redirect: false,
            showerror:false,
            showerrortype : 0,
            showerrormessage : "",
        }
        // add form value
        this.email = React.createRef();
        this.password = React.createRef();

        var token = localStorage.getItem('token');
        if(token != null){
            this.state.redirect = true;
        }
    }

    handleKeyPress(e){
        if(e.target.name == "Email"){
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(e.target.value)) {
                this.setState({validEmail:""});
            }else {
                this.setState({ validEmail: "Please enter a valid email address" })
            }           
        }

        if(e.target.name == "Password"){
            this.setState({validPassword:""});
        }
    }

    addNewfild(event){
        event.preventDefault();
        let log = false;
        if(!this.email.current.value){
            this.setState({ validEmail: "Please enter a valid email address" });
            log = true;
        }
       
        if(!this.password.current.value){
            this.setState({ validPassword: "Please enter a password" });
            log = true;
        }

        if(log === true){
            console.log("var");
            event.preventDefault();
            return
        }

        const apiOption = {
            method: "POST",
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({
                "email" :  this.email.current.value ,
                "password" : this.password.current.value,
            }),
            redirect: 'follow'
        }
   
        fetch(this.props.APIPath+"login",apiOption)
        .then(function responsError (res) {
            return res.json();
        }).then(resJson => {
            if(resJson.status == true){
                
                let Manin_data_wrap = resJson.data;
                let Profle = Manin_data_wrap.profile; 
                let profile_img = Profle.profile_image;
                localStorage.setItem('profile_img', profile_img);

                // Login user id
                let id = Profle.user_id;
                localStorage.setItem('User_id', id);
                localStorage.setItem('Account_Email', Profle.email);

                let access_token =  Manin_data_wrap.access_token;
                let token = access_token.token;
                localStorage.setItem('token',token);

                let user_name = Profle.name;
                localStorage.setItem('user_name',user_name);

                let account_status = Profle.account_status;
                localStorage.setItem('account_status',account_status);

                let green_house_token = Profle.greenhouse_access_token;
                localStorage.setItem('greenhouse_access_token',green_house_token);

                let user_Role = Profle.role;
                localStorage.setItem('user_Role',user_Role); 

                let user_sizes = Profle.user_sizes;
                let user_sizes_merd = '';
                if(user_sizes.length > 0){
                    user_sizes_merd = user_sizes[0].name;
                }
                localStorage.setItem('user_sizes',user_sizes_merd); 
                
                if(user_Role == 4){
                    let get_compnay_id = Profle.parent.user_id;
                    console.log(get_compnay_id);
                    localStorage.setItem('perent_id',get_compnay_id);

                    let get_compnay_email = Profle.email;
                    localStorage.setItem('get_compnay_email',get_compnay_email);
                }
                
                this.setState({showerror:true , showerrortype : 1 , showerrormessage: "User is login" });
                // this.error_handaling();
                // console.log("hello all data");
            }else{
                this.setState({ showerror: true ,showerrortype : 2 ,showerrormessage: "Please enter valid password" });
                let data = resJson.message;
                this.error_handaling();
            }   
        })
        .catch(error => {
            this.setState({ showerror: true ,showerrortype : 2 ,showerrormessage: "Invalid Response" });
            this.error_handaling();
        });
    }

    error_handaling(){
        if(this.state.showerror == true){
            setTimeout(function() {
               this.setState({showerror: false});
            }.bind(this),3000); 

            if(this.state.showerrortype == 1){
                setTimeout(function() {
                    this.setState({redirect: true});
                }.bind(this),3100);     
            }

            return(
                <>
                    <Alert style={{position:"absolute",top:"0",left:"0",right:"0", width:"100%", float:"left", textAlign:"center"}} variant={this.state.showerrortype == 1 ? "success" : "danger"}>
                        {this.state.showerrormessage}
                    </Alert>
                </>
            )
        }
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to="/ALL_Post" />
        }
        return (
            <>
                
                {this.error_handaling()}
                <div className="row  signin_signup_background signin_form_page">
                    <div className="col-lg-6"></div> 
                    <div className="col-lg-6 singin_box"  style={{background:'#f9f9f9',padding:"1% 5% 3% 5%",textAlign:"left"}}>
                        <div className="fileUpload" >
                            <div className="upload_image">
                                <img src={upload_image} style={{ width:"100px" }}/>
                            </div>
                        </div>
                        <div className="singup_box " style={{marginTop:"5%", background:'overlay',background: "#FFF",float: "left", width: "100%", height: "auto",borderRadius: "15px"}}>
                            <div className="singup_box_inner">
                                <div className="singup_box_title" style={{padding:"5%",textAlign:"center"}}>
                                    <h3 style={{fontWeight: "600" ,fontSize: "32px", color: "rgba(25,153,154,1)",fontFamily: 'Poppins'}}>Welcome Back</h3>
                                </div>
                                <div className="singup_box_title-sub" style={{padding:"0 0 5% ",textAlign:"center"}}>
                                    <h3 style={{color:'#070707',fontWeight: "bold" ,fontSize: "32px",fontFamily: 'Poppins'}}>Sign in</h3>
                                    <span style={{fontWeight:"500",fontSize: "18px",color: "#161616"}}>Sign In or continue where you left off. </span>                            
                                </div>
                                <div className="singup_box-singup-stap-2" style={{padding:"0% 15% 6%",float:"left",width:"100%"}}>
                                    <form id="UserLoginForm" onSubmit={this.addNewfild.bind(this)} style={{float:"left",width:"100%"}}>
                                        <div className="singup_input" style={{marginBottom:"40px"}}>
                                            <input onKeyPress={this.handleKeyPress.bind(this)} name="Email" type="Email" ref={this.email} placeholder="Email address" style={{fontSize:"18px",color: "rgba(109,109,109,1)",fontSize:"18px",border: "2px solid #AEAEAE"}}  />
                                            <span className="form_validtion_class">{ this.state.validEmail }</span>
                                        </div>
                                        <div className="singup_input" style={{marginBottom:"30px"}}>
                                            <input  onKeyPress={this.handleKeyPress.bind(this)} name="Password" type="password" ref={this.password} placeholder="Password" style={{fontWeight:"500",fontSize:"18px",border: "2px solid #AEAEAE"}} />
                                            <span className="form_validtion_class">{ this.state.validPassword }</span>
                                        </div>
                                        <div className="singup_input" style={{textAlign:"center",width:"100%"}}>
                                            <button type="submit" style={{maxWidth:"100%"}} className="singup_input_button"> Login </button>
                                            {/* <Link to={"/Singup"} style={{maxWidth:"100%"}} className="singup_input_button" variant="outline-success">Login</Link> */}
                                        </div>
                                    </form> 
                                </div>
                                <div className="forget-password">
                                    <span style={{ marginBottom:"20px" }}>Don't have an account? <Link style={{fontWeight:"500"}} to={"/Singup"} variant="outline-success">Sign up now</Link></span>
                                    <span>By signing up, you agree to our  </span> <span style={{maxWidth:"390px"}}> <a style={{fontWeight:"500"}} href="#">Terms & Conditions</a>  and our <a href="#" style={{fontWeight:"500"}} >Privacy Policy</a></span>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </>
        );
    }
    

    // render() {
    //     return (
    //         <div className="row signin_signup_background">
    //             <div class="col-lg-6"></div> 
    //             <div class="col-lg-6" style={{background:'#f9f9f9'}}>
    //                 <div className="d-flex pt-4">
    //                     <img src={logo} style={{margin:'0 auto'}}/>
    //                 </div>
    //                 <div className="pt-5">
    //                     <h2 className="green text-align" style={{fontFamily: 'Poppins',fontWeight:'600',fontSize:'32px',color:'#19999a'}}>Welcome Back</h2>
    //                 </div>
    //             </div> 
    //         </div>
    //     );
    // }
}

Signin.propTypes = {

};

export default Signin;