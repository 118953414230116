import React, { Component } from 'react';
//CSS
//Images
import airbnb from '../../assets/image/airbnb.png';
import User_Profile_bod from '../../assets/image/User_Profile_bod.png';
class Profile_header extends Component {
    constructor(props) {
        super(props);
        console.log(this.props)
        this.state = {
            //logo upload start
            file: "",
            imagePreviewUrl: "",
            //logo upload End
            //background logo upload start
            background_file: "",
            background_imagePreviewUrl: "",
            //background logo upload End
        };
    }

    // Fileupload before privew;
    changeLogo(e) {
        e.preventDefault();
        this.setState({ valid_logo: "" });

        let reader = new FileReader();
        let file = e.target.files[0];
        let Profile_img = "";
        reader.onloadend = () => {
            this.setState({
                file: file, 
                imagePreviewUrl: reader.result
            });
        }
        reader.readAsDataURL(file);
        this.get_set_logo();
    }

    get_set_logo(){
        this.props.change_logo(this.state.file,this.state.imagePreviewUrl);
    }

    // Fileupload before privew;
    bg_changeLogo(e) {
        e.preventDefault();
        

        let reader = new FileReader();
        let bg_file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                bg_file: bg_file, 
                bg_imagePreviewUrl: reader.result
            });
        }
        reader.readAsDataURL(bg_file);
        this.props.changelogo_bg(bg_file,reader.result);
    }

    render() {
        return (
            <>
                <div className='Profile_header'>
                    <img src={User_Profile_bod} />
                    <div className='Profile_header-inner'>
                        <div className='Profile_header_user'>
                            <span className='Profile_header_user_logo'>
                                <img src={localStorage.getItem('profile_img')} />
                                <input style={{ position: "absolute", top: "0", bottom: "0", left: "0", right: "0", background: "transparent", fontSize: "0", width: "100%", opacity:"0"}} onChange={(e)=>this.changeLogo(e)} id='input-file-logo' type='file' inputProps={{ accept: 'image/*' }} />
                            </span>
                            <div className='Profile_header_user_text'>
                                <h3>{this.props.mydata.name != "" ? this.props.mydata.name :""}</h3>
                                <span>{this.props.mydata.city != "" ? this.props.mydata.city + " , " + this.props.mydata.state : ""}</span>
                                <span>Design Finance </span>
                            </div>
                        </div>
                        <div className='profile_change'>
                            <a href='javascript:void(0)' style={{position:"relative" }}> Change cover photo <input style={{ opacity:"0", position: "absolute", top: "0", bottom: "0", left: "0", right: "0", background: "transparent", fontSize: "0", width: "100%" }} onChange={(e)=>this.bg_changeLogo(e)} id='input-file-logo_bg' type='file' inputProps={{ accept: 'image/*' }} /> </a>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default Profile_header;